import React, { useContext, useMemo, useState } from "react";
import { useGooglePicker } from "@common/components/GoogleDocsIntegration/UseGooglePickerHook";
import { InputFormItem } from "../CollapsibleViewEditPanel/ViewEditFormItems/ViewEditFormItems";
import {
	AttachmentPreview,
	ClickToEdit,
	DeleteFileModal,
	GenemodIcon,
	Notification,
	Table,
	Typography,
	UserAvatar,
	Spin,
	axios,
	API,
} from "@components";
import {
	DateFormItem,
	UserFormItem,
	InputClickToEditFormItem,
	DatePickerClickToEditFormItem,
	TextAreaClickToEditFormItem,
	PriceClickToEditFormItem,
	AntibioticSelectClickToEditFormItem,
	GenusSelectClickToEditFormItem,
	SpeciesSelectClickToEditFormItem,
} from "../CollapsibleViewEditPanel/ViewEditFormItems/ViewEditFormItems";
import moment from "moment";
import { SafeLeavingGuardContext } from "@common/context/SafeLeavingGuardContext";
import { nanoid } from "@reduxjs/toolkit";
import column_styles from "@components/Table/ItemColumnWidth.module.scss";
import { getFileTypeIcon } from "@common/components/FileIcon";
import classNames from "classnames";
import { truncArgs } from "@helpers/Formatters";
import { IdsInConstType } from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import commonStyles from "common/styles/common.module.scss";
import styles from "./ItemViewAndEditPanel.module.scss";
import GoogleDocIcon from "@common/components/GoogleDocsIntegration/GoogleDocIcon";
import {
	ISOString,
	UUID,
	FieldType,
	Item,
	AttachmentOrGoogle,
	NewAttachmentOrGoogle,
	CURRENCY_DEF,
	CURRENCY_TYPES,
} from "@common/types";
import { formatCustomId } from "@common/types/Item";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import {
	useItemGoogleDocsQuery,
	useItemAttachmentsQuery,
	useItemAttachmentDeleteMutation,
} from "@redux/freezer/FreezerApiSlice";
import { useItemTypesQuery } from "@redux/inventory/Item";
import { FAILED_UPLOAD_MESSAGE } from "@common/constants";
import { UploadAttachmentModalV2Content } from "@common/components/UploadAttachmentModalV2/UploadAttachmentModalV2";
import dayjs, { Dayjs } from "dayjs";

type FormValue = string | string[] | number | undefined;
type FormState = Record<UUID, FormValue>;
type OnSave = (
	formState: FormState,
	callback: () => any,
	isReagent?: boolean
) => void;

type ItemViewAndEditPanelProps = {
	item: Item;
	nameIsEditable: boolean;
	viewOnly: boolean;
	onEdit: (
		editedItem: Item,
		callback: (newBox: boolean, item: Item | Error) => void,
		avoidLoading?: boolean
	) => void;
	itemGroup?: boolean;
};

/**
 * The entire panel that holds all the collapsible, editable, form sections for an item.
 */
export const ItemViewAndEditPanel = ({
	item,
	nameIsEditable = true,
	viewOnly,
	onEdit,
	itemGroup,
}: ItemViewAndEditPanelProps): JSX.Element => {
	const { data: itemTypes } = useItemTypesQuery();
	const copyItem = (item: Item) => ({
		...item,
		type_data: !item.type_data
			? item.type_data
			: {
					...item.type_data,
			  },
	});
	const itemInternal = copyItem(item);
	const itemType = itemTypes?.find(
		(type) => type.id === itemInternal.item_type
	);
	const onSave: OnSave = (formState, callback, isReagent = false) => {
		const haveItemsChanged = !!Object.entries(formState).find(
			([key, value]) => value !== (itemInternal as any)[key]
		);
		if (!haveItemsChanged) {
			callback();
		}
		const editedItem = copyItem(itemInternal);
		if (isReagent) {
			Object.assign(editedItem.type_data as any, formState);
		} else {
			Object.assign(editedItem, formState);
		}
		onEdit(
			editedItem,
			(success) => {
				if (success) {
					callback();
				}
			},
			true
		);
	};

	return (
		<div className={styles.wrapper}>
			<GeneralInformationCard
				viewOnly={viewOnly}
				item={itemInternal}
				onSave={onSave}
				nameIsEditable={nameIsEditable}
				itemGroup={itemGroup}
			/>{" "}
			{(itemType?.schema?.length || 0) > 0 && (
				<ReagentCard
					viewOnly={viewOnly}
					item={itemInternal}
					onSave={onSave}
				/>
			)}
			<NotesCard
				viewOnly={viewOnly}
				item={itemInternal}
				onSave={onSave}
			/>
			<SourceCard
				viewOnly={viewOnly}
				item={itemInternal}
				onSave={onSave}
			/>
		</div>
	);
};

type ReagentCardProps = {
	item: Item;
	onSave: OnSave;
	viewOnly: boolean;
};

const ReagentCard = ({ item, onSave, viewOnly }: ReagentCardProps) => {
	const { formState } = useCommonStateHook();
	const { data: itemTypes } = useItemTypesQuery();
	const fieldValues: FormState = {
		...item.type_data,
		...formState,
	};

	const fields =
		itemTypes?.find((itemType) => itemType.id === item.item_type)?.schema ||
		[];

	const formItems = fields.map((field, idx) => {
		const uuid = field.uuid;
		const value = fieldValues[uuid];
		const htmlKey = `${uuid}-${idx}`;

		if (!field) {
			return <Spin key={htmlKey} />;
		}

		let formValidators;
		if (field.type === FieldType.GENUS) {
			// Strain related
			return (
				<GenusSelectClickToEditFormItem
					label={field.label}
					key={htmlKey}
					value={value as string}
					onComplete={(e: string) => {
						onSave(
							{ ...fieldValues, [uuid]: e },
							() => {
								return;
							},
							true
						);
					}}
					readOnly={viewOnly}
					className={styles.strainSelectClickToEdit}
					placeholder="-"
				/>
			);
		} else if (field.type === FieldType.SPECIES) {
			// Strain related
			const genusValue = item.type_data?.[
				fields.find((field) => field.type === FieldType.GENUS)?.uuid ||
					""
			] as string | undefined;
			return (
				<SpeciesSelectClickToEditFormItem
					label={field.label}
					key={htmlKey}
					value={value as string}
					genus={genusValue}
					onComplete={(e: string) => {
						onSave(
							{ ...fieldValues, [uuid]: e },
							() => {
								return;
							},
							true
						);
					}}
					className={styles.strainSelectClickToEdit}
					placeholder="-"
					readOnly={viewOnly}
				/>
			);
		} else if (field.type === FieldType.SELECTION_MARKER) {
			// Strain related
			return (
				<AntibioticSelectClickToEditFormItem
					value={(value && (value as string).split(", ")) || []}
					label={field.label}
					key={htmlKey}
					onComplete={(e: string[]) => {
						onSave(
							{ ...fieldValues, [uuid]: e.join(", ") },
							() => {
								return;
							},
							true
						);
					}}
					placeholder="-"
					className={styles.strainSelectClickToEdit}
					readOnly={viewOnly}
				/>
			);
		} else if (field.type === FieldType.NUMBER) {
			formValidators = [
				{
					validator: (val: any) => !isNaN(val),
					error: "Please enter a number",
				},
			];
		} else if (field.type === FieldType.MULTIPLE_LINE) {
			return (
				<TextAreaClickToEditFormItem
					id={field.uuid}
					key={htmlKey}
					label={field.label}
					value={(value || "") as string}
					onComplete={(e: string) => {
						onSave(
							{ ...fieldValues, [uuid]: e },
							() => {
								return;
							},
							true
						);
					}}
					enterToSave={false}
					readOnly={viewOnly}
					detectLinks
				/>
			);
		}

		if (field.type === FieldType.DATE_PICKER) {
			return (
				<DatePickerClickToEditFormItem
					key={htmlKey}
					label={field.label}
					value={value ? dayjs(value as string) : dayjs()}
					onComplete={(e: Dayjs | null | undefined) => {
						if (e) {
							onSave(
								{
									...fieldValues,
									[uuid]: e.format("MM/DD/YYYY"),
								},
								() => {
									return;
								},
								true
							);
						}
					}}
				/>
			);
		}

		return (
			<InputClickToEditFormItem
				key={htmlKey}
				id={htmlKey}
				label={field.label}
				value={(value || "") as string}
				onComplete={(e: string) => {
					onSave(
						{ ...fieldValues, [uuid]: e },
						() => {
							return;
						},
						true
					);
				}}
				readOnly={viewOnly}
				validators={formValidators}
				detectLinks
			/>
		);
	});

	const itemTypeName =
		itemTypes?.find((itemType) => itemType.id === item.item_type)?.name ||
		"Custom";

	return (
		<div className={styles.formSection}>
			<SectionHeader>{itemTypeName}</SectionHeader>
			{formItems}
		</div>
	);
};

type GeneralInformationCardProps = {
	item: Item;
	onSave: OnSave;
	nameIsEditable: boolean;
	viewOnly: boolean;
	itemGroup?: boolean;
};

const GeneralInformationCard = ({
	item,
	onSave,
	viewOnly,
}: GeneralInformationCardProps) => {
	const { formState, onValidityChange } = useCommonStateHook();
	const {
		created_by: owner,
		added_at,
		updated_at,
		updated_by,
		custom_id,
		organization_prefix,
		item_type,
	} = item;
	const { concentration, volume, created_at, expiration_date } = {
		...item,
		...formState,
	};
	const { data: itemTypes } = useItemTypesQuery();

	return (
		<div className={styles.formSection}>
			<InputFormItem
				label="ID"
				value={formatCustomId(organization_prefix, custom_id)}
				isEditable={false}
				tooltip="ID can't be edited"
			/>
			<InputFormItem
				label="Item type"
				value={
					itemTypes?.find((itemType) => itemType.id === item_type)
						?.name
				}
				isEditable={false}
				tooltip="Item type can't be edited"
			/>
			<InputClickToEditFormItem
				label="Concentration"
				value={concentration}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							concentration: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
			/>
			<InputClickToEditFormItem
				label="Vol / Mass"
				value={volume}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							volume: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
				maxLength={40}
			/>
			{/* We are going to name it "Created on" for the user to keep */}
			{/* consistency but we are going to use the "Added At" field */}
			<DatePickerClickToEditFormItem
				required
				label="Created on"
				value={added_at ? dayjs(added_at) : dayjs()}
				onComplete={(e: Dayjs | null | undefined) => {
					onSave(
						{
							...formState,
							added_at: e as any,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
			/>
			<UserFormItem label="Created by" value={owner} />
			<DatePickerClickToEditFormItem
				label="Expires on"
				value={expiration_date ? dayjs(expiration_date) : null}
				placeholder="-"
				onComplete={(e: Dayjs | null | undefined) => {
					onSave(
						{
							...formState,
							expiration_date: e as any,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
				minDate={item.added_at ? dayjs(item.added_at) : dayjs()}
			/>
			<DateFormItem
				label="Last modified on"
				value={updated_at}
				isEditable={false}
			/>
			<UserFormItem label="Last modified by" value={updated_by} />
		</div>
	);
};

type NotesCardProps = {
	item: Item;
	onSave: OnSave;
	viewOnly: boolean;
};

const NotesCard = ({ item, onSave, viewOnly }: NotesCardProps) => {
	const { formState } = useCommonStateHook();
	const { notes } = {
		...item,
		...formState,
	};

	return (
		<div className={styles.formSection}>
			<SectionHeader>Notes</SectionHeader>
			<div className={styles.notesSection}>
				<ClickToEdit
					value={notes}
					component="textarea"
					placeholder={viewOnly ? "No notes" : "Click to add note"}
					onComplete={(e: string) => {
						onSave(
							{
								...formState,
								notes: e,
							},
							() => {
								return;
							}
						);
					}}
					enterToSave={false}
					className={styles.notesClickToEdit}
					readOnly={viewOnly}
					detectLinks
					adjustHeight
				/>
			</div>
		</div>
	);
};

type SourceCardProps = {
	item: Item;
	onSave: OnSave;
	viewOnly: boolean;
};

const SourceCard = ({ item, onSave, viewOnly }: SourceCardProps) => {
	const { formState } = useCommonStateHook();
	const {
		source,
		reference,
		catalog,
		lot,
		packaging,
		price,
		currency = CURRENCY_TYPES[CURRENCY_DEF],
	} = {
		...item,
		...formState,
	};

	return (
		<div className={styles.formSection}>
			<SectionHeader>Source</SectionHeader>
			<InputClickToEditFormItem
				label="Vendor"
				value={source}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							source: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
				detectLinks
			/>
			<InputClickToEditFormItem
				label="Reference"
				value={reference}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							reference: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
				detectLinks
			/>
			<InputClickToEditFormItem
				label="Catalog #"
				value={catalog}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							catalog: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
				detectLinks
			/>
			<InputClickToEditFormItem
				label="Lot #"
				value={lot}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							lot: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
			/>
			<InputClickToEditFormItem
				label="Packaging"
				value={packaging}
				onComplete={(e: string) => {
					onSave(
						{
							...formState,
							packaging: e,
						},
						() => {
							return;
						}
					);
				}}
				readOnly={viewOnly}
				detectLinks
			/>
			<PriceClickToEditFormItem
				label="Price"
				value={price}
				onComplete={(newPrice: any, newCurrency: any) => {
					onSave(
						{
							...formState,
							price: newPrice,
							currency: newCurrency,
						},
						() => {
							return;
						}
					);
				}}
				currencyValue={
					+currency as IdsInConstType<typeof CURRENCY_TYPES>
				}
				readOnly={viewOnly}
				enterToSave
			/>
		</div>
	);
};

type AttachmentProps = {
	item: Item;
	viewOnly: boolean;
};

export const AttachmentCard = ({ item, viewOnly }: AttachmentProps) => {
	const { isEditing, setIsEditing } = useCommonStateHook();
	const [showUploadModal, setShowUploadModal] = useState(false);
	const [uploadingRate, setUploadingRate] = useState(0.0);
	const [attachmentToDelete, setAttachmentToDelete] =
		useState<AttachmentOrGoogle | null>(null);
	const [previewAttachment, setPreviewAttachment] =
		useState<AttachmentOrGoogle | null>(null);
	const { is_limit_reached: isGoogleDriveRestricted } =
		useFeatureRestrictionHook("google_drive");
	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const { openGooglePickerAndCreate, deleteGoogleDoc } = useGooglePicker({
		parentType: "Item",
		itemId: item.id,
	});

	const {
		data: googleDocs = [],
		isLoading: isGoogleDocsLoading,
		refetch,
	} = useItemGoogleDocsQuery({
		itemId: item.id,
	});

	const {
		data: fileAttachments = [],
		isLoading: isAttachmentsLoading,
		isFetching: isAttachemntsFetching,
		refetch: refetchItemAttachments,
	} = useItemAttachmentsQuery(item.id);
	const attachments = [...fileAttachments, ...googleDocs];
	const attachmentLoading =
		isAttachmentsLoading || isAttachemntsFetching || isGoogleDocsLoading;
	const [deleteItemAttachment] = useItemAttachmentDeleteMutation();

	const uploadCompleted = async (file: any) => {
		if (!item.id) return;
		setShowUploadModal(true);
		const config = {
			onUploadProgress: function (progressEvent: any) {
				const percentCompleted = Math.floor(
					(progressEvent.loaded / progressEvent.total) * 100
				);
				setUploadingRate(percentCompleted);
			},
		};
		const data = new FormData();
		data.append("upload", file);

		// Utilize axios here so we can utilize the upload progress
		axios
			.post(
				API.freezerV2.item(item.id).attachments().getRoute(),
				data,
				config
			)
			.then((response) => {
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${response.data.name}`(68)}</b>
							{" has been uploaded."}
						</span>
					),
				});
				refetchItemAttachments();
			})
			.catch((error) => {
				let errorMessage = FAILED_UPLOAD_MESSAGE;
				if (error.response && error.response.data.upload) {
					errorMessage = error.response.data.upload[0];
				}
				Notification.warning({
					message: errorMessage,
				});
			})
			.finally(() => {
				setUploadingRate(0.0);
				setShowUploadModal(false);
			});
	};

	const handleDeleteItemAttachment = async (
		attachment: AttachmentOrGoogle | null
	) => {
		if (!attachment || !attachmentToDelete) return;

		// Delete google drive doc
		if (attachment?.document_type === "GoogleDriveDocument") {
			deleteGoogleDoc(attachment, () => {
				refetch();
				setPreviewAttachment(null);
				setAttachmentToDelete(null);
			});

			return;
		}

		deleteItemAttachment(attachment.id)
			.unwrap()
			.then(() => {
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${attachment.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
				setPreviewAttachment(null);
				setAttachmentToDelete(null);
			})
			.catch(() =>
				Notification.warning({
					message:
						"Failed to delete the attachment. Try again or contact us if it continues.",
				})
			);
	};

	const handleAttachmentDownload = (
		attachment: AttachmentOrGoogle | null
	) => {
		if (attachment?.document_type === "GoogleDriveDocument") return;

		window.open(attachment?.upload);
	};

	return (
		<div className={styles.attachmentSection}>
			<Typography
				variant="headline"
				color="text-primary-v2"
				semibold
				style={{ marginBottom: 16 }}
			>
				Attachments
			</Typography>
			{viewOnly &&
			!isEditing &&
			!attachmentLoading &&
			attachments.length === 0 ? (
				<Typography className={styles.empty}>No files</Typography>
			) : (
				<AttachmentTable<AttachmentOrGoogle>
					className={styles.attachmentsTable}
					attachments={attachments}
					viewOnly={viewOnly}
					isEditing={true}
					setAttachmentToDelete={setAttachmentToDelete}
					setAttachmentToPreview={setPreviewAttachment}
					footer={
						viewOnly
							? undefined
							: () => (
									<UploadAttachmentModalV2Content
										visible={showUploadModal}
										uploadCompleted={uploadCompleted}
										uploadingRate={uploadingRate}
										openGoogleDrive={
											isGoogleDriveRestricted
												? () =>
														openUpgradeModal({
															type: "GOOGLE_DRIVE",
														})
												: () =>
														openGooglePickerAndCreate()
										}
										isGoogleDriveRestricted={
											isGoogleDriveRestricted
										}
									/>
							  )
					}
				/>
			)}
			{/* <UploadAttachmentModal
				visible={showUploadModal}
				onCancel={() => {
					setShowUploadModal(false);
				}}
				uploadCompleted={uploadCompleted}
				uploadingRate={uploadingRate}
			/> */}
			<DeleteFileModal
				visible={!!attachmentToDelete}
				onCancel={() => setAttachmentToDelete(null)}
				onDelete={() => handleDeleteItemAttachment(attachmentToDelete)}
			/>
			<AttachmentPreview
				visible={!!previewAttachment}
				attachment={previewAttachment}
				onClose={() => setPreviewAttachment(null)}
				onDelete={() => setAttachmentToDelete(previewAttachment)}
				onDownload={() => handleAttachmentDownload(previewAttachment)}
			/>
		</div>
	);
};

type AttachmentTableItemType = AttachmentOrGoogle | NewAttachmentOrGoogle;

type AttachmentTableProps<T extends AttachmentTableItemType> = {
	attachments: T[];
	viewOnly: boolean;
	isEditing: boolean;
	setAttachmentToDelete: (arg: T) => void;
	setAttachmentToPreview?: (arg: T) => void;
	className?: string;
	footer?: React.ComponentProps<typeof Table>["footer"];
};

export function AttachmentTable<T extends AttachmentTableItemType>({
	attachments,
	viewOnly,
	isEditing,
	setAttachmentToDelete,
	setAttachmentToPreview = (_arg: T) => {
		return;
	},
	className,
	footer,
}: AttachmentTableProps<T>): JSX.Element {
	const renderAttachmentTitle = (row: T) => {
		return (
			<div className={styles.attachmentTitle}>
				{(() => {
					if (
						row.document_type === "GoogleDriveDocument" ||
						row.document_type === "GoogleDocCreateDto"
					) {
						return (
							<div
								className={styles.googleIconWrapper}
								style={{ flexShrink: 0 }}
							>
								<GoogleDocIcon
									callbackDoc={row.raw_data}
									className={styles.genemodIcon}
								/>
							</div>
						);
					}
					const Icon = getFileTypeIcon(`${row.file_type}_sm`);
					return <Icon />;
				})()}
				<Typography ellipsis className={styles.attachmentName}>
					{row.name}
				</Typography>
			</div>
		);
	};
	const now = new Date().toISOString() as ISOString;

	const getCreatedAt = (row: T) =>
		row?.document_type === "GoogleDocCreateDto" ? now : row.created_at;

	const columns = [
		{
			title: "",
			dataIndex: "name",
			key: "name",
			ellipsis: true,
			render: (_: T, row: T) => {
				return renderAttachmentTitle(row);
			},
		},
		{
			title: "",
			dataIndex: "upload_date",
			key: "uploaded_on",
			ellipsis: true,
			sorter: (a: T, b: T) =>
				-1 * moment(getCreatedAt(a)).diff(moment(getCreatedAt(b))),
			render: (_: any, row: T) => {
				return (
					<div
						style={{
							flexShrink: 0,
						}}
						className={commonStyles.flexCenter}
					>
						<UserAvatar
							user={
								row?.document_type === "GoogleDocCreateDto"
									? undefined
									: row.created_by
							}
							avatarStyle={{ display: "flex", flexShrink: 0 }}
							displayName={true}
						/>
					</div>
				);
			},
			className: column_styles["S_COLUMN_WIDTH"],
		},
		{
			title: "",
			dataIndex: "delete",
			key: "delete",
			render: (_: T, row: T) => {
				if (viewOnly || !isEditing) return null;
				return (
					<GenemodIcon
						onClick={(e: any) => {
							e.stopPropagation();
							setAttachmentToDelete(row);
						}}
						name="trash"
						size="large"
					/>
				);
			},
			width: viewOnly ? 0 : 56,
		},
	];
	const onRow = (record: T) => {
		return {
			onClick: () => {
				/** click row */
				setAttachmentToPreview(record);
			},
		};
	};

	return (
		<Table
			className={classNames(className)}
			showHeader={false}
			columns={columns}
			dataSource={attachments}
			onRow={onRow}
			footer={footer}
		/>
	);
}

// HELPERS
export const useCommonStateHook = () => {
	const { addShouldBlockKey, removeShouldBlockKey } = useContext(
		SafeLeavingGuardContext
	);

	const [isEditing, setIsEditing] = useState(false);
	const [formState, setFormState] = useState<FormState>({});
	const [validityState, setValidityState] = useState<
		Record<UUID | string, boolean>
	>({});
	const formId = useMemo(() => nanoid(), []);

	const onEdit = () => {
		setIsEditing(true);
	};

	const onCancel = () => {
		setIsEditing(false);
		setValidityState({});
		setFormState({});
		removeShouldBlockKey?.(formId);
	};

	const onChange = (uuid: UUID, value: FormValue) => {
		setFormState({
			...formState,
			[uuid]: value,
		});
		addShouldBlockKey?.(formId);
	};

	const getIsValid = () => {
		return !Object.values(validityState).some(
			(isValid) => isValid === false
		);
	};

	const onValidityChange = (uuid: UUID, isValid: boolean) =>
		setValidityState({
			...validityState,
			[uuid]: isValid,
		});

	return {
		isEditing,
		setIsEditing,
		formState,
		onEdit,
		onCancel,
		onChange,
		getIsValid,
		onValidityChange,
	};
};

function SectionHeader(props: React.ComponentProps<typeof Typography>) {
	return (
		<div className={styles.sectionHeader}>
			<Typography
				variant="headline5"
				bold
				color="text-primary-v2"
				{...props}
			/>
		</div>
	);
}
