import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import {
	CURRENCY_TYPES,
	Freezer,
	FREEZER_TYPES,
	SharingOptions,
} from "@common/types";
import { PlanName } from "@common/types/FeatureRestriction";
import {
	CustomDatePickerV3,
	GenemodAnchorme,
	GenemodIcon,
	Select,
} from "@components";
import ItemTypeSelect from "@containers/Freezer/components/ItemTypeSelect/ItemTypeSelect";
import { formatQuantity } from "@helpers/Formatters";
import { Semaphore, useEffectAfterFirstCall } from "@helpers/Hooks";
import { useCurrentPlanNameHook } from "@helpers/Hooks/featureRestrictionHook";
import { useClickOutsideElement } from "@helpers/Hooks/useClickOutsideElement";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { useInputValidation } from "@helpers/InputHelper";
import { idKeysOfConst, IdsInConstType } from "@helpers/TypeHelpers";
import { useSearchParams } from "@helpers/URLParams";
import { zodResolver } from "@hookform/resolvers/zod";
import { useItemTypesQuery } from "@redux/inventory/Item";
import { default as classNames, default as cn } from "classnames";
import React, {
	ComponentPropsWithoutRef,
	CSSProperties,
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import Input, {
	InputProps as GenemodInputProps,
	InputValidator,
	MAX_INTEGER,
} from "../Input/Input";
import { CurrencyEditCell } from "@containers/ProjectManagement/components/ProjectViewV2/components/ExperimentFileMaterials/MaterialsTable";
import Typography, { TypographyProps } from "../Typography/Typography";
import styles from "./ClickToEdit.module.scss";
import { DatePickerProps } from "antdv5";
import dayjs, { Dayjs } from "dayjs";

type TextAreaProps = {
	inputProps?: ComponentPropsWithoutRef<"textarea">;
	component: "textarea";
};

type InputProps = {
	inputProps?: ComponentPropsWithoutRef<"input">;
	component: "input";
};

export type Props = {
	value: string;
	onComplete: (value: string) => void;
	maxLength?: number;
	placeholder?: string;
	required?: boolean;
	readOnly?: boolean;
	edit?: boolean;
	enterToSave?: boolean;
	tabToSave?: boolean;
	onEditChange?: (edit: boolean) => void;
	validators?: InputValidator[];
	onValidityChange?: (arg0: boolean) => void;
	detectLinks?: boolean;
	customDisplay?: string | ((text: string) => string);
	type?: string;
	/** Adding a confirm and cancel button on the bottom right corner of the click to edit component to save or cancel edit. Set to true by default */
	addConfirmAndCancel?: boolean;
	stretchInput?: boolean;
	/**
	 * Calling the semaphore update function will cause this component to be focused in editing mode.
	 */
	focusSemaphore?: Semaphore;
	useNumberArrows?: boolean;
	preventLessThanZero?: boolean;
	theme?: "default" | "light";
	onCancel?: () => void;
	adjustHeight?: boolean;
	containerClassName?: string;
	saveBeforeNavigation?: boolean;
	hideInputBorder?: boolean;
	noHoverEffect?: boolean;
	selectText?: boolean;
	dataCy?: string;
	lightBackground?: boolean;
	cancelEditionIfEmpty?: boolean;
} & TypographyProps &
	(TextAreaProps | InputProps);

const ClickToEdit = ({
	value,
	onComplete,
	component,
	inputProps,
	maxLength,
	placeholder,
	required,
	readOnly,
	edit,
	onEditChange,
	enterToSave = true,
	tabToSave = false,
	validators = [],
	onValidityChange = () => {},
	detectLinks = false,
	customDisplay,
	type = "text",
	addConfirmAndCancel = true,
	stretchInput = false,
	focusSemaphore,
	useNumberArrows,
	preventLessThanZero,
	theme,
	onCancel,
	adjustHeight,
	containerClassName,
	saveBeforeNavigation = true,
	hideInputBorder = false,
	noHoverEffect = false,
	selectText,
	dataCy,
	lightBackground,
	cancelEditionIfEmpty: resetIfEmpty = false,
	...props
}: Props): JSX.Element => {
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const [_text, _setText] = useState(value);
	const text =
		maxLength !== undefined ? _text.substring(0, maxLength) : _text;
	const editable = !readOnly && (edit ?? _editable);

	const setText = (text: string) => {
		if (type !== "number" || text === "" || isNaN(Number(text))) {
			return _setText(text);
		}

		const result = Number(text);

		if (result < 0) {
			return _setText("0");
		}

		if (result > MAX_INTEGER) {
			return _setText(MAX_INTEGER.toString());
		}

		return _setText(result.toString());
	};

	useEffect(() => {
		setText(value);
	}, [value]);

	useEffect(() => {
		if (!editable && required && !text) {
			setText(value);
		}
	}, [editable]);

	const [isValid, showError] = useInputValidation(
		text,
		validators,
		onValidityChange
	);

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
		onEditChange?.(edit);
	};

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const handleComplete = () => {
		if (resetIfEmpty && !text.trim()) {
			handleCancel();
		} else if ((!required || !!text.trim()) && text !== value) {
			onComplete(text.trim());
		} else {
			onCancel?.();
		}
		setEditable(false);
	};

	const handleCancel = () => {
		setEditable(false);
		setText(value);
		onCancel?.();
	};

	const [addOne, subtractOne] = (() => {
		const changeNumber = (n: number) => () => {
			if (!isNaN(Number(text))) {
				let result = Number(text) + n;
				if (preventLessThanZero && result < 0) result = 0;
				if (result > MAX_INTEGER) result = MAX_INTEGER;
				setText(result.toString());
				props?.onChange?.({
					target: { value: result.toString() },
				} as any);
				handleEditableTextClick();
			}
		};
		return [changeNumber(1), changeNumber(-1)] as const;
	})();

	const handleTextAreaKeyEvent = (
		event:
			| React.KeyboardEvent<HTMLTextAreaElement>
			| React.KeyboardEvent<HTMLInputElement>
	) => {
		if (
			((enterToSave && event.key === "Enter") ||
				(tabToSave && event.key === "Tab")) &&
			!event.shiftKey &&
			isValid
		) {
			event.preventDefault();
			handleComplete();
		} else if (event.key === "Escape") {
			handleCancel();
		} else if (event.key === "ArrowUp") {
			addOne();
		} else if (event.key === "ArrowDown") {
			subtractOne();
		}
	};

	const inputContainer = useRef<HTMLDivElement | null>(null);
	useClickOutsideElement(inputContainer, () => {
		if (isValid) handleComplete();
	});

	const handleTextAreaLostFocus = (
		_event: React.FocusEvent<HTMLTextAreaElement>
	) => {
		if (isValid) handleComplete();
	};

	useSaveBeforeNav({
		editable,
		showError,
		handleComplete,
		addListener: saveBeforeNavigation,
	});

	const input = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
	const [textarea, setTextarea] = useState<HTMLTextAreaElement | null>(null);
	useEffectAfterFirstCall(() => {
		_setEditable(true);
		setTimeout(() => {
			input.current?.focus();
		}, 200);
	}, [focusSemaphore]);

	useEffect(() => {
		if (selectText && input.current) {
			input.current.select();
		}
	}, [selectText]);

	useEffect(() => {
		if (textarea) {
			textarea.setSelectionRange(
				textarea.value.length,
				textarea.value.length
			);
		}
	}, [textarea]);

	const renderCustomDisplay = () => {
		if (typeof customDisplay === "function") return customDisplay(text);
		return customDisplay;
	};

	const renderReadOnly = () => {
		return (
			<div
				className={cn(styles.label, inputProps?.className, {
					[styles.hover]: !readOnly && !noHoverEffect,
					[styles.withLinks]: detectLinks,
					[styles.ellipsis]: props.ellipsis,
				})}
				onClick={!readOnly ? handleEditableTextClick : () => {}}
			>
				{detectLinks ? (
					<GenemodAnchorme
						target="_blank"
						rel="noopener"
						onClick={(e) => e.stopPropagation()}
					>
						{text || placeholder || "-"}
					</GenemodAnchorme>
				) : (
					<>{renderCustomDisplay() || text || placeholder || "-"}</>
				)}
			</div>
		);
	};

	const renderEditable = () => {
		if (component === "textarea") {
			return (
				<textarea
					data-cy={`${dataCy}-textarea`}
					onBlur={handleTextAreaLostFocus}
					onChange={(e) => {
						setText(e.target.value);
					}}
					onKeyDown={handleTextAreaKeyEvent}
					value={text}
					ref={(ref) => {
						input.current = ref;
						setTextarea(ref);
					}}
					autoFocus
					{...inputProps}
					className={cn(styles.textarea, inputProps?.className)}
					style={{
						width: stretchInput ? "100%" : `${text.length + 14}ch`,
						overflow: "hidden",
						resize: "none",
						height:
							adjustHeight && textarea
								? textarea.scrollHeight
								: "auto",
						...inputProps?.style,
					}}
				/>
			);
		}
		return (
			<>
				<input
					data-cy={`${dataCy}-input`}
					onChange={(e) => {
						setText(e.target.value);
					}}
					onKeyDown={handleTextAreaKeyEvent}
					value={text}
					ref={input as MutableRefObject<HTMLInputElement>}
					onClick={handleEditableTextClick}
					type={type}
					autoFocus={!useNumberArrows}
					{...inputProps}
					className={cn(styles.input, inputProps?.className)}
					style={{
						width: stretchInput ? "100%" : `${text.length + 2}ch`,
						...(useNumberArrows && { marginRight: "20px" }),
						...inputProps?.style,
					}}
				/>
				{useNumberArrows && (
					<div className={styles.arrowContainer}>
						<GenemodIcon
							name="chevron-right"
							style={{
								transform: "rotate(-90deg)",
							}}
							onClick={addOne}
						/>
						<GenemodIcon
							onClick={subtractOne}
							name="chevron-left"
							style={{ transform: "rotate(-90deg)" }}
						/>
					</div>
				)}
			</>
		);
	};

	return (
		<Typography
			color={"text-secondary-v2"}
			variant="label"
			{...props}
			style={{ position: "relative", ...props.style }}
			onMouseEnter={() => useNumberArrows && _setEditable(true)}
			onMouseLeave={() => {
				if (
					useNumberArrows &&
					!inputContainer.current?.contains(document.activeElement) &&
					value === text
				) {
					_setEditable(false);
				}
			}}
			dataCy={dataCy}
		>
			{!editable ? (
				renderReadOnly()
			) : (
				<div
					className={cn(
						styles.inputContainer,
						{
							[styles.lightBackground]: lightBackground,
							[styles.lightTheme]: theme === "light",
							[styles.error]: showError,
							[styles.hideBorder]: hideInputBorder,
						},
						containerClassName
					)}
					ref={inputContainer}
				>
					{renderEditable()}
				</div>
			)}
			{editable && addConfirmAndCancel && (
				<ConfirmAndCancel
					onConfirm={handleComplete}
					onCancel={handleCancel}
					isValid={isValid as boolean}
				/>
			)}
			{showError && (
				<Typography
					className={styles.inputContainerError}
					variant="caption"
					color="red-contrast"
				>
					{showError}
				</Typography>
			)}
		</Typography>
	);
};

export type DatePickerClickToEditProps = {
	edit?: boolean;
	setEdit?: (e: boolean) => void;
	readOnly?: boolean;
	placeholder?: string;
	onComplete: (e: Dayjs | null | undefined) => void;
	enterToSave?: boolean;
	required?: boolean;
	autoClose?: boolean;
} & DatePickerProps & { dataCy?: string };

export function DatePickerClickToEdit({
	edit,
	setEdit,
	readOnly,
	placeholder,
	onComplete,
	enterToSave,
	required,
	autoClose,
	...props
}: DatePickerClickToEditProps): JSX.Element {
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(props.value);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;
	const notRequired = required === false || required === undefined;

	const handleEditableTextClick = (e: any) => {
		setEditable(true);
		setOpen(true);
	};

	const setEditable = (edit: boolean) => {
		setEdit?.(edit);
		_setEditable(edit);
	};

	const handleComplete = (e?: any) => {
		const oldVal = dayjs(props.value).format("YYYY-MM-DD");
		const newVal = dayjs(value).format("YYYY-MM-DD");

		if ((notRequired || !!value) && oldVal !== newVal) {
			onComplete(value);
		}
		setEdit?.(false);
		setEditable(false);
		setOpen(false);
	};

	useSaveBeforeNav({ editable, showError: false, handleComplete });

	return (
		<Typography
			color={!value ? "text-tertiary-v2" : "text-secondary-v2"}
			style={{ width: "100%" }}
		>
			{!editable ? (
				<div
					className={`${styles.label} ${!readOnly && styles.hover} ${
						props?.className
					}`}
					onClick={!readOnly ? handleEditableTextClick : () => {}}
				>
					{props.value
						? dayjs(props.value).format("MMMM D, YYYY")
						: `${placeholder || "-"}`}
				</div>
			) : (
				<CustomDatePickerV3
					open={open}
					value={value}
					onChange={(e) => {
						if (autoClose) {
							setValue(e);
							onComplete(e);
						} else {
							setValue(e);
						}
					}}
					onOpenChange={(val) => {
						setOpen(val);
						setEdit?.(val);
					}}
					autoFocus
					{...props}
				/>
			)}
		</Typography>
	);
}

export type FreezerSharingClickToEditProps = {
	edit?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	onComplete: (value: SharingOptions) => void;
	enterToSave?: boolean;
	required?: boolean;
	value: SharingOptions;
	detectLinks?: boolean;
	disablePersonalOption?: boolean;
	freezer: Freezer;
} & Omit<GenemodInputProps, "label" | "value">;

export function FreezerSharingClickToEdit({
	edit,
	readOnly,
	placeholder,
	onComplete,
	enterToSave,
	value,
	required,
	detectLinks,
	disablePersonalOption,
	freezer,
	...props
}: FreezerSharingClickToEditProps): JSX.Element {
	const [_value, setValue] = useState(value);
	useEffect(() => {
		if (value !== _value) setValue(value);
	}, [value]);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;
	const { isLastWorkSpaceUser } = useCurrentWorkspaceUserHooks();
	const { planName } = useCurrentPlanNameHook();

	type SharingOptionsMapping = {
		[label in SharingOptions]: {
			title: string;
			plan?: PlanName;
			disabled?: boolean;
			tooltip?: string;
			hide?: boolean;
			dataCy?: string;
		};
	};

	const sharingOptions: SharingOptionsMapping = {
		personal: {
			title: "Just me",
			disabled: disablePersonalOption,
			dataCy: "sharing-object-buttons-just-me",
		},
		workspace: {
			title:
				planName === "enterprise"
					? "Workspaces in my organization"
					: "People in my workspace",
			disabled: isLastWorkSpaceUser,
			dataCy: "sharing-object-buttons-workspace",
		},
		org: {
			title: "My entire organization",
			plan: "enterprise",
			dataCy: "sharing-object-buttons-organization",
		},
	};

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		// onComplete(_value as number);
		setEditable(false);
	};

	const handleTextAreaLostFocus = (
		event:
			| React.FocusEvent<HTMLDivElement>
			| React.FocusEvent<HTMLInputElement>
	) => {
		if (!event.currentTarget.contains(event.relatedTarget as any)) {
			handleComplete();
		}
	};

	useSaveBeforeNav({ editable, showError: false, handleComplete });

	return (
		<Typography color="text-secondary" style={{ width: "100%" }}>
			{!editable ? (
				<div
					className={cn(
						styles.label,
						props?.wrapperProps?.className,
						{
							[styles.withLinks]: detectLinks,
							[styles.hover]: !readOnly,
						}
					)}
					onClick={!readOnly ? handleEditableTextClick : () => {}}
				>
					{_value !== null
						? sharingOptions[_value as keyof SharingOptionsMapping]
								.title
						: `${placeholder || "-"}`}
				</div>
			) : (
				<div
					className={styles.selectClickToEditContainer}
					onBlur={handleTextAreaLostFocus}
				>
					<Select
						value={value}
						onChange={(val) => {
							setValue(val as SharingOptions);
							setEditable(false);
							onComplete(val as SharingOptions);
						}}
						isInput
						optionLabelProp="label"
						dataCy="fridge-settings-editable-type"
						visible={true}
					>
						{["personal", "workspace", "org"].map((typeName) => {
							return (
								<Select.Option
									key={typeName}
									value={typeName}
									disabled={
										sharingOptions[
											typeName as keyof SharingOptionsMapping
										].disabled
									}
									label={
										sharingOptions[
											typeName as keyof SharingOptionsMapping
										].title
									}
									data-cy={`fridge-settings-editable-type-${typeName}`}
								>
									<Select.SelectedOpt
										isSelected={typeName === _value}
										label={
											sharingOptions[
												typeName as keyof SharingOptionsMapping
											].title
										}
									/>
								</Select.Option>
							);
						})}
					</Select>
				</div>
			)}
		</Typography>
	);
}

export type FreezerTypeClickToEditProps = {
	edit?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	onComplete: (value: number) => void;
	enterToSave?: boolean;
	required?: boolean;
	value: IdsInConstType<typeof FREEZER_TYPES>;
	detectLinks?: boolean;
} & Omit<GenemodInputProps, "label" | "value">;

export function FreezerTypeClickToEdit({
	edit,
	readOnly,
	placeholder,
	onComplete,
	enterToSave,
	value,
	required,
	detectLinks,
	...props
}: FreezerTypeClickToEditProps): JSX.Element {
	const [_value, setValue] = useState(value);
	useEffect(() => {
		if (value !== _value) setValue(value);
	}, [value]);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;
	const { Option } = Select;

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		onComplete(_value as number);
		setEditable(false);
	};

	const handleTextAreaLostFocus = (
		event:
			| React.FocusEvent<HTMLDivElement>
			| React.FocusEvent<HTMLInputElement>
	) => {
		if (!event.currentTarget.contains(event.relatedTarget as any)) {
			handleComplete();
		}
	};

	useSaveBeforeNav({ editable, showError: false, handleComplete });

	return (
		<Typography color="text-secondary" style={{ width: "100%" }}>
			{!editable ? (
				<div
					className={cn(
						styles.label,
						props?.wrapperProps?.className,
						{
							[styles.withLinks]: detectLinks,
							[styles.hover]: !readOnly,
						}
					)}
					onClick={!readOnly ? handleEditableTextClick : () => {}}
				>
					{_value !== null
						? FREEZER_TYPES[_value]
						: `${placeholder || "-"}`}
				</div>
			) : (
				<div
					className={styles.selectClickToEditContainer}
					onBlur={handleTextAreaLostFocus}
				>
					<Select
						value={value}
						onChange={(val) => {
							setValue(
								val as IdsInConstType<typeof FREEZER_TYPES>
							);
							setEditable(false);
							onComplete(val as number);
						}}
						isInput
						optionLabelProp="label"
						dataCy="fridge-settings-editable-type"
						visible={true}
					>
						{idKeysOfConst(FREEZER_TYPES).map((typeName) => {
							return (
								<Select.Option
									key={typeName}
									value={typeName}
									label={FREEZER_TYPES[typeName]}
									data-cy={`fridge-settings-editable-type-${typeName}`}
								>
									<Select.SelectedOpt
										isSelected={typeName === _value}
										label={FREEZER_TYPES[typeName]}
									/>
								</Select.Option>
							);
						})}
					</Select>
				</div>
			)}
		</Typography>
	);
}

export type FreezerItemTypeClickToEditProps = {
	edit?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	onComplete: (value: number) => void;
	enterToSave?: boolean;
	required?: boolean;
	value: number;
	detectLinks?: boolean;
} & Omit<GenemodInputProps, "label" | "value">;

export function FreezerItemTypeClickToEdit({
	edit,
	readOnly,
	placeholder,
	onComplete,
	enterToSave,
	value,
	required,
	detectLinks,
	...props
}: FreezerItemTypeClickToEditProps): JSX.Element {
	const [_value, setValue] = useState(value);
	useEffect(() => {
		if (value !== _value) setValue(value);
	}, [value]);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;
	const { Option } = Select;
	const { data = [] } = useItemTypesQuery(undefined, {});

	let itemTypes = [...data];
	const customItemTypeIndex = itemTypes.findIndex((type) => type.id >= 1000);
	const customItemTypes = itemTypes.splice(customItemTypeIndex);
	itemTypes = customItemTypes.concat(itemTypes);

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		onComplete(_value as number);
		setEditable(false);
	};

	const handleTextAreaLostFocus = (
		event:
			| React.FocusEvent<HTMLDivElement>
			| React.FocusEvent<HTMLInputElement>
	) => {
		if (!event.currentTarget.contains(event.relatedTarget as any)) {
			handleComplete();
		}
	};

	useSaveBeforeNav({ editable, showError: false, handleComplete });

	return (
		<Typography color="text-secondary" style={{ width: "100%" }}>
			{!editable ? (
				<div
					className={cn(
						styles.label,
						props?.wrapperProps?.className,
						{
							[styles.withLinks]: detectLinks,
							[styles.hover]: !readOnly,
						}
					)}
					onClick={!readOnly ? handleEditableTextClick : () => {}}
				>
					{_value !== null
						? itemTypes.find((v) => v.id === _value)?.name
						: `${placeholder || "-"}`}
				</div>
			) : (
				<div
					className={styles.selectClickToEditContainer}
					onBlur={handleTextAreaLostFocus}
				>
					<ItemTypeSelect
						value={value as number}
						defaultValue={value}
						onSelect={(value) => {
							setValue(value);
							onComplete(value);
						}}
						dataCy="fridge-settings-editable-item-type"
						isVisible={true}
					/>
				</div>
			)}
		</Typography>
	);
}

export type PriceClickToEditProps = {
	edit?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	onComplete: (
		price: string | number | undefined,
		currency: IdsInConstType<typeof CURRENCY_TYPES>
	) => void;
	enterToSave?: boolean;
	currencyValue: IdsInConstType<typeof CURRENCY_TYPES>;
	required?: boolean;
} & Omit<GenemodInputProps, "label">;

export function PriceClickToEdit({
	edit,
	readOnly,
	placeholder,
	onComplete,
	enterToSave,
	value,
	currencyValue,
	required,
	...props
}: PriceClickToEditProps): JSX.Element {
	const [_value, setValue] = useState(value);
	useEffect(() => {
		if (value !== _value) setValue(value);
	}, [value]);
	const [currency, setCurrency] =
		useState<IdsInConstType<typeof CURRENCY_TYPES>>(currencyValue);
	useEffect(() => {
		if (currencyValue !== currency) {
			setCurrency(currencyValue);
		}
	}, [currencyValue]);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;
	const [isEditingCurrency, setIsEditingCurrency] = useState(false);

	const priceValidators = [
		{
			validator: (val: any) => !isNaN(Number(val)),
			error: "Please enter a number",
		},
	];

	if (required) {
		priceValidators.push({
			validator: (val: any) => !!val,
			error: "Required",
		});
	}

	const [isValid, _] = useInputValidation(
		_value,
		[...priceValidators],
		props.onValidityChange
	);

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		const priceChanged = value !== _value;
		const currencyChanged = currencyValue !== currency;
		if (priceChanged || currencyChanged) {
			onComplete(_value, currency);
		}
		setEditable(false);
	};

	const handleInputLostFocus = (
		event:
			| React.FocusEvent<HTMLDivElement>
			| React.FocusEvent<HTMLInputElement>
	) => {
		if (!event.currentTarget.contains(event.relatedTarget as any)) {
			if (isValid) handleComplete();
		}
	};
	useSaveBeforeNav({ editable, showError: false, handleComplete });

	const inputContainer = useRef<HTMLDivElement | null>(null);
	useClickOutsideElement(inputContainer, () => {
		if (!isEditingCurrency && isValid) {
			handleComplete();
		}
		setIsEditingCurrency(false);
	});

	const handleCancel = () => {
		setEditable(false);
		setValue(value);
		setCurrency(currencyValue);
	};

	return (
		<Typography
			color={!_value ? "text-placeholder" : "text-secondary-v2"}
			variant="label"
			style={{
				width: "100%",
				position: "relative",
				marginRight: 8,
			}}
		>
			{!editable ? (
				<div
					className={`${styles.label} ${!readOnly && styles.hover} ${
						props?.wrapperProps?.className
					}`}
					onClick={!readOnly ? handleEditableTextClick : () => {}}
				>
					{value
						? `${CURRENCY_TYPES[currencyValue]} ${value}`
						: `${placeholder || "-"}`}
				</div>
			) : (
				<div
					className={styles.priceClickToEditContainer}
					onBlur={handleInputLostFocus}
					ref={inputContainer}
				>
					<CurrencyEditCell
						currency={currency || 0}
						price={String(_value) || ""}
						setCurrency={(e: any) => {
							setCurrency(
								e as IdsInConstType<typeof CURRENCY_TYPES>
							);
							setIsEditingCurrency(false);
						}}
						setPrice={(price: any) => {
							setValue(price);
						}}
						setIsEditingCurrency={setIsEditingCurrency}
					/>
				</div>
			)}
			{editable && (
				<ConfirmAndCancel
					onConfirm={handleComplete}
					onCancel={handleCancel}
					isValid={isValid as boolean}
				/>
			)}
		</Typography>
	);
}

type ConfirmAndCancelProps = {
	onConfirm?: (e: any) => void;
	onCancel?: (e: any) => void;
	isValid?: boolean;
	isDisabled?: boolean;
};

export function ConfirmAndCancel({
	onConfirm = () => {
		return;
	},
	onCancel = () => {
		return;
	},
	isValid = true,
	isDisabled,
}: ConfirmAndCancelProps): JSX.Element {
	return (
		<div className={styles.confirmAndCancelContainer}>
			<button
				disabled={isDisabled}
				className={classNames(styles.confirm, {
					[styles.confirmAndCancelDisabled]: isDisabled,
				})}
				// Setting it to onMouseDown so that this gets triggered before the parent component's onBlur
				onMouseDown={
					isValid
						? onConfirm
						: () => {
								return;
						  }
				}
				data-cy="click-to-edit-confirm-button"
			>
				<GenemodIcon name="checkmark" fill="text-inverse" />
			</button>
			<button
				className={styles.cancel}
				// Setting it to onMouseDown so that this gets triggered before the parent component's onBlur
				onMouseDown={onCancel}
				data-cy="click-to-edit-cancel-button"
			>
				<GenemodIcon name="exit" fill="text-secondary" />
			</button>
		</div>
	);
}

export default ClickToEdit;

type UseSaveBeforeNav = {
	editable: boolean;
	showError: boolean | string;
	handleComplete: () => void;
	addListener?: boolean;
};

/**
 * Save the click to edits before navigating away.
 */
const useSaveBeforeNav = ({
	editable,
	showError,
	handleComplete,
	addListener = true,
}: UseSaveBeforeNav) => {
	const history = useHistory();

	useEffect(() => {
		let unlisten = () => {};
		if (addListener) {
			unlisten = history.listen(() => {
				if (editable && !showError) {
					handleComplete();
				}
			});
		}
		return () => unlisten();
	}, [history, editable, showError, handleComplete, addListener]);
};

type QuantityClickToEditProps = {
	value: number;
	unitValue: string;
	onComplete?: (quantity: number, unit: string) => void;
	readOnly?: boolean;
	typographyStyle?: Omit<CSSProperties, "color">;
};

export const QuantityClickToEdit = ({
	value,
	unitValue,
	onComplete,
	readOnly,
	typographyStyle,
}: QuantityClickToEditProps): JSX.Element => {
	const [editable, setEditable] = useState(false);

	const { consumable_id } = useSearchParams<{ consumable_id: number }>();
	type Schema = {
		quantity: number;
		unit: string;
	};

	const validationSchema = z.object({
		quantity: z.number().min(1).max(MAX_INTEGER),
		unit: z.string().optional(),
	});

	const { control, handleSubmit, formState, reset, watch, setValue } =
		useForm<Schema>({
			defaultValues: {
				quantity: value,
				unit: unitValue,
			},
			resolver: zodResolver(validationSchema),
		});

	const inputWrapper = useRef<HTMLDivElement | null>(null);
	useClickOutsideElement(inputWrapper, () => {
		onSubmit();
	});

	useEffect(() => {
		setValue("quantity", value);
		setValue("unit", unitValue);
	}, [value, unitValue]);

	if (!editable) {
		return (
			<Typography
				className={`${styles.label} ${!readOnly && styles.hover}`}
				onClick={() => (!readOnly ? setEditable(true) : () => {})}
				style={{
					...typographyStyle,
					marginLeft: -8,
				}}
				color="text-secondary-v2"
			>
				{`${formatQuantity(value)} ${unitValue || ""}`}
			</Typography>
		);
	}

	const onSubmit = handleSubmit((data) => {
		if (consumable_id) {
			const quantityChanged =
				formState.defaultValues?.quantity !== data.quantity;
			const unitChanged = formState.defaultValues?.unit !== data.unit;
			if (quantityChanged || unitChanged) {
				onComplete?.(data.quantity, data.unit);
				reset({
					quantity: data.quantity,
					unit: data.unit,
				});
			}
		}
		setEditable(false);
	});

	return (
		<div ref={inputWrapper} className={styles.quantityClickToEdit}>
			<Controller
				defaultValue={value}
				control={control}
				name="quantity"
				render={({ field: { name, onChange, value: _value } }) => (
					<Input
						onChange={(e) => onChange(Number(e.target.value))}
						autoFocus
						type="number"
						name={name}
						useNumberArrows
						numberArrowsOrientation="vertical"
						inputProps={{
							style: { paddingRight: 0, paddingLeft: 8 },
						}}
						wrapperProps={{
							style: { width: "auto", marginBottom: 0 },
						}}
						value={_value}
						arrowIcon="chevron"
						onKeyDown={(e: any) => {
							if (e.key === "Enter") {
								onSubmit();
							}
						}}
					/>
				)}
			/>
			<Controller
				control={control}
				name="unit"
				render={({ field: { name, onChange, value: _value } }) => (
					<Input
						placeholder="unit"
						onChange={onChange}
						name={name}
						value={_value}
						onKeyDown={(e: any) => {
							if (e.key === "Enter") {
								onSubmit();
							}
						}}
					/>
				)}
			/>
			<ConfirmAndCancel
				onConfirm={() => {
					onComplete?.(watch("quantity"), watch("unit"));
					setEditable(false);
				}}
				onCancel={() => {
					setEditable(false);
					reset();
				}}
				isDisabled={!formState.errors}
			/>
		</div>
	);
};
