import { AutoComplete, AutoCompleteProps } from "antdv5";
import classNames from "classnames";
import React, { HTMLProps } from "react";
import GenemodIcon from "../GenemodIcon/GenemodIcon";
import InputV3 from "../InputV3/InputV3";
import Typography from "../Typography/Typography";
import styles from "./SearchBarV2.module.scss";
import Input from "../Input/Input";

type SearchBarV2Props = Omit<AutoCompleteProps, "placeholder"> & {
	dataCy?: string;
	placeholder?: string;
	prefix?: React.ReactNode;
	showPrefix?: boolean;
	disableSuggestions?: boolean;
	onEnter?: () => void;
	searchButton?: boolean;
	onSearchButtonClick?: () => void;
	wrapperProps?: HTMLProps<HTMLDivElement>;
};

const SearchBarV2 = ({
	placeholder,
	prefix,
	showPrefix = true,
	disableSuggestions,
	onEnter,
	onClear,
	searchButton,
	onSearchButtonClick,
	wrapperProps,
	...props
}: SearchBarV2Props) => {
	const getPrefix = () => {
		if (!showPrefix) return null;

		if (prefix) return prefix;

		return <GenemodIcon name="search" color="text-tertiary-v2" />;
	};

	return (
		<div
			{...wrapperProps}
			className={classNames(styles.wrapper, wrapperProps?.className)}
		>
			<AutoComplete
				{...props}
				defaultActiveFirstOption
				className={classNames(
					styles.autocomplete,
					{
						[styles.withSearchButton]: searchButton,
					},
					props.className
				)}
				popupClassName={classNames(styles.popup, props.popupClassName)}
				open={disableSuggestions || undefined}
				prefix={getPrefix()}
				placeholder={placeholder}
				allowClear={{
					clearIcon: (
						<GenemodIcon
							name="circle-cancel"
							color="text-tertiary-v2"
						/>
					),
				}}
				onClear={onClear}
			/>
			{searchButton && (
				<div
					className={styles.searchButtonIconContainer}
					onClick={onSearchButtonClick}
				>
					<Typography medium color="text-on-color">
						Search
					</Typography>
				</div>
			)}
		</div>
	);
};

export default SearchBarV2;
