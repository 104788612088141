import { Freezer, FREEZER_TYPES, SharedInternalObj } from "@common/types";
import {
	Demo,
	DemoSection,
	DemoWrapper,
	DropDown,
	DropdownV2,
	GenemodIcon,
	Modal,
	Notification,
	Typography,
} from "@components";
import DeleteConfirmModal from "@containers/Freezer/components/DeleteConfirmModal/DeleteConfirmModal";
import { useExportState } from "@containers/Freezer/hooks";
import useCurrentFeatureRestriction from "@helpers/Hooks/useCurrentFeatureRestrictionHook";
import useCurrentWorkspaceUserHooks from "@helpers/Hooks/UseCurrentWorkspaceUserHook";
import { useCommonModalState } from "@redux/CommonModals/hooks";

import {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDownV2/dropdownV2";
import { useHistory } from "@common/helpers/Hooks/UseRouterDom";
import { truncArgs } from "@helpers/Formatters";
import { fillInUrlParams } from "@helpers/TypeHelpers";
import { wasCreatedOnTheLastFiveSeconds } from "@helpers/UXHelpers";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import {
	useFreezerDeleteMutation,
	useFreezerUpdateMutation,
} from "@redux/inventory/Freezer";
import { useLazyGetLastAddedItemQuery } from "@redux/inventory/Item";
import { useOrganizationRouter } from "@root/AppRouter";
import { Menu } from "antd";
import { ClickParam } from "antd/lib/menu";
import { DropDownProps } from "antdv4";
import cn from "classnames";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { FREEZER_PATHS } from "../..";
import styles from "./FreezerCard.module.scss";
import { useSharingState } from "@helpers/Hooks/SharedObjectHooks";

type FreezerButtonProps = {
	// The freezer data associated with this freezer card
	freezer: Freezer;
};

export default function FreezerButton(props: FreezerButtonProps): JSX.Element {
	const { freezer } = props;
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const { handleExport: _handleExport, exportModal } = useExportState();

	const { isCurrentWorkspaceUserAdmin } = useCurrentWorkspaceUserHooks();

	const { featureRestrictionData, fetchFeatureRestriction } =
		useCurrentFeatureRestriction();
	// Loading status
	const [isLoading, setLoading] = useState(false);
	const { isFridgeSettingsVisible, openFridgeSettings } =
		useCommonPanelState("fridgeSettings");

	const [updateFreezer] = useFreezerUpdateMutation();
	const [deleteFreezer] = useFreezerDeleteMutation();

	// Deleting stuff
	const [showDelete, setDelete] = useState(false);
	const [
		privateFreezerRestoreModalVisible,
		setPrivateFreezerRestoreModalVisible,
	] = useState(false);
	// Freezer options dropdown menu
	const [isMenuVisible, setMenuVisibility] = useState(false);
	// Controls the visiblity of ArchivedFreezerModal
	const [archiveModalVis, setArchiveModalVis] = useState<boolean>(false);

	const [getLastAddedItem] = useLazyGetLastAddedItemQuery();

	const { selectedSharingOption } = useSharingState(
		freezer as SharedInternalObj<Freezer>,
		false
	);

	const cleanup = () => {
		setLoading(false);
		setDelete(false);
	};

	const handleDelete = () => {
		setMenuVisibility(false);
		setDelete(true);
	};

	const handleEdit = () => {
		setMenuVisibility(false);
		openFridgeSettings({ id: freezer.id });
	};

	const handleDeleteFreezer = async () => {
		setLoading(true);
		deleteFreezer(freezer.id)
			.unwrap()
			.then(() => {
				cleanup();
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${freezer.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
				setLoading(false);
				fetchFeatureRestriction();
			})
			.catch(() =>
				Notification.warning({
					message: `Failed to delete freezer. Try again or contact us if it continues.`,
				})
			);
	};

	const handleBtnClick = () => {
		if (!isFridgeSettingsVisible && !showDelete && !exportModal) {
			history.push(
				appendBaseUrl(
					FREEZER_PATHS.CONTENTS.replace(":id", freezer.id + "")
				)
			);
		}
	};

	// Fetches data and navigates to the most recently
	// added item
	const handleViewRecent = () => {
		setMenuVisibility(false);
		getLastAddedItem({
			freezerId: freezer.id,
			params: {
				exclude: ["items", "path", "shelves"],
			},
		})
			.unwrap()
			.then((res) => {
				const { freezer, last_added, box, item_group } = res;
				// Generate the path
				let path = "";
				box &&
					(path = fillInUrlParams(FREEZER_PATHS.BOXES)({
						freezer_id: freezer.id,
						box_id: box.id,
					}));
				item_group &&
					(path = fillInUrlParams(FREEZER_PATHS.ITEMGROUPS)({
						freezer_id: freezer.id,
						item_group_id: item_group.id,
					}));

				if (!path) {
					throw new Error("No path found");
				}

				path += "?item_id=" + last_added.id;
				history.push(appendBaseUrl(path));
			})
			.catch(() => {
				Notification.warning({
					message:
						"This freezer is empty. Please add an item to use this feature.",
				});
			});
	};

	const handleExport = () => {
		setMenuVisibility(false);
		_handleExport("freezers", freezer.id, freezer.name);
	};

	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const handleStatusUpdate = (isArchive: boolean) => {
		const reachedSharedLimit =
			!!featureRestrictionData?.shared_freezer?.is_limit_reached;
		const reachedPrivateLimit =
			!!featureRestrictionData?.private_freezer?.is_limit_reached;

		if (!isArchive) {
			if (freezer.is_shared && reachedSharedLimit) {
				openUpgradeModal({
					type: "FREEZER",
				});
				return;
			}

			if (selectedSharingOption === "personal" && reachedPrivateLimit) {
				setPrivateFreezerRestoreModalVisible(true);
				return;
			}
		}

		updateFreezer({
			id: freezer.id,
			is_archived: isArchive,
		})
			.unwrap()
			.then((response) => {
				if ("error" in response) {
					Notification.warning({
						message: `Failed to ${
							isArchive ? "archive" : "restore"
						} "${freezer.name}"`,
					});
				} else {
					Notification.success({
						message: (
							<span>
								<b>{freezer.name}</b>
								{isArchive
									? " has been archived."
									: " has been restored."}
							</span>
						),
					});
					fetchFeatureRestriction();
				}
				setArchiveModalVis(false);
			});
	};

	return (
		<>
			<FreezerCard
				freezer={props.freezer}
				isAdmin={!!isCurrentWorkspaceUserAdmin}
				optionsMenu={
					<FreezerBtnMenu
						isAdmin={!!isCurrentWorkspaceUserAdmin}
						freezer={props.freezer}
						onView={handleViewRecent}
						onEdit={handleEdit}
						onDelete={handleDelete}
						onExport={handleExport}
						onSelect={() => setMenuVisibility(false)}
						onArchive={() => {
							setMenuVisibility(false);
							setArchiveModalVis(true);
						}}
						onRestore={() => {
							setMenuVisibility(false);
							handleStatusUpdate(false);
						}}
					/>
				}
				isMenuVisible={isMenuVisible}
				onMenuVisibilityChange={setMenuVisibility}
				onClick={handleBtnClick}
			/>
			<DeleteConfirmModal
				title={"Delete this freezer?"}
				itemName={freezer.name || "freezer"}
				onConfirm={handleDeleteFreezer}
				loading={isLoading}
				onCancel={() => setDelete(false)}
				visible={showDelete}
				type="freezer"
			/>
			<ArchiveFreezerModal
				visible={archiveModalVis}
				onCancel={() => setArchiveModalVis(false)}
				onArchive={() => handleStatusUpdate(true)}
			/>
			<PrivateFreezerRestoreModal
				visible={privateFreezerRestoreModalVisible}
				onCancel={() => setPrivateFreezerRestoreModalVisible(false)}
			/>
			{exportModal}
		</>
	);
}

type FreezerCardProps = {
	// The freezer data associated with the card
	freezer: Freezer;
	// Whether the current user is admin
	isAdmin: boolean;
	// Available options of the freezer card
	optionsMenu: DropDownProps["overlay"];
	// A function called when users click on the freezer card
	onClick: MouseEventHandler<HTMLDivElement>;
	// Whether the dropdown menu is visible to users
	isMenuVisible: boolean;
	// Called when the menu visibility changes
	onMenuVisibilityChange: (visible: boolean) => any;
	//Data-cy attribute for cypress testing
	dataCy?: string;
};
function FreezerCard({
	freezer,
	isAdmin,
	optionsMenu,
	onClick,
	isMenuVisible,
	onMenuVisibilityChange,
	dataCy,
}: FreezerCardProps) {
	const {
		name,
		freezer_type,
		is_shared,
		is_archived,
		is_locked,
		sharing,
		updated_at,
	} = freezer;
	const [isMenuVisibleState, setMenuVisibility] = useState(false);
	const handleDropdownMenu = (visibility: boolean) => {
		setMenuVisibility(visibility);
		if (onMenuVisibilityChange) onMenuVisibilityChange(visibility);
	};

	// Sync menu visibility with props
	useEffect(() => {
		if (isMenuVisible !== isMenuVisibleState) {
			setMenuVisibility(isMenuVisible);
		}
	}, [isMenuVisible, isMenuVisibleState]);

	const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!isMenuVisibleState && onClick && !is_locked) {
			onClick(e);
		}
	};

	const dropDownMenuContent = (
		<DropdownV2
			visible={isMenuVisibleState}
			onVisibleChange={handleDropdownMenu}
			overlay={optionsMenu}
			type="kabob"
			hoverEffect
		/>
	);

	const disabled = is_locked;

	return (
		<>
			<div className={styles.hoverContainer}>
				<div
					className={cn(styles.container, "genemod-freezer-card", {
						[styles.inactive]: disabled,
						[styles.archived]: is_archived,
						[styles.container__focused]: isMenuVisible,
						isNewCardLikeComponent:
							wasCreatedOnTheLastFiveSeconds(freezer),
					})}
					data-cy={`freezer-card-${name.replace(/\s/g, "")}`}
				>
					<div
						className={cn(styles.content, {
							[styles.noHoverEffect]: disabled,
							[styles.archived]: is_archived,
						})}
						onClick={handleClick}
					>
						<div className={styles["shared-locked-indicator"]}>
							<GenemodIcon
								className={styles.cardIcons}
								name={(() => {
									if (is_locked) {
										return "lock";
									}
									if (sharing === "personal") {
										return "private";
									}
									if (sharing === "workspace") {
										return "people-group";
									}
									return "building-o";
								})()}
								color={
									disabled ? "text-ghost" : "accent-subtle"
								}
								fill={disabled ? "text-ghost" : "accent-subtle"}
								size="large"
							/>
						</div>
						<div className={styles.detailsContainer}>
							<div className={styles["freezer-details"]}>
								<Typography
									className={styles["freezer-name"]}
									ellipsis
									variant="headline4"
									color={
										disabled ? "text-ghost" : "text-primary"
									}
									medium
								>
									{name}
								</Typography>
								<Typography
									style={{ marginTop: 2 }}
									variant="body"
									resize={false}
									color={
										disabled
											? "text-ghost"
											: "text-tertiary"
									}
								>
									{FREEZER_TYPES[freezer_type]}
								</Typography>
							</div>
						</div>
					</div>
					<div
						className={styles.kabob}
						data-cy={`freezer-card-${name.replace(/\s/g, "")}-menu`}
					>
						{optionsMenu && (
							<>
								{!is_archived || !is_shared ? (
									<>{dropDownMenuContent}</>
								) : (
									<>{isAdmin && <>{dropDownMenuContent}</>}</>
								)}
							</>
						)}
						<div
							className={styles.optionsFiller}
							onClick={handleClick}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

type FreezerBtnMenuProps = {
	// The freezer data associated with this menu
	freezer: Freezer;
	// Whether the current user is an admin
	isAdmin: boolean;
	// When clicking "View last added" on the menu
	onView: () => any;
	// When clicking "Settings" on the menu
	onEdit: () => any;
	// When clicking "Export to CSV" on the menu
	onExport: () => any;
	// When clicking "Delete freezer " on the menu
	onDelete: () => any;
	// When freezercard is selected
	onSelect: () => any;
	// WHen clicking "Archive" on the menu
	onArchive: () => any;
	// When clicking "Restore" on the menu
	onRestore: () => any;
};
function FreezerBtnMenu(props: FreezerBtnMenuProps) {
	const Item = Menu.Item;
	const isArchived = props.freezer.is_archived;
	const isLocked = props.freezer.is_locked;
	const enabled = !(isArchived || isLocked);
	const isShared = props.freezer.is_shared;
	const accessible = !isShared || props.isAdmin;

	const handleMenuSelection = ({ key }: ClickParam) => {
		if (key === "viewLastCreated") {
			props.onView();
		} else if (key === "setting") {
			props.onEdit();
		} else if (key === "restore") {
			props.onRestore();
		} else if (key === "archive") {
			props.onArchive();
		} else if (key === "delete") {
			props.onDelete();
		}
	};

	return (
		<Menu onClick={handleMenuSelection}>
			{enabled && (
				<DropdownMenuItem
					key="viewLastCreated"
					label="View last created"
					data-cy="freezer-card-menu-viewLastCreated"
				/>
			)}
			{enabled && (
				<DropdownMenuItem
					key="setting"
					label="Settings"
					data-cy="freezer-card-menu-settings"
				/>
			)}
			{accessible && isArchived && (
				<DropdownMenuItem
					key="restore"
					label="Restore"
					data-cy="freezer-card-menu-restore"
				/>
			)}
			{accessible && !isArchived && (
				<DropdownMenuItem
					key="archive"
					label="Archive"
					data-cy="freezer-card-menu-archive"
				/>
			)}
			{accessible && (
				<DropdownMenuItem
					key="delete"
					label="Delete"
					color="dust-red"
					data-cy="freezer-card-menu-delete"
				/>
			)}
		</Menu>
	);
}

type ArchiveFreezerModalProps = {
	// Controls the visibility of the modal
	visible: boolean;
	// Called when users closed the modal
	onCancel: () => void;
	// Called when users click on "Move to archive"
	onArchive: () => void;
};
function ArchiveFreezerModal({
	visible,
	onCancel,
	onArchive,
}: ArchiveFreezerModalProps) {
	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			onOk={onArchive}
			title="Move freezer to archive?"
			okText="Move to archive"
			hideCancelButton
			dataCy="archive-modal"
		>
			You will not be able to modify the freezer, and the items will be
			unavailable for search.
		</Modal>
	);
}

type PrivateFreezerRestoreModalProps = {
	// Controls the visibility of the modal
	visible: boolean;
	// Called when users closed the modal
	onCancel: () => void;
};
function PrivateFreezerRestoreModal({
	visible,
	onCancel,
}: PrivateFreezerRestoreModalProps) {
	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			onOk={onCancel}
			title="Limit reached"
			okText="Got it"
			hideCancelButton
		>
			You reached the limit of one private freezer. To restore this
			freezer, archive or delete your active private freezer first.
		</Modal>
	);
}

export function FREEZERCARD_DEMO(): JSX.Element {
	const unsharedFreezer: Partial<Freezer> = {
		id: -1,
		name: "Private Freezer",
	};
	const sharedFreezer: Partial<Freezer> = {
		id: -1,
		name: "Private Shared Freezer",
		is_shared: true,
	};

	const longFreezer: Partial<Freezer> = {
		id: -1,
		name: "Looooooooooong name",
		is_shared: true,
	};

	const lntFreezer: Partial<Freezer> = {
		id: -1,
		name: "Test Freezer",
		freezer_type: FREEZER_TYPES.NITROGEN,
	};
	const noMenu: Partial<Freezer> = {
		id: -1,
		name: "No menu",
	};
	const FreezerCardReduced = FreezerCard as any;
	const FreezerBtnMenuReduced = FreezerBtnMenu as any;
	return (
		<DemoWrapper>
			<DemoSection>
				<div>simple</div>
				<Demo
					title="Basic usage"
					description="Displays information about a given freezer"
				>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced
							freezer={sharedFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced
							freezer={unsharedFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced
							freezer={lntFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
				</Demo>
				<Demo
					title="Options menu"
					description="Any menu can be used. Also possible to have no menu"
				>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced
							freezer={unsharedFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced
							freezer={unsharedFreezer}
							optionsMenu={
								<Menu>
									<Menu.Item>Option 1</Menu.Item>
									<Menu.Item>Option 2</Menu.Item>
									<Menu.Item>Option 3</Menu.Item>
								</Menu>
							}
						/>
					</div>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced freezer={noMenu} />
					</div>
				</Demo>
				<Demo
					title="Edge cases"
					description="Long freezer names are converted into ellipsis if cut off. Min width is 156px (100px for name, 24 for the lock, 32 for the options)."
				>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced freezer={longFreezer} />
					</div>
					<div style={{ width: "250px" }}>
						<FreezerCardReduced
							freezer={longFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
					<div style={{ width: "400px" }}>
						<FreezerCardReduced
							freezer={longFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
					<div style={{ width: "50px" }}>
						<FreezerCardReduced
							freezer={longFreezer}
							optionsMenu={<FreezerBtnMenuReduced />}
						/>
					</div>
				</Demo>
			</DemoSection>
		</DemoWrapper>
	);
}

type FreezerMenuProps = {
	children?: React.ReactNode;
	freezer: Freezer;
	redirectAfterDeletingOrArchiving?: boolean;
};

export const FreezerMenu = ({
	children,
	freezer,
	redirectAfterDeletingOrArchiving,
}: FreezerMenuProps) => {
	const [getLastAddedItem] = useLazyGetLastAddedItemQuery();
	const [menuVisibility, setMenuVisibility] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [showDelete, setDelete] = useState(false);
	const { isCurrentWorkspaceUserAdmin } = useCurrentWorkspaceUserHooks();
	const { appendBaseUrl } = useOrganizationRouter();
	const history = useHistory();
	const { openFridgeSettings } = useCommonPanelState("fridgeSettings");
	const { featureRestrictionData, fetchFeatureRestriction } =
		useCurrentFeatureRestriction();
	const [deleteFreezer] = useFreezerDeleteMutation();
	const { handleExport: _handleExport, exportModal } = useExportState();
	const [archiveModalVis, setArchiveModalVis] = useState<boolean>(false);
	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const [
		privateFreezerRestoreModalVisible,
		setPrivateFreezerRestoreModalVisible,
	] = useState(false);
	const [updateFreezer] = useFreezerUpdateMutation();

	const cleanup = () => {
		setLoading(false);
		setDelete(false);
	};

	const handleViewRecent = () => {
		setMenuVisibility(false);
		getLastAddedItem({
			freezerId: freezer.id,
			params: {
				exclude: ["items", "path", "shelves"],
			},
		})
			.unwrap()
			.then((res) => {
				const { freezer, last_added, box, item_group } = res;
				// Generate the path
				let path = "";
				box &&
					(path = fillInUrlParams(FREEZER_PATHS.BOXES)({
						freezer_id: freezer.id,
						box_id: box.id,
					}));
				item_group &&
					(path = fillInUrlParams(FREEZER_PATHS.ITEMGROUPS)({
						freezer_id: freezer.id,
						item_group_id: item_group.id,
					}));

				if (!path) {
					throw new Error("No path found");
				}

				path += "?item_id=" + last_added.id;
				history.push(appendBaseUrl(path));
			})
			.catch(() => {
				Notification.warning({
					message:
						"This freezer is empty. Please add an item to use this feature.",
				});
			});
	};

	const handleDeleteFreezer = async () => {
		try {
			const result = await deleteFreezer(freezer.id);
			cleanup();
			history.push(appendBaseUrl(FREEZER_PATHS.HOME));
			Notification.success({
				message: (
					<span>
						<b>{truncArgs`${freezer.name}`(68)}</b>
						{" has been deleted."}
					</span>
				),
			});
			fetchFeatureRestriction();
		} catch (error) {
			Notification.warning({
				message: `Failed to delete freezer. Try again or contact us if it continues.`,
			});
		}
	};

	const handleDelete = () => {
		setMenuVisibility(false);
		setDelete(true);
	};

	const handleEdit = () => {
		setMenuVisibility(false);
		openFridgeSettings({ id: freezer.id });
	};

	const handleExport = () => {
		setMenuVisibility(false);
		_handleExport("freezers", freezer.id, freezer.name);
	};

	const handleStatusUpdate = (isArchive: boolean) => {
		const reachedSharedLimit =
			!!featureRestrictionData?.shared_freezer?.is_limit_reached;
		const reachedPrivateLimit =
			!!featureRestrictionData?.private_freezer?.is_limit_reached;

		if (!isArchive) {
			if (freezer.is_shared && reachedSharedLimit) {
				openUpgradeModal({
					type: "FREEZER",
				});
				return;
			}

			if (!freezer.is_shared && reachedPrivateLimit) {
				setPrivateFreezerRestoreModalVisible(true);
				return;
			}
		}

		updateFreezer({
			id: freezer.id,
			is_archived: isArchive,
		})
			.unwrap()
			.then((response) => {
				if ("error" in response) {
					Notification.warning({
						message: `Failed to ${
							isArchive ? "archive" : "restore"
						} "${freezer.name}"`,
					});
				} else {
					history.push(appendBaseUrl(FREEZER_PATHS.HOME));
					Notification.success({
						message: (
							<span>
								<b>{freezer.name}</b>
								{isArchive
									? " has been archived."
									: " has been restored."}
							</span>
						),
					});
					fetchFeatureRestriction();
				}
				setArchiveModalVis(false);
			});
	};

	const handleDropdownMenu = (visibility: boolean) => {
		setMenuVisibility(visibility);
	};

	return (
		<>
			<DropdownV2
				visible={menuVisibility}
				onVisibleChange={handleDropdownMenu}
				overlay={
					<FreezerBtnMenu
						freezer={freezer}
						isAdmin={!!isCurrentWorkspaceUserAdmin}
						onView={handleViewRecent}
						onEdit={handleEdit}
						onDelete={handleDelete}
						onExport={handleExport}
						onSelect={() => setMenuVisibility(false)}
						onArchive={() => {
							setMenuVisibility(false);
							setArchiveModalVis(true);
						}}
						onRestore={() => {
							setMenuVisibility(false);
							handleStatusUpdate(false);
						}}
					/>
				}
				hoverEffect
				type="meatballs"
				key="dropdown"
			>
				{children}
			</DropdownV2>
			<DeleteConfirmModal
				title={"Delete this freezer?"}
				itemName={freezer.name || "freezer"}
				onConfirm={handleDeleteFreezer}
				loading={isLoading}
				onCancel={() => setDelete(false)}
				visible={showDelete}
				type="freezer"
			/>
			<ArchiveFreezerModal
				visible={archiveModalVis}
				onCancel={() => setArchiveModalVis(false)}
				onArchive={() => handleStatusUpdate(true)}
			/>
			<PrivateFreezerRestoreModal
				visible={privateFreezerRestoreModalVisible}
				onCancel={() => setPrivateFreezerRestoreModalVisible(false)}
			/>
			{exportModal}
		</>
	);
};
