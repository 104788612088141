import { Avatar, AxisDirection, ISOString, Item, TypeData } from ".";

export type ItemLocationData = {
	freezer?: number;
	shelf?: number;
	rack?: number;
	category?: number;
	box?: number;
	item_group?: number;
	item?: number;
	shelf_name?: string;
	shelf_is_archived?: boolean;
	rack_name?: string;
	rack_is_archived?: boolean;
	category_name?: string;
	category_is_archived?: boolean;
	freezer_name?: string;
	freezer_is_archived?: boolean;
};
export type DefaultSearchSetting = {
	column_order: string;
	volume: boolean;
	concentration: boolean;
	location: boolean;
	expiration_date: boolean;
	added_by: boolean;
	notes: boolean;
};

export type SearchSetting = {
	[item_id: string]: DefaultSearchSetting & {
		[schema_id: string]: boolean;
	};
};

/** user search setting */
export type SearchSettings = SearchSetting & {
	is_grouped?: boolean;
};

export type GroupedSearchResultItem = {
	count: number;
	custom_ids: number[];
	ids: number[];
	item_type: number;
	max_custom_id: number;
	min_custom_id: number;
	name: string;
	created_by: Avatar;
	location: ItemLocation;
	organization_prefix: string;
	location_data: ItemLocationMeta;
};

type ItemLocation = {
	id: number;
	box_location?: { id: number; row: number; col: number; box: number };
	item_group?: number;
	box?: number;
	freezer: number;
};

export type ItemLocationMeta = {
	freezer_name?: string;
	freezer_is_archived?: boolean;
	box_name?: string;
	box_is_archived?: boolean;
	item_group_name?: string;
	item_group_is_archived?: boolean;
	axis_direction?: AxisDirection;
	shelf_is_archived?: boolean;
	rack_is_archived?: boolean;
};

export type UngroupedSearchResultItem = {
	catalog: string;
	concentration: string;
	created_at: ISOString;
	added_at: ISOString;
	currency: number;
	custom_id: number;
	expiration_date: ISOString;
	id: number;
	is_bookmarked: boolean;
	item_type: number;
	updated_by: Avatar;
	location: ItemLocation;
	lot: string;
	name: string;
	notes: string;
	created_by: Avatar;
	packaging: string;
	organization_prefix: string;
	price: string;
	reference: string;
	source: string;
	tags: string;
	type_data: TypeData;
	updated_at: ISOString;
	volume: string;
	location_data: ItemLocationMeta;
};

export type SearchResultItem =
	| GroupedSearchResultItem
	| UngroupedSearchResultItem;

export type FilterOption = {
	name: string;
	count: number;
	value: number;
	search: string;
};

export type SearchResults = {
	has_locked_freezers?: boolean;
	total_page_count: number;
	total_count: number;
	filter_options: {
		freezer: FilterOption[];
		item_type: FilterOption[];
		created_by: (Omit<FilterOption, "name"> & { name: Avatar })[];
	};
	results: SearchResultItem[];
	current_page_num: number;
};

export type SearchFilterState = {
	search: string | null;
	page_size: number;
	item_type: string | null;
	added_start: string | null;
	added_end: string | null;
	sort_by: string | null;
	page: number;
	created_by: number[];
	freezer: number[];
	items_per_page: number;
	expandedRow: number;
	expandedRowData: Item[];
	is_grouped: boolean;
	is_shared?: boolean;
	initial?: boolean;
	extra_fields?: boolean;
};
