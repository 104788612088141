import {
	GenemodIcon,
	LayerSystemContainer,
	LoadingSpinner,
	SearchBar,
	Typography,
} from "@common/components";
import { useFurnitureCategoriesSearchQuery } from "@redux/freezer/FreezerApiSlice";
import React, { useCallback, useMemo, useState } from "react";
import styles from "./AssignLocationPopupV2.module.scss";
import { useDebounceEventHandler } from "@helpers/Hooks";
import {
	Box,
	Category,
	Freezer,
	ITEM_STATUS,
	Rack,
	TableItem,
	ConsumableAlertScopeSearchResult,
	Shelf,
	BoxLocationData,
} from "@common/types";
import { useGetRepositoryFreezersQuery } from "@redux/inventory/Freezer";
import { useParams } from "@helpers/URLParams";
import { useGetRepositoryRacksQuery } from "@redux/inventory/Rack";
import { useGetRepositoryShelvesQuery } from "@redux/inventory/Shelf";
import { useGetRepositoryCategoriesQuery } from "@redux/inventory/Category";
import { useGetRepositoryBoxesQuery } from "@redux/inventory/Box";
import { FreezerLayers } from "@containers/Freezer/Repository/components/FreezerTab";
import { useGetRepositoryItemGroupsQuery } from "@redux/inventory/ItemGroup";
import FreezerItem from "./FreezerItem";
import {
	useFurnitureCategoriesQuery,
	useFurnituresQuery,
	useListSpacesQuery,
} from "@redux/freezer/ConsumableApiSlice";
import {
	ConsumableLayers,
	ConsumableTableItems,
} from "@containers/Freezer/Repository/components/ConsumablesTab";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import ConsumableItem from "./ConsumableItem";
import { capitalizeFirstLetter } from "@helpers/Formatters";

const FREEZER_PARENT_LAYERS: { [key in FreezerLayers]: FreezerLayers[] } = {
	FREEZER: [],
	SHELF: ["FREEZER"],
	RACK: ["SHELF"],
	CATEGORY: ["SHELF"],
	BOX: ["RACK", "CATEGORY"],
	ITEMGROUP: ["CATEGORY"],
	ITEM: ["BOX", "ITEMGROUP"],
};

const FREEZER_CHILDREN_LAYERS: { [key in FreezerLayers]?: string } = {
	FREEZER: "Shelf",
	SHELF: "Rack/Category",
	RACK: "Box",
	CATEGORY: "Box/Item Group",
};

const CONSUMABLE_CHILDREN_LAYERS: { [key in ConsumableLayers]?: string } = {
	SPACE: "Furniture",
	FURNITURE: "Category",
	CATEGORY: "Consumable",
};

type FreezerLayerItem = {
	item: TableItem;
	layer: FreezerLayers;
};

type ConsumableLayerItem = {
	item: ConsumableTableItems;
	layer: ConsumableLayers;
};

type BredcrumbItem = FreezerLayerItem | ConsumableLayerItem;

type Props = {
	selectedTableItem?: number;
	onSelect?: (
		id: number,
		tableItem: TableItem,
		selectedLayer: FreezerLayers
	) => void;
	onSelectConsumable?: (categoryId: number) => void;
	isConsumable?: boolean;
};

export default ({
	isConsumable = false,
	onSelect,
	onSelectConsumable,
}: Props) => {
	const [search, setSearch] = useState("");
	const [termSearch, setTermSearch] = useState("");
	const [itemsBreadcrumb, setItemsBreadcrumb] = useState<BredcrumbItem[]>([]);
	const { getParam } = useParams();
	const layer = getParam("layer") as FreezerLayers;

	const selectedItem = useMemo(() => {
		if (!itemsBreadcrumb.length) return undefined;
		return itemsBreadcrumb[itemsBreadcrumb.length - 1];
	}, [itemsBreadcrumb]);
	const selectedLayer = selectedItem?.layer;

	const getFilterSelectedItem = (currentLayer: FreezerLayers) => {
		if (termSearch?.length) return undefined;
		return selectedLayer === currentLayer
			? selectedItem?.item?.id
			: undefined;
	};

	const getSkipFreezerLayer = (
		currentLayer: FreezerLayers,
		layersToNotSkip?: FreezerLayers[]
	) => {
		if (isConsumable) return true;
		if (termSearch?.length) {
			return (
				!FREEZER_PARENT_LAYERS[layer].includes(currentLayer) ||
				currentLayer === "ITEMGROUP"
			);
		}
		if (!selectedLayer) return currentLayer !== "FREEZER";
		if (layersToNotSkip?.includes(selectedLayer as FreezerLayers))
			return false;
		return true;
	};

	const getSkipConsumableLayer = (
		currentLayer: ConsumableLayers,
		layersToNotSkip?: ConsumableLayers[],
		isSearchable?: boolean
	) => {
		if (!isConsumable) return true;
		if (termSearch?.length) return !isSearchable;
		if (isSearchable) return !termSearch.length;
		if (!selectedLayer) return currentLayer !== "SPACE";
		if (layersToNotSkip?.includes(selectedLayer as ConsumableLayers))
			return false;
		return true;
	};

	// FREEZER QUERIES
	const skipFreezers = getSkipFreezerLayer("FREEZER");
	const skipShelves = getSkipFreezerLayer("SHELF", ["FREEZER"]);
	const skipRacks = getSkipFreezerLayer("RACK", ["SHELF"]);
	const skipCategories = getSkipFreezerLayer("CATEGORY", ["SHELF"]);
	const skipItemGroups = getSkipFreezerLayer("ITEMGROUP", ["CATEGORY"]);
	const skipBoxes = getSkipFreezerLayer("BOX", ["RACK", "CATEGORY"]);
	const { data: freezersData, isLoading: loadingFreezers } =
		useGetRepositoryFreezersQuery(
			{
				page: 1,
				page_size: 10,
				search: termSearch?.startsWith("#") ? undefined : termSearch,
				ordering: "-updated_at",
				status__in: [ITEM_STATUS.ACTIVE],
			},
			{
				refetchOnMountOrArgChange: true,
				skip: skipFreezers,
			}
		);
	const { data: shelvesData, isLoading: loadingShelves } =
		useGetRepositoryShelvesQuery(
			{
				page: 1,
				page_size: 10,
				search: termSearch?.startsWith("#") ? undefined : termSearch,
				ordering: "-updated_at",
				status__in: [ITEM_STATUS.ACTIVE],
				freezer: getFilterSelectedItem("FREEZER"),
			},
			{
				refetchOnMountOrArgChange: true,
				skip: skipShelves,
			}
		);
	const { data: racksData, isLoading: loadingRacks } =
		useGetRepositoryRacksQuery(
			{
				page: 1,
				page_size: 10,
				search: termSearch?.startsWith("#") ? undefined : termSearch,
				ordering: "-updated_at",
				status__in: [ITEM_STATUS.ACTIVE],
				location__shelf: getFilterSelectedItem("SHELF"),
			},
			{
				refetchOnMountOrArgChange: true,
				skip: skipRacks,
			}
		);
	const { data: categoriesData, isLoading: loadingCategories } =
		useGetRepositoryCategoriesQuery(
			{
				page: 1,
				page_size: 10,
				search: termSearch?.startsWith("#") ? undefined : termSearch,
				ordering: "-updated_at",
				status__in: [ITEM_STATUS.ACTIVE],
				location__shelf: getFilterSelectedItem("SHELF"),
			},
			{
				refetchOnMountOrArgChange: true,
				skip: skipCategories,
			}
		);
	const { data: itemGroupsData, isLoading: loadingItemGroups } =
		useGetRepositoryItemGroupsQuery(
			{
				page: 1,
				page_size: 10,
				search: termSearch?.startsWith("#") ? undefined : termSearch,
				ordering: "-updated_at",
				status__in: [ITEM_STATUS.ACTIVE],
				location__category: getFilterSelectedItem("CATEGORY"),
			},
			{
				refetchOnMountOrArgChange: true,
				skip: skipItemGroups,
			}
		);
	const {
		data: boxData,
		isFetching,
		isLoading: loadingBoxes,
	} = useGetRepositoryBoxesQuery(
		{
			page: 1,
			page_size: 20,
			search: termSearch?.startsWith("#") ? undefined : termSearch,
			ordering: "-updated_at",
			status__in: [ITEM_STATUS.ACTIVE],
			location__rack_location__rack: getFilterSelectedItem("RACK"),
			location__category: getFilterSelectedItem("CATEGORY"),
		},
		{
			refetchOnMountOrArgChange: true,
			skip: skipBoxes,
		}
	);
	// CONSUMABLE QUERIES
	const { data: consumableSpacesData, isLoading: loadingConsumablesSpaces } =
		useListSpacesQuery(undefined, {
			refetchOnMountOrArgChange: true,
			skip: getSkipConsumableLayer("SPACE"),
		});
	const {
		data: consumableFurnituresData,
		isLoading: loadingConsumablesFurnitures,
	} = useFurnituresQuery(selectedItem?.item?.id || skipToken, {
		refetchOnMountOrArgChange: true,
		skip: getSkipConsumableLayer("FURNITURE", ["SPACE"]),
	});
	const {
		data: consumableCategoriesData,
		isLoading: loadingConsumablesCategories,
	} = useFurnitureCategoriesQuery(selectedItem?.item?.id || skipToken, {
		refetchOnMountOrArgChange: true,
		skip: getSkipConsumableLayer("CATEGORY", ["FURNITURE"]),
	});
	const {
		data: consumableCategoriesSearchData,
		isLoading: loadingConsumablesSearchCategories,
	} = useFurnitureCategoriesSearchQuery(
		{
			page: 1,
			page_size: 20,
			stock_name: "",
			search: termSearch,
			filter_from_repository: true,
		},
		{
			refetchOnMountOrArgChange: true,
			skip: getSkipConsumableLayer("CATEGORY", ["FURNITURE"], true),
		}
	);

	const debounceSearch = useDebounceEventHandler((ev: string) => {
		setTermSearch(ev.trim());
	}, 350);

	const renderBoxLocation = (location_data: BoxLocationData) => {
		const { freezer_name, rack_name, shelf_name, category_name } =
			location_data;
		let location = "";
		if (freezer_name) {
			location = freezer_name;
		}
		if (shelf_name) {
			location += location !== "" ? ` > ${shelf_name}` : shelf_name;
		}
		if (rack_name || category_name) {
			const rack_or_category = rack_name || category_name;
			location +=
				location !== "" ? ` > ${rack_or_category}` : rack_or_category;
		}
		return location;
	};

	const getEmptyLayer = () => {
		let message = "";
		if (!selectedItem) {
			message = `No ${isConsumable ? "Spaces" : "Freezers"} available`;
		} else {
			message = `${capitalizeFirstLetter(
				selectedItem.layer.toLowerCase()
			)} is empty`;
		}
		return (
			<div style={{ paddingTop: 8 }}>
				<Typography>{message}</Typography>
			</div>
		);
	};

	const renderFreezerLayerItems = (
		items: FreezerLayerItem[],
		loading: boolean,
		getDescription: (
			checkItem: any,
			currentLayer: FreezerLayers
		) => string | undefined,
		getShowSpacesAvailable: (
			checkItem: any,
			currentLayer: FreezerLayers
		) => boolean,
		getSpacesAvailable: (
			checkItem: any,
			currentLayer: FreezerLayers
		) => number
	) => {
		if (loading) return <Typography>Loading</Typography>;
		if (!items.length) return getEmptyLayer();
		return items?.map(({ item, layer: currentLayer }) => {
			const spacesAvailable = getSpacesAvailable(item, currentLayer);
			const isItemAvailable = spacesAvailable > 0;
			const description = getDescription(item, currentLayer);
			const showSpacesAvailable = getShowSpacesAvailable(
				item,
				currentLayer
			);
			return (
				<FreezerItem
					key={`${currentLayer}-item-${item.id}`}
					item={item}
					selectedLayer={currentLayer}
					description={description}
					isItemAvailable={isItemAvailable}
					spacesAvailable={spacesAvailable}
					showSpacesAvailable={showSpacesAvailable}
					isChoosable={
						showSpacesAvailable || currentLayer === "ITEMGROUP"
					}
					onChooseItem={onSelect}
					onSelectItem={(item) =>
						setItemsBreadcrumb((prev) => [
							...prev,
							{ item, layer: currentLayer },
						])
					}
				/>
			);
		});
	};

	const renderConsumableLayerItems = (
		items: ConsumableLayerItem[],
		loading: boolean
	) => {
		if (loading) return <Typography>Loading</Typography>;
		if (!items.length) return getEmptyLayer();
		return items?.map(({ item, layer: currentLayer }) => {
			const { space, furniture } =
				(item as ConsumableAlertScopeSearchResult).path || {};
			let description = "";
			if (space && furniture)
				description = `${space.name} > ${furniture.name}`;
			return (
				<ConsumableItem
					key={`${currentLayer}-item-${item.id}`}
					item={item}
					description={description}
					showNextLayerIcon={
						currentLayer !== "CONSUMABLE" &&
						currentLayer !== "CATEGORY"
					}
					onChooseItem={onSelectConsumable}
					onSelectItem={(item) =>
						setItemsBreadcrumb((prev) => [
							...prev,
							{ item, layer: currentLayer },
						])
					}
				/>
			);
		});
	};

	const renderResults = () => {
		if (isConsumable) {
			const consumableItems = [
				...(consumableSpacesData
					?.filter((item) => !item.is_archived)
					.map((item) => ({ item, layer: "SPACE" })) || []),
				...(consumableFurnituresData?.map((item) => ({
					item,
					layer: "FURNITURE",
				})) || []),
				...(consumableCategoriesData?.map((item) => ({
					item,
					layer: "CATEGORY",
				})) || []),
				...(consumableCategoriesSearchData?.results?.map((item) => ({
					item,
					layer: "CATEGORY",
				})) || []),
			];
			return renderConsumableLayerItems(
				consumableItems as ConsumableLayerItem[],
				loadingConsumablesSpaces ||
					loadingConsumablesFurnitures ||
					loadingConsumablesCategories ||
					loadingConsumablesSearchCategories
			);
		}
		if (!skipFreezers && freezersData) {
			return renderFreezerLayerItems(
				freezersData.results.map((item) => ({
					item,
					layer: "FREEZER",
				})) || [],
				loadingFreezers,
				() => undefined,
				() => false,
				(item: Freezer) => item.shelves - item.shelves_number
			);
		}
		if (!skipShelves && shelvesData) {
			return renderFreezerLayerItems(
				shelvesData.results.map((item) => ({ item, layer: "SHELF" })) ||
					[],
				loadingShelves,
				(item: Shelf) =>
					termSearch.length
						? item.location_data?.freezer_name
						: undefined,
				() => false,
				() => 1
			);
		}
		if (!skipRacks || !skipCategories) {
			const items = [
				...(racksData?.results.map((item) => ({
					item,
					layer: "RACK",
				})) || []),
				...(categoriesData?.results.map((item) => ({
					item,
					layer: "CATEGORY",
				})) || []),
			];
			return renderFreezerLayerItems(
				items as FreezerLayerItem[],
				loadingRacks || loadingCategories,
				(item: Rack | Category) =>
					termSearch.length
						? `${item.location_data?.freezer_name} > ${item.location_data?.shelf_name}`
						: undefined,
				(_, currentLayer: FreezerLayers) =>
					currentLayer !== "CATEGORY" && currentLayer !== "RACK",
				(item: Rack | Category, currentLayer: FreezerLayers) =>
					currentLayer === "CATEGORY"
						? 1
						: (item as Rack).availability
			);
		}
		if (!skipBoxes || !skipItemGroups) {
			const items = [
				...(boxData?.results.map((item) => ({ item, layer: "BOX" })) ||
					[]),
				...(itemGroupsData?.results.map((item) => ({
					item,
					layer: "ITEMGROUP",
				})) || []),
			];
			return renderFreezerLayerItems(
				items as FreezerLayerItem[],
				loadingBoxes ||
					(selectedLayer === "CATEGORY" && loadingItemGroups),
				(item: Box, currentLayer: FreezerLayers) =>
					termSearch.length
						? currentLayer === "BOX"
							? renderBoxLocation(item.location_data)
							: undefined
						: undefined,
				(_, currentLayer: FreezerLayers) =>
					currentLayer !== "ITEMGROUP",
				(item: Box, currentLayer: FreezerLayers) =>
					currentLayer === "ITEMGROUP"
						? 1
						: item.rows * item.columns - item.item_count
			);
		}
		return null;
	};

	const getFreezerItemPlaceHolder = (forInput: boolean, plural: boolean) => {
		if (layer === "BOX") {
			if (forInput || !plural) return "rack or category";
			return "racks or categories";
		}
		if (layer === "SHELF") {
			if (forInput || !plural) return "freezer";
			return "freezers";
		}
		if (layer === "RACK" || layer === "CATEGORY") {
			if (forInput || !plural) return "shelf";
			return "shelves";
		}
		if (forInput || !plural) return "box";
		return "boxes";
	};

	const getEmptyFreezerItemsList = () => {
		if (selectedLayer === "FREEZER") {
			return "shelves";
		}
		if (selectedLayer === "SHELF") {
			return "racks or categories";
		}
		if (selectedLayer === "RACK") {
			return "boxes";
		}
		if (selectedLayer === "CATEGORY") {
			return "boxes or item groups";
		}
		return "freezers";
	};

	const getConsumableItemPlaceHolder = (plural: boolean) => {
		if (plural) return "categories";
		return "category";
	};

	const getTotalFreezerItems = useCallback(() => {
		let total = freezersData?.results.length || 0;
		total += shelvesData?.results.length || 0;
		total += racksData?.results.length || 0;
		total += categoriesData?.results.length || 0;
		total += boxData?.results.length || 0;
		total += itemGroupsData?.results.length || 0;
		return total;
	}, [
		freezersData?.results,
		shelvesData?.results,
		racksData?.results,
		categoriesData?.results,
		boxData?.results,
		itemGroupsData?.results,
	]);

	const getTotalConsumableItems = useCallback(() => {
		let total = consumableSpacesData?.length || 0;
		total += consumableFurnituresData?.length || 0;
		total += consumableCategoriesData?.length || 0;
		total += consumableCategoriesSearchData?.results.length || 0;
		return total;
	}, [
		consumableSpacesData,
		consumableFurnituresData,
		consumableCategoriesData,
		consumableCategoriesSearchData?.results,
	]);

	const renderTopContent = useCallback(() => {
		if (termSearch.length) {
			const totalItems = isConsumable
				? getTotalConsumableItems()
				: getTotalFreezerItems();
			const placeholder = isConsumable
				? getConsumableItemPlaceHolder(totalItems !== 1)
				: getFreezerItemPlaceHolder(false, totalItems !== 1);
			return (
				<Typography
					color="text-tertiary"
					className={styles.resultsLabel}
				>
					{totalItems} {placeholder} found
				</Typography>
			);
		}
		const renderBreadcrumb = itemsBreadcrumb.map(({ item }, index) => {
			return (
				<div
					key={`breadcrumbItem-${item.id}-${index}`}
					className={styles.breadcrumbItemContainer}
					onClick={() =>
						setItemsBreadcrumb((prev) => prev.slice(0, index))
					}
				>
					<Typography color="text-secondary-v2" ellipsis>
						{item.name}
					</Typography>
					{index < itemsBreadcrumb.length && (
						<GenemodIcon
							name="chevron-right"
							color="text-secondary-v2"
						/>
					)}
				</div>
			);
		});
		let nextLayerName: string | undefined = "";
		if (isConsumable) {
			nextLayerName = !selectedLayer
				? "Spaces"
				: CONSUMABLE_CHILDREN_LAYERS[selectedLayer as ConsumableLayers];
		} else {
			nextLayerName = !selectedLayer
				? "Freezers"
				: FREEZER_CHILDREN_LAYERS[selectedLayer as FreezerLayers];
		}
		return (
			<div className={styles.breadcrumbContainer}>
				{[
					...renderBreadcrumb,
					<Typography key="breadcrumb-placeholder">
						{nextLayerName}
					</Typography>,
				]}
			</div>
		);
	}, [
		termSearch?.length,
		itemsBreadcrumb,
		selectedLayer,
		getTotalFreezerItems,
		getTotalConsumableItems,
		isConsumable,
	]);

	return (
		<div className={styles.container} data-cy="assign-location-popup">
			<div className={styles.searchBarContainer}>
				<LayerSystemContainer>
					<SearchBar
						disableSuggestions
						value={search}
						onChange={(e) => {
							setSearch(e);
							debounceSearch(e);
						}}
						placeholder={`Search ${
							isConsumable
								? "category"
								: getFreezerItemPlaceHolder(true, false)
						}`}
						iconPosition="left"
					/>
				</LayerSystemContainer>
			</div>
			<LoadingSpinner loading={isFetching} centered size="large">
				<Typography
					variant="footnote"
					color="text-ghost"
					style={{ marginTop: 8 }}
				>
					* Moving to an item group will rename the item to the item
					group&apos;s name
				</Typography>
				{renderTopContent()}
				{renderResults()}
			</LoadingSpinner>
		</div>
	);
};
