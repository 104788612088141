import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useRepository } from "../../RepositoryContext";
import { ButtonV2, Typography } from "@common/components";
import styles from "./UnarchiveContainer.module.scss";
import ProgressCircles from "@common/components/ProgressCircles/ProgressCircles";
import { useHistory } from "react-router-dom";

const Portal = ({ children }: { children: React.ReactNode }) => {
	const portalRoot = document.getElementById("portal-root");
	if (!portalRoot) return null;
	return ReactDOM.createPortal(children, portalRoot);
};

function UnarchiveContainer() {
	const history = useHistory();

	const {
		unarchivingSteps,
		currentStep,
		setCurrentStep,
		handleArchiveOrRestoreButton,
		selectedItems,
		closeUnArchiveContainer,
	} = useRepository();

	useEffect(() => {
		// Hook for navigation back
		const unlisten = history.listen((_, action) => {
			// if back button clicked while UnarchivcContainer is opened
			if (action === "POP" && unarchivingSteps) {
				closeUnArchiveContainer();
				// Prevent returning to the UnarchiveContainer parameters.
				window.history.pushState(null, "", window.location.href);
			}
		});
		// remove listen when it is unmounted
		return () => unlisten();
	}, [history, unarchivingSteps]);

	const handleRestore = () => {
		handleArchiveOrRestoreButton(false);
	};

	if (!unarchivingSteps) {
		return null;
	}

	return (
		<div className={styles.unarchiveContainer}>
			<div className="flex justify-between px-8 pb-5 align-center pt-7">
				<ButtonV2
					icon="chevron-left"
					onClick={() => closeUnArchiveContainer()}
				>
					Cancel
				</ButtonV2>
				{unarchivingSteps.length === 1 ? (
					<Typography variant="headline4">
						{unarchivingSteps[currentStep].title}
					</Typography>
				) : (
					<ProgressCircles
						steps={unarchivingSteps}
						currentStep={unarchivingSteps[currentStep]}
						onStepClick={(step) => {
							const selectedStep = unarchivingSteps.findIndex(
								(s) => s.key === step.key
							);
							setCurrentStep(selectedStep);
						}}
					/>
				)}
				{unarchivingSteps.length > currentStep + 1 ? (
					<ButtonV2
						type="primary"
						onClick={() => setCurrentStep(currentStep + 1)}
						disabled={selectedItems.length === 0}
					>
						Continue
					</ButtonV2>
				) : (
					<ButtonV2
						type="primary"
						onClick={() => handleRestore()}
						disabled={selectedItems.length === 0}
					>
						Restore
					</ButtonV2>
				)}
			</div>
			<div className={styles.unarchiveStepContainer}>
				{unarchivingSteps.length > 1 ? (
					<div className="flex justify-center">
						<Typography variant="headline4">
							{unarchivingSteps[currentStep].title}
						</Typography>
					</div>
				) : null}
				<div>{unarchivingSteps[currentStep].component}</div>
			</div>
		</div>
	);
}

export default UnarchiveContainer;
