import { Link } from "@common/helpers/Hooks/UseRouterDom";
import {
	CURRENCY_TYPES,
	ConsumableSearchItem,
	ExperimentMaterial,
	FolderMaterial,
	Item,
	UngroupedSearchResultItem,
	formatCustomId,
} from "@common/types";
import {
	Button,
	GenemodIcon,
	Input,
	LayerSystemContainer,
	LoadingSpinner,
	Modal,
	SearchBar,
	Typography,
} from "@components";
import { FREEZER_PATHS } from "@containers/Freezer";
import { truncArgs } from "@helpers/Formatters";
import {
	InfiniteScrollingConfig,
	useDebounce,
	useInfiniteScrolling,
} from "@helpers/Hooks";
import {
	IdsInConstType,
	flattenUnion,
	nameKeysOfConst,
} from "@helpers/TypeHelpers";
import {
	useConsumablesSearchQuery,
	useLazyConsumablesSearchQuery,
} from "@redux/freezer/FreezerApiSlice";
import { useLazyFreezerItemSearchQuery } from "@redux/inventory/Item";
import { useOrganizationRouter } from "@root/AppRouter";
import { PM_ROUTES } from "@root/routes";
import classNames from "classnames";
import React, {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import styles from "./index.module.scss";

type ExperimentFolderMaterial = ExperimentMaterial | FolderMaterial;

// Location breadcrumb item location data format coming from the search
interface BreadCrumbItemInterface {
	id: number;
	name: string;
}

interface LinkItemInterface {
	id: number;
	custom_id: number;
	name: string;
	source: string;
	reference: string;
	catalog: string;
	lot: string;
	packaging: string;
	price: string;
	currency: IdsInConstType<typeof CURRENCY_TYPES>;
	// Optional fields for the breadcrumb location
	// Consumables
	parent_space?: BreadCrumbItemInterface;
	parent_furniture?: BreadCrumbItemInterface;
	parent_furniture_category?: BreadCrumbItemInterface;
	// Freezer
	parent_freezer_name?: string;
	parent_freezer?: number;
	parent_shelf_name?: string;
	parent_rack_name?: string;
	parent_box_name?: string;
	parent_box?: number;
	organization_prefix?: string;
}

/**
 *
 * @param item the consumable search item
 * @returns the link to that consumable item written in "space_name > furniture_name > furniture_category_name"
 */
const formatItemLink = (
	item: LinkItemInterface,
	type: "consumables" | "freezer"
): string => {
	if (type === "consumables") {
		return truncArgs`${item?.parent_space?.name} > ${item?.parent_furniture?.name} > ${item?.parent_furniture_category?.name}`(
			20
		);
	} else {
		return truncArgs`${item?.parent_freezer_name} > ${item?.parent_shelf_name} > ${item?.parent_rack_name} > ${item?.parent_box_name}`(
			20
		);
	}
};

/**
 *
 * A modal for linking an experiment material to a consumable item
 */
export function ConsumableLinkModal(): JSX.Element {
	const page_size = 25;
	const { linkModalState, setLinkModalState } = useContext(
		LinkModalStateContext
	);
	const { type: linkModalType, materialItem } = flattenUnion(linkModalState);
	const isModalVisible = useMemo(() => {
		return linkModalType === "consumables";
	}, [linkModalType]);

	const [searchBarTerm, setSearchTerm] = useState(
		linkModalState?.defaultSearch || ""
	);
	const debouncedSearch = useDebounce(searchBarTerm, 250);

	const { data: defaultResults } = useConsumablesSearchQuery({
		page_size: "25",
		page: "1",
		search: "",
	});
	const [fetchConsumables, { isFetching, isLoading }] =
		useLazyConsumablesSearchQuery();
	const [data, setData] = useState<ConsumableSearchItem[]>([]);

	useEffect(() => {
		if (isModalVisible) {
			// Reset searchTerm when modal is opened
			const search = linkModalState?.defaultSearch || "";
			setSearchTerm(search);
		}
	}, [isModalVisible]);

	useEffect(() => {
		if (!isModalVisible) return;
		// Reset the search when searchBarTerm changes
		if (!debouncedSearch.trim()) {
			setData(defaultResults?.results || []);
			return;
		}
		fetchConsumables({
			search: searchBarTerm,
			page: Number(1).toString(),
			page_size: page_size.toString(),
		}).then((res) => {
			setData(res?.data?.results || []);
		});
	}, [debouncedSearch, isModalVisible]);

	return (
		<Modal
			title="Link item in Consumables"
			visible={isModalVisible}
			footer={<></>}
			onCancel={() => setLinkModalState(null)}
			width={600}
		>
			<div className={styles.container}>
				<SearchBar
					value={searchBarTerm}
					onChange={setSearchTerm}
					disableSuggestions
					placeholder="Find items in Consumables by keyword"
				/>
				<Typography color="text-tertiary">
					{!data.length
						? "No results found in Consumables"
						: "Select one of the following to attach ID"}
				</Typography>
				<div className={styles.cards}>
					{isLoading || isFetching ? (
						<LoadingSpinner
							centered
							loading={isFetching || isLoading}
							style={{ marginTop: "24px" }}
							size="large"
						/>
					) : (
						<>
							{data.map((item, index) => {
								return (
									<LinkItemRow
										key={index}
										materialItem={
											materialItem as ExperimentFolderMaterial
										}
										linkItem={item}
										linkTitle={formatItemLink(
											item,
											"consumables"
										)}
									/>
								);
							})}
						</>
					)}
				</div>
			</div>
		</Modal>
	);
}

export function FreezerLinkModal(): JSX.Element {
	const page_size = 15;
	const { linkModalState, setLinkModalState } = useContext(
		LinkModalStateContext
	);
	const { type: linkModalType, materialItem } = flattenUnion(linkModalState);
	const isModalVisible = useMemo(() => {
		return linkModalType === "freezer";
	}, [linkModalType]);

	const [searchBarTerm, setSearchTerm] = useState("");
	const [isSearchLoading, setIsSearchLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const debouncedSearch = useDebounce(searchBarTerm, 250);

	const [searchItems, { isLoading }] = useLazyFreezerItemSearchQuery();
	const [data, setData] = useState<UngroupedSearchResultItem[]>([]);
	const loaderRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isModalVisible) {
			// Reset searchTerm when modal is opened
			const search = linkModalState?.defaultSearch || "";
			setSearchTerm(search);
		}
	}, [isModalVisible]);

	useEffect(() => {
		if (!isModalVisible) return;
		// Reset the search when searchBarTerm changes
		setIsSearchLoading(true);
		searchItems({
			search: searchBarTerm,
			is_grouped: false,
			is_shared: true,
			page: 1,
			page_size,
			extra_fields: true,
		})
			.then((res) => {
				setData(
					(res?.data?.results ||
						[]) as unknown as UngroupedSearchResultItem[]
				);
			})
			.finally(() => {
				setIsSearchLoading(false);
			});
	}, [debouncedSearch, isModalVisible]);

	const handleScrollingFetch: InfiniteScrollingConfig["callback"] =
		React.useCallback(
			async ({ page, setPage, setEnabled, setLoading }) => {
				setIsFetching(true);
				searchItems({
					search: searchBarTerm,
					page_size,
					page,
					is_grouped: false,
					is_shared: true,
					extra_fields: true,
				})
					.then((response) => {
						if (response.isSuccess) {
							const { data: resData } = response;
							setData((prev) => [
								...prev,
								...(resData.results as UngroupedSearchResultItem[]),
							]);

							// Stop fetching if no more results
							const {
								total_page_count,
								total_count: total_items_matched,
							} = resData;
							if (
								(total_page_count &&
									page >= total_page_count) ||
								page_size * page >= total_items_matched
							) {
								setEnabled(false);
							} else {
								setPage((p) => p + 1);
							}
						}
					})
					.finally(() => {
						setLoading(false);
						setIsFetching(false);
					});
			},
			[searchItems, page_size, setData]
		);

	useInfiniteScrolling({
		target: loaderRef,
		callback: handleScrollingFetch,
		initOptions: {
			firstFetch: true,
			pageSize: page_size,
			additionalDependencies: [loaderRef],
		},
	});

	return (
		<Modal
			title="Link item in Freezer"
			visible={isModalVisible}
			footer={<></>}
			onCancel={() => setLinkModalState(null)}
			width={600}
		>
			<div className={styles.container}>
				<SearchBar
					value={searchBarTerm}
					onChange={setSearchTerm}
					disableSuggestions
					placeholder="Find items in Freezer by keyword"
				/>
				<Typography color="text-tertiary">
					{!data.length
						? "No results found in Freezer"
						: "Select one of the following to attach ID"}
				</Typography>
				<div className={styles.cards}>
					{isLoading || isSearchLoading ? (
						<LoadingSpinner
							centered
							loading
							style={{ marginTop: "24px" }}
						/>
					) : (
						<>
							{data.map((item, index) => {
								const castedItem = item as LinkItemInterface;
								return (
									<LinkItemRow
										key={index}
										materialItem={
											materialItem as ExperimentFolderMaterial
										}
										linkItem={castedItem}
										linkTitle={formatItemLink(
											castedItem,
											"freezer"
										)}
									/>
								);
							})}
						</>
					)}
					<LoadingSpinner
						centered
						loading={isFetching}
						style={{ marginTop: "24px" }}
					/>
					<div ref={loaderRef} key="loader" style={{ height: 1 }} />
				</div>
			</div>
		</Modal>
	);
}

type LinkItemRowProps = {
	materialItem: ExperimentFolderMaterial;
	linkItem: LinkItemInterface;
	linkTitle: string;
};

/**
 *
 * The row of each Consumable item inside the ConsumableLinkModal. Has form field display to look at the consumable item field
 */
function LinkItemRow({
	materialItem,
	linkItem,
	linkTitle,
}: LinkItemRowProps): JSX.Element {
	const [expanded, setExpanded] = useState<boolean>(false);

	const { linkModalState, setLinkModalState } = useContext(
		LinkModalStateContext
	);
	const { materialCallback } = flattenUnion(linkModalState);

	/**
	 *
	 * Handles the behavior when clisking the "Select" button to link an experiment material to a consumable item
	 */
	const handleSelect = async () => {
		if (!materialItem || !materialCallback || !linkModalState) return;

		const updateMaterial: ExperimentFolderMaterial = {
			...materialItem,
		};

		// If a type exists in the experiment material, we update the name of the updateMaterial with the name from the linkItem
		if (linkModalState.type === "consumables") {
			updateMaterial["consumable_item"] = {
				...linkItem,
			} as ConsumableSearchItem;
			updateMaterial.name = linkItem.name;
		} else {
			updateMaterial["freezer_item"] = {
				...linkItem,
			} as Item;
			updateMaterial.name = linkItem.name;
		}

		materialCallback(updateMaterial as unknown as ExperimentFolderMaterial);
		setLinkModalState(null);
	};

	return (
		<div className={styles.linkRowContainer}>
			<div
				className={classNames(styles.linkRow, {
					[styles.linkRow__expanded]: expanded,
				})}
			>
				<div className={styles.nameAndLink}>
					<Typography ellipsis className={styles.nameOrLink}>{`[ID: ${
						linkItem.organization_prefix
							? formatCustomId(
									linkItem.organization_prefix,
									linkItem.custom_id
							  )
							: linkItem.custom_id
					}] ${linkItem.name}`}</Typography>
					<Typography
						className={styles.nameOrLink}
						variant="label"
						color="text-tertiary"
						ellipsis
						hideTooltip
					>
						{linkTitle}
					</Typography>
				</div>
				<div className={styles.selectAndCollapsible}>
					<Button
						className={styles.selectButton}
						size="small"
						type="ghost"
						shape="squared"
						onClick={handleSelect}
					>
						Select
					</Button>
					<div className={styles.icon}>
						<GenemodIcon
							size="large"
							name={expanded ? "chevron-left" : "chevron-right"}
							onClick={() => setExpanded(!expanded)}
						/>
					</div>
				</div>
			</div>
			{expanded && (
				<ItemFormFields linkItem={linkItem} onSelect={handleSelect} />
			)}
		</div>
	);
}

type ItemFormFieldsProps = {
	linkItem: LinkItemInterface;
	onSelect: () => void;
	loading?: boolean;
};

/**
 *
 * A form to display the other fields of the Consumable item. The inputs are not editable. For display only
 */
function ItemFormFields({
	linkItem,
	onSelect,
	loading,
}: ItemFormFieldsProps): JSX.Element {
	const { source, reference, catalog, lot, packaging, price, currency } =
		linkItem;

	const inputWrapperProps = {
		className: styles.input,
	};

	const { appendBaseUrl } = useOrganizationRouter();
	const { linkModalState } = useContext(LinkModalStateContext);
	const { type } = flattenUnion(linkModalState);
	const getLinkItemUrl = () => {
		let pathname = PM_ROUTES.HOME;
		let newParams = new URLSearchParams();

		switch (type) {
			case "consumables":
				pathname = FREEZER_PATHS.FURNITURE_CATEGORIES.replace(
					":space_id",
					(linkItem?.parent_space?.id || -1).toString()
				)
					.replace(
						":furniture_id",
						(linkItem?.parent_furniture?.id || -1).toString()
					)
					.replace(
						":furniture_category_id",
						(
							linkItem?.parent_furniture_category?.id || -1
						).toString()
					);
				newParams = new URLSearchParams({
					consumable_id: linkItem.id + "",
				});
				return {
					pathname: appendBaseUrl(pathname),
					search: `?${newParams.toString()}`,
				};
			case "freezer":
				pathname = FREEZER_PATHS.BOXES.replace(
					":freezer_id",
					(linkItem?.parent_freezer || -1).toString()
				).replace(":box_id", (linkItem?.parent_box || -1).toString());
				newParams = new URLSearchParams({
					search_ids: linkItem.id + "",
				});
				return {
					pathname: appendBaseUrl(pathname),
					search: `?${newParams.toString()}`,
				};
			default:
				// code block
				return {
					pathname: appendBaseUrl(pathname),
					search: `?${newParams.toString()}`,
				};
		}
	};

	return (
		<LayerSystemContainer
			overrideLayer={"background"}
			className={styles.formFields}
		>
			<Input
				wrapperProps={inputWrapperProps}
				label="Source"
				labelPosition="top"
				value={source}
				disabled
			/>
			<Input
				wrapperProps={inputWrapperProps}
				label="Reference"
				labelPosition="top"
				value={reference}
				disabled
			/>
			<div className={styles.row}>
				<Input
					wrapperProps={inputWrapperProps}
					label="Catalog #"
					labelPosition="top"
					value={catalog}
					disabled
				/>
				<Input
					wrapperProps={inputWrapperProps}
					label="lot #"
					labelPosition="top"
					value={lot}
					disabled
				/>
			</div>
			<Input
				wrapperProps={inputWrapperProps}
				label="Packaging"
				labelPosition="top"
				value={packaging}
				disabled
			/>
			<div className={styles.row}>
				<Input
					wrapperProps={inputWrapperProps}
					label="Price"
					labelPosition="top"
					prefix={
						CURRENCY_TYPES[
							currency as IdsInConstType<typeof CURRENCY_TYPES>
						]
					}
					value={price}
					disabled
				/>
				<Input
					wrapperProps={inputWrapperProps}
					label="Currency"
					labelPosition="top"
					prefix={
						CURRENCY_TYPES[
							currency as IdsInConstType<typeof CURRENCY_TYPES>
						]
					}
					value={nameKeysOfConst(CURRENCY_TYPES)[currency]}
					disabled
				/>
			</div>
			<div className={styles.footer}>
				<Link to={getLinkItemUrl()} target="_blank">
					<Button type="link" size="small">
						View in new tab
					</Button>
				</Link>
				<Button size="small" onClick={onSelect} loading={loading}>
					Select
				</Button>
			</div>
		</LayerSystemContainer>
	);
}

type LinkModalStateProps = {
	type: "consumables" | "freezer";
	materialItem: ExperimentFolderMaterial;
	materialCallback?: (material: ExperimentFolderMaterial) => void;
	defaultSearch?: string;
};

type LinkModalStateContextProps = {
	linkModalState: LinkModalStateProps | null;
	setLinkModalState: (state: LinkModalStateProps | null) => void;
};

export const LinkModalStateContext = createContext<LinkModalStateContextProps>({
	linkModalState: null,
	setLinkModalState: () => {},
});

export const LinkModalStateContextProvider = (props: any) => {
	const [linkModalState, setLinkModalState] =
		useState<LinkModalStateProps | null>(null);

	return (
		<LinkModalStateContext.Provider
			value={{ linkModalState, setLinkModalState }}
		>
			{props.children}
		</LinkModalStateContext.Provider>
	);
};
