import { MessageWithLink } from "@common/components/Notification/Notification";
import { Box, Item } from "@common/types";
import { Notification } from "@components";
import { fillInUrlParams } from "@helpers/TypeHelpers";
import {
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import { useOrganizationRouter } from "@root/AppRouter";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MovingCart from "..";
import { FREEZER_PATHS } from "../../../../Freezer";
import { areaBoundsIncludesCellId, getGridCoord } from "../../../data";
import { useBoxView } from "../../../table/BoxTableHooks";
import BoxList from "./BoxList";
import ItemPlacement from "./ItemPlacement";

export default function ItemMovingCart(): JSX.Element {
	const history = useHistory();
	const { appendBaseUrl } = useOrganizationRouter();
	const { selectedAreaBounds } = useBoxTableFields("selectedAreaBounds");

	const { box, items: itemsData } = useBoxView();
	const { setMovingItems } = useBoxTableActions();

	const items = itemsData.filter(
		(item) =>
			!!selectedAreaBounds.find((bounds) =>
				areaBoundsIncludesCellId(bounds, getGridCoord(item))
			)
	);

	const [newBoxId, setNewBoxId] = useState<number | null>(box?.id || 1);

	const handleMovingCart = (
		rawItems: Item[],
		movedBox: Box,
		newCellId?: string
	) => {
		setMovingItems(false);

		let message: React.ReactNode;

		if (box && box.id !== movedBox.id) {
			message =
				rawItems.length === 1
					? `1 item moved to "${movedBox.name}"`
					: `${rawItems.length} items moved to "${movedBox.name}"`;

			const pathname = appendBaseUrl(
				fillInUrlParams(FREEZER_PATHS.BOXES)({
					box_id: movedBox.id,
					freezer_id: movedBox.location.freezer,
				})
			);

			if (rawItems.length === 1) {
				message = (
					<MessageWithLink
						message={message as string}
						onClick={() =>
							history.push({
								pathname,
								search: `item_id=${rawItems[0].id}`,
							})
						}
						linkText="View item"
					/>
				);
			} else {
				message = (
					<MessageWithLink
						message={message as string}
						onClick={() => history.push(pathname)}
						linkText="View box"
					/>
				);
			}
		} else {
			if (newCellId) {
				message =
					rawItems.length === 1
						? `1 item moved to cell "${newCellId}"`
						: `${rawItems.length} items moved to multiple cells.`;
			} else {
				message =
					rawItems.length === 1
						? `1 item moved within this box.`
						: `${rawItems.length} items moved within this box.`;
			}
		}

		Notification.success({ message });
	};

	return (
		<MovingCart
			title={`Move ${items?.length} item${
				items.length > 1 ? "s" : ""
			} to`}
			visible={!!box}
			onClose={() => setMovingItems(false)}
		>
			{newBoxId && items.length ? (
				<ItemPlacement
					boxId={newBoxId}
					clearBox={() => setNewBoxId(null)}
					items={items}
					handleMovingCart={handleMovingCart}
				/>
			) : (
				<BoxList setBoxId={setNewBoxId} />
			)}
		</MovingCart>
	);
}
