import React, { useState } from "react";
import moment, { Moment } from "moment";
import "./ViewEditFormItems.scss";
import styles from "./ViewEditFormItems.module.scss";
import {
	Input,
	CustomDatePicker,
	UserAvatar,
	Typography,
	ClickToEdit,
	Tooltip,
	GenemodAnchorme,
	Select,
} from "@components";
import {
	GenusSelect,
	SpeciesSelect,
	AntibioticSelect,
	AntibioticSelectProps,
	StrainFieldType,
	SpeciesSelectProps,
} from "../../../table/components/ReagentSpecificFields/ReagentSpecificFields";
import { InputValidator, MAX_INTEGER } from "@common/components/Input/Input";
import { Avatar, getFullNameFromAvatar, Freezer } from "@common/types";
import {
	DatePickerClickToEdit,
	DatePickerClickToEditProps,
	PriceClickToEdit,
	PriceClickToEditProps,
	Props as ClickToEditInputProps,
	QuantityClickToEdit,
	FreezerTypeClickToEditProps,
	FreezerTypeClickToEdit,
	FreezerSharingClickToEdit,
	FreezerSharingClickToEditProps,
	FreezerItemTypeClickToEditProps,
	FreezerItemTypeClickToEdit,
} from "@common/components/ClickToEdit";
import ClickToEditStyles from "@components/ClickToEdit/ClickToEdit.module.scss";
import { DatePickerProps } from "antdv5";
import { Dayjs } from "dayjs";

type FormItemProps = {
	label: string;
	required?: boolean;
	children: any;
	multirow?: boolean;
	bold?: boolean;
	medium?: boolean;
	dataCy?: string;
};
/**
 * Base form item, label and body
 * Provide multirow = true to spread given children into their own styled rows
 */
const FormItem = ({
	label,
	required = false,
	children,
	multirow = false,
	bold = false,
	medium = true,
	dataCy,
}: FormItemProps) => {
	let content;
	if (multirow) {
		const childrenArray = Array.isArray(children) ? children : [children];
		content = (childrenArray || []).map((child, index) => (
			<div key={index} className="form-item-content-row">
				{child}
			</div>
		));
	} else {
		content = <div className="form-item-content-row">{children}</div>;
	}
	return (
		<div className="view-edit-form-item">
			<div className="form-item-label">
				<Typography
					color="text-primary-v2"
					variant="body"
					medium={medium}
					bold={bold}
				>
					{label}
					{!!required && "*"}
				</Typography>
			</div>
			<div className="form-item-content">
				<Typography
					color="text-secondary-v2"
					variant="body2"
					bold
					data-cy={dataCy}
				>
					{content}
				</Typography>
			</div>
		</div>
	);
};

type DateFormItemProps = {
	label: string;
	value?: string | null;
	disabledDate?: any; // todo
	onChange?: (value: Moment | null) => void;
	required?: boolean;
	isEditable?: boolean;
	isEditing?: boolean;
	validators?: InputValidator[];
	extraValidityDependencies?: [];
	onValidityChange?: (isValid: boolean) => void;
};

/**
 * Wraps date form and provides a display mode for use in CollapsibleViewEditCards
 */
export const DateFormItem = ({
	label,
	value,
	disabledDate,
	onChange,
	required,
	isEditable = true,
	isEditing,
	validators = [],
	extraValidityDependencies,
	onValidityChange,
}: DateFormItemProps) => {
	const editMode = isEditable && isEditing;
	const displayValue = value ? moment(value).format("MMMM D, yyyy") : "-";
	if (required) {
		validators.push({
			validator: (value: any) => !!value,
			error: "Required",
		});
	}
	return (
		<FormItem label={label} required={required}>
			{editMode && (
				<CustomDatePicker
					allowClear
					disabledDate={disabledDate}
					onChange={(date) => onChange && onChange(date)}
					value={value as any}
					validators={validators}
					extraValidityDependencies={extraValidityDependencies}
					onValidityChange={onValidityChange}
				/>
			)}
			{!editMode && <div className="value-display">{displayValue}</div>}
		</FormItem>
	);
};

type UserIconAndNameProps = {
	user?: Avatar | null;
	count?: number;
};

/**
 * Wraps user avatar and adds a the username to the right of the avatar, meant to be used in UserListFormItem but can probably be used elsewhere
 * @param {Avatar} user -the user Object or UserAvatar(object) to show userIcon and name
 * @param {Number} [count = 0] -The number of items created by the user
 */
const UserIconAndName = ({ user, count = 0 }: UserIconAndNameProps) => {
	const fullName = user ? getFullNameFromAvatar(user) : "Unknown";
	return (
		<div className="user-icon-and-name">
			<UserAvatar
				user={user}
				avatarStyle={{
					marginRight: "8px",
				}}
				size={18}
			/>
			<div className="username">
				{fullName} {count > 0 ? `(${count})` : null}
			</div>
		</div>
	);
};

type UserFormItemProps = {
	label: string;
	value?: Avatar | null;
	isEditable?: boolean;
};

/**
 * Wraps UserIconAndName and provides a display mode for use in CollapsibleViewEditCards
 */
export const UserFormItem = ({
	label,
	value, // the name of user
}: UserFormItemProps) => {
	return (
		<FormItem label={label} multirow={false}>
			<UserIconAndName
				key={label + (value?.first_name || "unknown")}
				user={value}
			/>
		</FormItem>
	);
};

type InputFormItemProps = {
	label: string;
	value: string | number | undefined;
	id?: string;
	onChange?: (value: string | undefined) => any;
	required?: boolean;
	detectLinks?: boolean;
	suffix?: string;
	placeholder?: string;
	isEditable?: boolean;
	isEditing?: boolean;
	validators?: InputValidator[];
	emptyIsInvalid?: boolean;
	maxLength?: number;
	onValidityChange?: (isValid: boolean) => any;
	type?: "number";
	useNumberArrows?: boolean;
	minNumber?: number;
	maxNumber?: number;
	setEmptyStringToUndefined?: boolean;
	autoFocus?: boolean;
	tooltip?: string;
};

/**
 * Wraps our common input form element and provides a display mode for use in CollapsibleViewEditCards
 */
export const InputFormItem = ({
	label,
	value,
	id,
	onChange,
	required,
	detectLinks = false,
	suffix,
	placeholder,
	isEditable = true,
	isEditing = false,
	validators = [],
	emptyIsInvalid = false,
	maxLength = 200,
	onValidityChange,
	type,
	useNumberArrows = false,
	minNumber,
	maxNumber,
	setEmptyStringToUndefined = true,
	autoFocus,
	tooltip,
}: InputFormItemProps) => {
	const editMode = isEditable && isEditing;
	const displayValue =
		value === null || typeof value === "undefined" || value === ""
			? "-"
			: value;
	const internalValidators = [...validators];

	if (emptyIsInvalid) {
		internalValidators.push({
			validator: (value: any) => value.length !== 0,
			error: "Required",
		});
	}

	if (type === "number") {
		internalValidators.push({
			validator: (value: string) => +value <= MAX_INTEGER,
			error: "Number is too large",
		});
	}

	let display = <div className="value-display">{displayValue}</div>;

	if (tooltip) {
		display = (
			<Tooltip trigger="click" title={tooltip} placement="right">
				{display}
			</Tooltip>
		);
	}

	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
			medium
		>
			{editMode && (
				<Input
					inputProps={{ className: "form-input" }}
					id={id}
					onChange={(ev) => {
						if (!onChange) return;
						let value: string | undefined = ev.target.value;
						if (setEmptyStringToUndefined && value === "") {
							value = undefined;
						}
						onChange(value);
					}}
					value={value}
					suffix={suffix}
					type={type}
					validators={internalValidators}
					placeholder={placeholder}
					onValidityChange={onValidityChange}
					maxLength={maxLength}
					useNumberArrows={useNumberArrows}
					minNumber={minNumber}
					maxNumber={maxNumber}
					autoFocus={autoFocus}
					dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
				/>
			)}
			{!editMode && !detectLinks && <>{display}</>}
			{!editMode && detectLinks && (
				<GenemodAnchorme target="_blank" rel="noopener">
					{displayValue + ""}
				</GenemodAnchorme>
			)}
		</FormItem>
	);
};

type InputClickToEditFormItemProps = {
	label: string;
	required?: boolean;
	validators?: InputValidator[];
	onValidityChange?: (arg0: boolean) => void;
	detectLinks?: boolean;
	customDisplay?: string;
	type?: string;
} & Omit<ClickToEditInputProps, "component">;

export function InputClickToEditFormItem({
	value,
	label,
	required,
	onComplete,
	validators,
	onValidityChange,
	detectLinks,
	readOnly,
	customDisplay,
	type,
}: InputClickToEditFormItemProps): JSX.Element {
	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<ClickToEdit
				value={value}
				customDisplay={customDisplay}
				onComplete={onComplete}
				component="input"
				placeholder="-"
				className={styles.inputClickToEdit}
				validators={validators}
				onValidityChange={onValidityChange}
				detectLinks={detectLinks}
				readOnly={readOnly}
				type={type}
				stretchInput
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type TextAreaClickToEditFormItemProps = {
	label: string;
	required?: boolean;
	validators?: InputValidator[];
	onValidityChange?: (arg0: boolean) => void;
} & Omit<ClickToEditInputProps, "component">;

export function TextAreaClickToEditFormItem({
	value,
	label,
	required,
	onComplete,
	validators,
	onValidityChange,
	readOnly,
}: TextAreaClickToEditFormItemProps): JSX.Element {
	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<ClickToEdit
				value={value}
				onComplete={onComplete}
				component="textarea"
				placeholder="-"
				className={styles.inputClickToEdit}
				validators={validators}
				onValidityChange={onValidityChange}
				enterToSave={false}
				readOnly={readOnly}
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type DatePickerClickToEditFormItem = {
	label: string;
	required?: boolean;
	onComplete: (e: Dayjs | null | undefined) => void;
} & DatePickerProps & { dataCy?: string };

export function DatePickerClickToEditFormItem({
	label,
	required,
	...props
}: DatePickerClickToEditFormItem): JSX.Element {
	const [showRequired, setShowRequired] = useState(false);

	return (
		<FormItem
			label={label}
			required={showRequired}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<DatePickerClickToEdit
				{...props}
				autoClose
				edit={showRequired}
				setEdit={setShowRequired}
				className={styles.inputClickToEdit}
				placeholder="-"
				allowClear
				required={required}
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type FreezerSharingToEditFormItemProps = {
	label: string;
	required?: boolean;
	freezer: Freezer;
} & FreezerSharingClickToEditProps;

export function FreezerSharingToEditFormItem({
	value,
	label,
	required,
	onComplete,
	onValidityChange,
	enterToSave,
	freezer,
	...props
}: FreezerSharingToEditFormItemProps): JSX.Element {
	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<FreezerSharingClickToEdit
				freezer={freezer}
				value={value}
				onComplete={onComplete}
				placeholder="-"
				wrapperProps={{
					className: styles.inputClickToEdit,
				}}
				onValidityChange={onValidityChange}
				enterToSave={enterToSave}
				{...props}
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type FreezerTypeToEditFormItemProps = {
	label: string;
	required?: boolean;
} & FreezerTypeClickToEditProps;

export function FreezerTypeToEditFormItem({
	value,
	label,
	required,
	onComplete,
	onValidityChange,
	enterToSave,
	...props
}: FreezerTypeToEditFormItemProps): JSX.Element {
	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<FreezerTypeClickToEdit
				value={value}
				onComplete={onComplete}
				placeholder="-"
				wrapperProps={{
					className: styles.inputClickToEdit,
				}}
				onValidityChange={onValidityChange}
				enterToSave={enterToSave}
				{...props}
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type FreezerItemTypeToEditFormItemProps = {
	label: string;
	required?: boolean;
} & FreezerItemTypeClickToEditProps;

export function FreezerItemTypeToEditFormItem({
	value,
	label,
	required,
	onComplete,
	onValidityChange,
	enterToSave,
	...props
}: FreezerItemTypeToEditFormItemProps): JSX.Element {
	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<FreezerItemTypeClickToEdit
				value={value}
				onComplete={onComplete}
				placeholder="-"
				wrapperProps={{
					className: styles.inputClickToEdit,
				}}
				onValidityChange={onValidityChange}
				enterToSave={enterToSave}
				{...props}
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type PriceClickToEditFormItemProps = {
	label: string;
	required?: boolean;
} & PriceClickToEditProps;

export function PriceClickToEditFormItem({
	value,
	currencyValue,
	label,
	required,
	onComplete,
	onValidityChange,
	enterToSave,
	...props
}: PriceClickToEditFormItemProps): JSX.Element {
	return (
		<FormItem
			label={label}
			required={required}
			dataCy={`form-item-${label.replace(/\s/g, "-")}`}
		>
			<PriceClickToEdit
				value={value}
				currencyValue={currencyValue}
				onComplete={onComplete}
				placeholder="-"
				wrapperProps={{
					className: styles.inputClickToEdit,
				}}
				onValidityChange={onValidityChange}
				enterToSave={enterToSave}
				{...props}
				dataCy={`form-item-edit-${label.replace(/\s/g, "-")}`}
			/>
		</FormItem>
	);
}

type CommonStainSelectClickToEditFormItemProps = {
	edit?: boolean;
	enterToSave?: boolean;
	label: string;
	required?: boolean;
	onComplete: (e: any) => void;
	readOnly?: boolean;
	className?: string;
};

type AntibioticSelectClickToEditFormItemProps =
	CommonStainSelectClickToEditFormItemProps &
		Omit<AntibioticSelectProps, "onChange">;

export function AntibioticSelectClickToEditFormItem({
	value,
	edit,
	enterToSave,
	label,
	required,
	onComplete,
	readOnly,
	className,
	...props
}: AntibioticSelectClickToEditFormItemProps): JSX.Element {
	const [_value, setValue] = useState(value);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		const valuesChanged = _value.toString() !== value.toString();

		if (valuesChanged) onComplete(_value);
		setEditable(false);
	};

	const handleTextAreaKeyEvent = (event: any) => {
		if (event.key === "Enter" && !event.shiftKey && enterToSave) {
			event.preventDefault();
			handleComplete();
		}
	};

	const handleTextAreaLostFocus = () => {
		handleComplete();
	};

	return (
		<FormItem label={label} required={required}>
			<Typography
				color={!value.length ? "text-tertiary" : undefined}
				style={{ position: "relative", width: "100%" }}
				{...props}
			>
				{!editable ? (
					<div
						className={`${ClickToEditStyles.label} ${
							!readOnly && ClickToEditStyles.hover
						} ${className}`}
						onClick={!readOnly ? handleEditableTextClick : () => {}}
					>
						{value.length
							? value.join(", ")
							: `${props.placeholder || "-"}`}
					</div>
				) : (
					<div className={styles.inputClickToEdit}>
						<AntibioticSelect
							{...props}
							value={_value}
							onChange={(e) => {
								setValue(e.target.value);
							}}
							placeholder="-"
							onBlur={handleTextAreaLostFocus}
							onKeyDown={handleTextAreaKeyEvent}
							autoFocus
						/>
					</div>
				)}
			</Typography>
		</FormItem>
	);
}

type GenusSelectClickToEditFormItemProps =
	CommonStainSelectClickToEditFormItemProps &
		Omit<StrainFieldType, "onChange">;

export function GenusSelectClickToEditFormItem({
	edit,
	label,
	required,
	onComplete,
	readOnly,
	className,
	value,
	...props
}: GenusSelectClickToEditFormItemProps): JSX.Element {
	const [_value, setValue] = useState(value);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		const valueChanged = _value !== value;
		if (valueChanged) {
			onComplete(_value || "");
		}
		setEditable(false);
	};

	const handleTextAreaLostFocus = () => {
		handleComplete();
	};

	return (
		<FormItem label={label} required={required}>
			<Typography
				color={!value ? "text-tertiary" : undefined}
				style={{ position: "relative", width: "100%" }}
				{...props}
			>
				{!editable ? (
					<div
						className={`${ClickToEditStyles.label} ${
							!readOnly && ClickToEditStyles.hover
						} ${className}`}
						onClick={!readOnly ? handleEditableTextClick : () => {}}
					>
						{value ? value : `${props.placeholder || "-"}`}
					</div>
				) : (
					<div className={styles.inputClickToEdit}>
						<GenusSelect
							value={_value}
							onChange={(e) => setValue(e.target.value)}
							placeholder="-"
							{...props}
							onBlur={handleTextAreaLostFocus}
							autoFocus
						/>
					</div>
				)}
			</Typography>
		</FormItem>
	);
}

type SpeciesSelectClickToEditFormItemProps =
	CommonStainSelectClickToEditFormItemProps &
		Omit<SpeciesSelectProps, "onChange">;

export function SpeciesSelectClickToEditFormItem({
	value,
	edit,
	label,
	required,
	onComplete,
	readOnly,
	className,
	genus,
	...props
}: SpeciesSelectClickToEditFormItemProps): JSX.Element {
	const [_value, setValue] = useState(value);
	const [_editable, _setEditable] = useState<boolean>(edit || false);
	const editable = edit ?? _editable;

	const handleEditableTextClick = () => {
		setEditable(true);
	};

	const setEditable = (edit: boolean) => {
		_setEditable(edit);
	};

	const handleComplete = () => {
		const valueChanged = _value !== value;
		if (valueChanged) onComplete(_value || "");
		setEditable(false);
	};

	const handleTextAreaLostFocus = () => {
		handleComplete();
	};

	return (
		<FormItem label={label} required={required}>
			<Typography
				color={!value ? "text-tertiary" : undefined}
				style={{ position: "relative", width: "100%" }}
				{...props}
			>
				{!editable ? (
					<div
						className={`${ClickToEditStyles.label} ${
							!readOnly && ClickToEditStyles.hover
						} ${className}`}
						onClick={!readOnly ? handleEditableTextClick : () => {}}
					>
						{value ? value : `${props.placeholder || "-"}`}
					</div>
				) : (
					<div className={styles.inputClickToEdit}>
						<SpeciesSelect
							value={_value}
							onChange={(e) => setValue(e.target.value)}
							placeholder="-"
							genus={genus}
							{...props}
							onBlur={handleTextAreaLostFocus}
							autoFocus
						/>
					</div>
				)}
			</Typography>
		</FormItem>
	);
}

type QuantityClickToEditFormItemProps = {
	value: number;
	unitValue: string;
	onComplete?: (quantity: number, unit: string) => void;
	readOnly?: boolean;
};

export function QuantityClickToEditFormItem({
	value,
	unitValue,
	onComplete,
	readOnly,
}: QuantityClickToEditFormItemProps): JSX.Element {
	return (
		<FormItem key="quantity" label="Quantity">
			<QuantityClickToEdit
				value={value}
				unitValue={unitValue}
				onComplete={onComplete}
				readOnly={readOnly}
				typographyStyle={{ width: "100%" }}
			/>
		</FormItem>
	);
}
