import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import MovingCart, {
	MovingCartContext,
	useCartPlacement,
	UseCartPlacementAPI,
} from "../";
import {
	Typography,
	GenemodIcon,
	Tooltip,
	PreviewScaleWrapper,
	Notification,
	ButtonV2,
} from "@components";
import {
	Box,
	Category,
	ItemContainerLocation,
	Rack,
	RackChildLocation,
	isRack,
} from "@common/types";
import cn from "classnames";
import UnsavedChangesModal from "../Modals";
import { MAX_COLS_RACK } from "@containers/Freezer/data";
import classnames from "classnames";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { flattenUnion } from "@helpers/TypeHelpers";
import { useOrganizationRouter } from "@root/AppRouter";
import { fillInUrlParams } from "@helpers/TypeHelpers";
import { FREEZER_PATHS } from "@containers/Freezer";
import { MessageWithLink } from "@common/components/Notification/Notification";
import { useHistory } from "@helpers/Hooks/UseRouterDom";
import {
	useBoxQuery,
	useBoxPatchMutation,
	useBoxesQuery,
} from "@redux/inventory/Box";
import { useFreezerQuery } from "@redux/inventory/Freezer";
import { useRackQuery } from "@redux/inventory/Rack";
import { useShelfQuery } from "@redux/inventory/Shelf";
import {
	useCategoryBoxesQuery,
	useCategoryQuery,
} from "@redux/inventory/Category";
import { clear } from "console";

type BoxMovingCartProps = {
	/** The id of the box that the user wants to move */
	boxId: number | null;
	/** Called when the modal is closed */
	onClose: () => void;
	/** Called after the user moves the box */
	onMoveBox: (data: Box) => void;
	/** Displays the rearrange box modal */
	onRearrange?: () => void;
};

/**
 * Component for users to move Boxes
 */
export default function BoxMovingCart(props: BoxMovingCartProps): JSX.Element {
	const { boxId, onClose, onMoveBox, onRearrange } = props;
	const { data: box } = useBoxQuery(boxId || -1, { skip: !boxId });

	return (
		<MovingCart
			visible={!!boxId}
			onClose={onClose}
			title={`Move "${box?.name}" to`}
		>
			<BoxPlacement
				boxId={boxId}
				onMoveBox={onMoveBox}
				onRearrange={onRearrange}
			/>
		</MovingCart>
	);
}

type BoxPlacementProps = {
	/** ID of the box to place/move */
	boxId: number | null;
	/** Called when the user clicks "Move here" to confirm the box's placement */
	onMoveBox: (data: Box) => void;
	/** Control the visibility of the rearrange box modal */
	onRearrange?: () => void;
};

/**
 * Allows the user to place boxes within a rack/category
 */
function BoxPlacement({ boxId, onMoveBox, onRearrange }: BoxPlacementProps) {
	const history = useHistory();
	const placement = useCartPlacement();
	const {
		setConfirmMoveFreezer,
		rackId,
		categoryId,
		setRack,
		setCategory,
		setShelf,
		setFreezer,
		clearRack,
		clearCategory,
	} = useContext(MovingCartContext);
	const { appendBaseUrl } = useOrganizationRouter();
	const [finalizing, setFinalizing] = useState<boolean>(false);

	const { data: rack, isLoading: isRackLoading } = useRackQuery(
		rackId || skipToken,
		{
			skip: rackId === -1,
		}
	);
	const { data: category, isLoading: isCategoryLoading } = useCategoryQuery(
		categoryId || skipToken,
		{
			skip: categoryId === -1,
		}
	);

	const isLoading = isRackLoading || isCategoryLoading;
	const rackOrCategory = rack || category;

	const { data: box, isLoading: boxLoading } = useBoxQuery(
		boxId || skipToken,
		{
			skip: boxId === -1,
		}
	);
	const { data: shelf } = useShelfQuery(
		rackOrCategory?.location.shelf || skipToken
	);
	const { data: freezer } = useFreezerQuery(shelf?.freezer || skipToken);
	const loading = boxLoading || isRackLoading;
	const [updateBox] = useBoxPatchMutation();

	//updated for rack and category
	useEffect(() => {
		if ((!rackId || rackId === -1) && (!categoryId || categoryId === -1)) {
			if (box) {
				setFreezer(box.location.freezer);

				box.location.rack_location
					? setRack(box.location.rack_location?.rack || -1)
					: setCategory(box.location.category || -1);
			}
		}
	}, [rackId, categoryId, box]);

	useEffect(() => {
		if (rack) {
			setShelf(rack.location.shelf);
		}
	}, [rack]);

	useEffect(() => {
		if (category) {
			setShelf(category.location.shelf);
		}
	}, [category]);

	const placeBox = (row: number, column: number) => {
		if (!boxId) return;
		placement.placeIdAtLocation(boxId, row, column);
	};

	/** Updates the box location */
	const handleFinalizePlacement = async () => {
		// Don't allow if still fetching data, no placed items, or a request is in progress
		if (
			loading ||
			!placement.getCount() ||
			!boxId ||
			(!rackId && !categoryId)
		)
			return;

		// Get the new location of the box
		const locations = placement.getLocations();
		const location = locations[boxId];
		if (location) {
			const locationPayload =
				rackId && rackId !== -1
					? {
							rack_location: {
								row: location.row,
								column: location.column,
								rack: rackId,
							},
							category: null,
					  }
					: {
							rack_location: null,
							category: categoryId,
					  };

			setFinalizing(true);
			updateBox({
				id: boxId,
				location: {
					...locationPayload,
				},
			})
				.unwrap()
				.then((movedBox) => {
					onMoveBox?.(movedBox);

					Notification.success({
						message: (
							<MessageWithLink
								message={`The box has been moved to "${rackOrCategory?.name}."`}
								onClick={() =>
									history.push(
										appendBaseUrl(
											fillInUrlParams(FREEZER_PATHS.BOX)({
												id: boxId,
											})
										)
									)
								}
								linkText="View box"
							/>
						),
					});
				})
				.finally(() => setFinalizing(false));
		}
	};

	// Prompt user before navigating backwards
	const [confirmBack, setConfirmBack] = useState<boolean>(false);
	const handleBackNavigate = () => {
		setConfirmBack(false);
		clearRack();
		clearCategory();
	};

	useEffect(() => {
		window.onbeforeunload = (event: BeforeUnloadEvent) => {
			if (placement.getCount() > 0) {
				const e = event || window.event;
				e.preventDefault();
				if (e) {
					e.returnValue = "";
				}
				return "";
			}
			return null;
		};
		setConfirmMoveFreezer(placement.getCount() > 0);
	}, [placement]);

	useEffect(() => {
		// Remove the unbeforeunload message. Otherwise it will show up after we close the modal
		return () => {
			window.onbeforeunload = () => null;
			setConfirmMoveFreezer(false);
		};
	}, []);

	// True if the user is in the rack that the box is currently in
	const isSameRack = rackId === box?.location.rack_location?.rack;
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.headerBlock}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: 12,
						}}
					>
						<Typography
							variant="label"
							color="text-secondary"
							className={styles.navigationItem}
							ellipsis
							hideTooltip
							onClick={() => {
								setShelf(null);
								setRack(null);
								setCategory(null);
							}}
						>
							{freezer?.name}
						</Typography>{" "}
						<GenemodIcon name="chevron-right" />{" "}
						<Typography
							variant="label"
							color="text-secondary"
							className={styles.navigationItem}
							ellipsis
							hideTooltip
							onClick={() => {
								setRack(null);
								setCategory(null);
							}}
						>
							{shelf?.name}
						</Typography>{" "}
						<GenemodIcon name="chevron-right" />{" "}
						<Typography variant="label" color="text-secondary" bold>
							{rack ? rack.name : category ? category.name : ""}
						</Typography>
					</div>
				</div>
				<div className={styles.headerBlock}>
					<Tooltip
						title={
							<Typography variant="caption" color="text-inverse">
								Click on a &quot;+&quot; button to place the
								box.
								{/* <Link to="todo">Watch tutorial here</Link> */}
							</Typography>
						}
						placement="left"
					>
						<GenemodIcon
							name="question-mark-o"
							size="large"
							style={{
								marginRight: "8px",
								marginLeft: "auto",
							}}
							fill="text-secondary"
						/>
					</Tooltip>
					<ButtonV2
						type="primary"
						size="small"
						disabled={!placement.getCount() || loading}
						onClick={handleFinalizePlacement}
						loading={finalizing}
					>
						Move here
					</ButtonV2>
				</div>
			</div>
			{(rackId || categoryId) && (
				<div className={styles.displayWrapper}>
					<MovingCartRackDisplay
						rackId={rackId}
						categoryId={categoryId}
						mode={{
							type: "PLACE_BOX",
							placement,
							box,
							placeBox,
						}}
					/>
				</div>
			)}

			{/* Only applicable to racks. Not categories */}
			{isSameRack && onRearrange && (
				<Typography
					variant="label"
					style={{ textAlign: "center", marginTop: "48px" }}
				>
					Want to rearrange boxes within this rack? Try{" "}
					<ButtonV2
						style={{ display: "inline", marginLeft: "-3px" }}
						type="link"
						onClick={onRearrange}
					>
						rearrange rack
					</ButtonV2>
				</Typography>
			)}
			<UnsavedChangesModal
				visible={confirmBack}
				onCancel={() => setConfirmBack(false)}
				onOk={handleBackNavigate}
			/>
		</div>
	);
}

type MovingCartRackDisplay = {
	rackId: number | null;
	categoryId: number | null;
	mode?:
		| {
				type: "PLACE_BOX";
				placement: UseCartPlacementAPI;
				box: Box | undefined;
				placeBox: (row: number, column: number) => void;
		  }
		| { type: "PREVIEW" };
};

export const MovingCartRackDisplay = ({
	rackId,
	categoryId,
	mode = { type: "PREVIEW" },
}: MovingCartRackDisplay): JSX.Element => {
	const { data: rack, isLoading: isRackLoading } = useRackQuery(
		rackId || skipToken
	);

	const { data: category, isLoading: isCategoryLoading } = useCategoryQuery(
		categoryId || skipToken
	);

	let rackOrCategory: Rack | Category | null = null;
	if (rack) rackOrCategory = rack;
	else if (category) rackOrCategory = category;

	const rackData = useBoxesQuery(
		rackId
			? {
					id: rackId,
			  }
			: skipToken,
		{
			selectFromResult: ({ data, ...rest }) => ({
				...rest,
				data: data,
			}),
		}
	);

	const categoryData = useCategoryBoxesQuery(
		categoryId ? { id: categoryId } : skipToken,
		{
			selectFromResult: ({ data, ...rest }) => ({
				...rest,
				data: data,
			}),
		}
	);

	let boxes: Box[] | undefined;
	let areBoxesLoading = false;

	if (rack) {
		boxes = rackData.data;
		areBoxesLoading = rackData.isLoading;
	} else {
		boxes = categoryData.data;
		areBoxesLoading = categoryData.isLoading;
	}
	const loading = isRackLoading || areBoxesLoading || isCategoryLoading;

	if (
		rackOrCategory === null ||
		isRackLoading ||
		areBoxesLoading ||
		isCategoryLoading ||
		(!rack && !category) ||
		!boxes
	)
		return <></>;

	const { type, placement, box, placeBox } = flattenUnion(mode);

	const { jsx, numRows, numColumns } = (() => {
		const jsx = [] as JSX.Element[];

		if (!isRack(rackOrCategory)) {
			/** Number of racks per row when rendering boxes for categories */
			const CATEGORY_BOXES_PER_ROW = 4;
			const hasAdditionalBox =
				box?.location.category !== rackOrCategory.id;
			const additionalBox =
				box &&
				box.location.rack_location?.rack !== rackId &&
				hasAdditionalBox
					? 1
					: 0;
			const numColumns =
				Math.min(
					CATEGORY_BOXES_PER_ROW,
					boxes.length + additionalBox
				) || 0;
			const numRows =
				Math.ceil(boxes.length / CATEGORY_BOXES_PER_ROW) || 0;

			boxes.forEach((box) => {
				jsx.push(
					<BoxCard
						row={0}
						column={0}
						box={box}
						placed={false}
						viewOnly={type === "PREVIEW"}
					/>
				);
			});
			if (
				type === "PLACE_BOX" &&
				box?.location.rack_location?.rack !== rackId &&
				hasAdditionalBox
			) {
				// Add an additional BoxCard at the end for the new box onlyif in different rack
				const placed = !!placement.getCount();
				const cardBox = placed ? box : undefined;
				jsx.push(
					<BoxCard
						row={0}
						column={0}
						box={cardBox}
						placed={placed}
						onPlace={placeBox}
						onRemove={placement.clearLocation}
						disabled={loading}
					/>
				);
			}
			return { jsx, numColumns, numRows };
		}

		const unrwappedRack = rackOrCategory;
		// Regular racks, not categories
		for (let row = 0; row < unrwappedRack.rows; row++) {
			for (let col = 0; col < unrwappedRack.columns; col++) {
				// Pass down the box for this cell if it exists
				const currBox = boxes.find(
					(box) =>
						box.location.rack_location?.column === col &&
						box.location.rack_location?.row === row
				);

				jsx.push(
					<BoxCard
						row={row}
						column={col}
						box={currBox}
						{...(() => {
							if (type === "PREVIEW") {
								return { viewOnly: true };
							}
							let extraProps: Partial<BoxCardProps> = {
								onRemove: placement.clearLocation,
								disabled: !!placement?.getCount() || loading,
								onPlace: placeBox,
								viewOnly: false,
							};
							// True if the box has been placed at this BoxCard
							if (
								type === "PLACE_BOX" &&
								placement &&
								!currBox &&
								!!placement.getIdAtLocation(row, col)
							)
								extraProps = {
									...extraProps,
									box,
									placed: true,
								};
							return extraProps;
						})()}
					/>
				);
			}
		}
		return {
			jsx,
			numColumns: unrwappedRack.columns,
			numRows: unrwappedRack.rows,
		};
	})();

	const table = (
		<div
			className={classnames(styles.content, {
				[styles.content__preview]: type === "PREVIEW",
			})}
			style={{
				maxWidth: "100%",
				width: `${((numColumns || 1) / MAX_COLS_RACK) * 100}%`,
			}}
		>
			<div
				className={classnames(styles.boxes, "genemod-lightmode")}
				style={{
					gridTemplateColumns: `repeat(${numColumns}, minmax(0, 1fr))`,
					gridTemplateRows: `repeat(${numRows}, 1fr)`,
				}}
			>
				{jsx}
			</div>
		</div>
	);

	// Rendering the PreviewScaleWrapper here so that it rerenders when data comes in.
	if (type === "PREVIEW") {
		if (!boxes.length && !isRack(rackOrCategory)) {
			return (
				<div style={{ width: 200, height: 100, padding: 20 }}>
					<Typography>Category is empty</Typography>
				</div>
			);
		}
		return (
			<PreviewScaleWrapper
				virtualWidth={930}
				virtualHeight={1200}
				maxParentWidth={350}
				maxParentHeight={350}
				maximumScaling={0.7}
				minimumScaling={0.37}
			>
				{table}
			</PreviewScaleWrapper>
		);
	}

	return table;
};

type BoxCardProps = {
	/** Row of the box. Used for callback function args */
	row: number;
	/** Column of the box. Used for callback function args */
	column: number;
	/** True if the box is only for a preview and should not be interactable. Also changes font size. */
	viewOnly?: boolean;
	/** The box for this BoxCard if applicable */
	box?: Box;
	/** True if a box has been placed in the location of this BoxCard */
	placed?: boolean;
	/** Called when a box gets placed on the BoxCard location */
	onPlace?: (row: number, column: number) => void;
	/** Called when a box is removed from the BoxCard location */
	onRemove?: (row: number, column: number) => void;
	/** Disables the card */
	disabled?: boolean;
	/**
	 * If true, sets a width on the card. Used for categories since
	 * they don't have dimensions
	 * */
	stretch?: boolean;
};

function BoxCard(props: BoxCardProps) {
	const {
		row,
		column,
		box,
		placed,
		onPlace,
		viewOnly,
		onRemove,
		disabled,
		stretch,
	} = props;

	const handleClick = () => {
		// Not allowed if there is already a box in this location
		if (box && !placed) return;

		if (disabled) {
			// Can only remove when everything is disabled
			onRemove?.(row, column);
		} else {
			// Determine which function to call: onPlace or onRemove
			const fn = placed ? onRemove : onPlace;
			// Call w/ args
			fn?.(row, column);
		}
	};

	const boxNameColor = placed ? "text-secondary" : "text-ghost";

	return (
		<div
			className={cn(styles.boxCard, {
				[styles.boxCard__preview]: viewOnly,
				[styles.boxCard__occupied]: !!box,
				[styles.boxCard__placed]: placed,
				[styles.boxCard__disabled]: disabled,
				[styles.boxCard__stretch]: stretch,
			})}
			onClick={handleClick}
		>
			<Typography
				variant="label"
				color={boxNameColor}
				ellipsis
				hideTooltip
				className={styles.boxName}
			>
				{box?.name}
			</Typography>
			{(!box || (box && placed)) && (
				<div className={styles.iconContainer}>
					{placed ? (
						<GenemodIcon
							name="undo"
							fill="button-primary"
							lightmode
						/>
					) : (
						<GenemodIcon lightmode name="plus" />
					)}
				</div>
			)}
		</div>
	);
}
