import { useBarcodeModal } from "@common/barcode/BarcodeModal";
import { DropdownMenuDivider } from "@common/components/DropDown/dropdown";
import { Item } from "@common/types";
import {
	ButtonV2,
	DropDown,
	GenemodIcon,
	Typography,
	UpgradeButton,
} from "@components";
import { useFeatureRestrictionHook } from "@helpers/Hooks/featureRestrictionHook";
import {
	ValueOf,
	conditionalArrayElements,
	mapToKeyValue,
} from "@helpers/TypeHelpers";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import {
	getBoxTableState,
	useBoxTableActions,
	useBoxTableFields,
} from "@redux/freezer/BoxTableSlice";
import { prepareItemPrint } from "@redux/freezer/BoxViewSlice";
import { useAppDispatch } from "@redux/store";
import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useOpenOrderFormForItem } from "../../../BoxView/BoxView";
import {
	AreaBounds,
	CONTEXT_MENU_ID,
	areaBoundsIncludesCellId,
	getCellId,
} from "../../../data";
import {
	getClipboardManager,
	useBookmarkToggleClick,
	useBoxView,
	useCopySelected,
} from "../../BoxTableHooks";
import styles from "../InnerBoxTable/InnerBoxTable.module.scss";
import { useOpenDeleteSelectedBoxTableCellsModal } from "../SelectedCellActionModals/SelectedCellActionModals";
import { UNARCHIVE_STEPS } from "@containers/Freezer/Repository/components/Unarchive/unarchiveSteps";
import { useRepository } from "@containers/Freezer/Repository/RepositoryContext";
import cn from "classnames";

/**
 * Wraps table in dropdown menu for copy/paste/etc.
 */
export const WithCellsMenu: React.FunctionComponent = ({ children }) => {
	const { setIsFocused } = useBoxTableActions();
	const [open, setOpen] = useState(false);

	const { options, selectedCellsContainingItemsCount, isMultiSelect } =
		useBoxCellMenuOptions();
	type OptionType = keyof typeof options;

	const handleSelection = (key: OptionType) => {
		setOpen(false);
		setIsFocused(true);
		options[key]?.onClick?.();
	};

	type ActionType = ValueOf<typeof options>;

	const actions: (ActionType | "DIVIDER")[] = (() => {
		// If there are multiple selection boxes
		if (isMultiSelect)
			return [options.moveTo, options.delete, options.printBarcode];

		// If all selected cells are empty
		if (selectedCellsContainingItemsCount === 0)
			return [options.copy, options.paste];

		if (selectedCellsContainingItemsCount === 1)
			return [
				options.cut,
				options.copy,
				options.paste,
				options.delete,
				// ~~~~~~~~~~~~~~~~
				"DIVIDER",
				options.moveTo,
				options.bookmark,
				options.requestOrder,
				// ~~~~~~~~~~~~~~~~
				"DIVIDER",
				options.printBarcode,
				options.print,
				options.share,
			];

		return [
			options.cut,
			options.copy,
			options.paste,
			options.moveTo,
			options.delete,
			options.printBarcode,
		];
	})();

	return (
		<DropDown
			overlay={
				<Menu
					id={CONTEXT_MENU_ID}
					onClick={(option) =>
						handleSelection(option.key as OptionType)
					}
				>
					{actions.map((action, index) => {
						if (action === "DIVIDER") {
							return (
								<DropdownMenuDivider key={`divider-${index}`} />
							);
						}
						const { key, renderMenuItem } = action;
						return (
							<Menu.Item key={key}>{renderMenuItem()}</Menu.Item>
						);
					})}
				</Menu>
			}
			trigger={["contextMenu"]}
			size="compact"
			open={open}
			onVisibleChange={(visible) => setOpen(visible)}
		>
			{children}
		</DropDown>
	);
};

export const HeaderCellActions: React.FunctionComponent = () => {
	const { options, selectedCellsContainingItemsCount } =
		useBoxCellMenuOptions();
	const { cellId } = useBoxTableFields("cellId");
	const { selectedAreaBounds } = getBoxTableState();

	let actions = [];
	if (!cellId) {
		actions = [
			options.importItems,
			options.restoreItems,
			options.customizeBox,
		];
	} else {
		actions = [
			...conditionalArrayElements(selectedCellsContainingItemsCount > 0)(
				options.copy
			),
			options.paste,
			...conditionalArrayElements(selectedCellsContainingItemsCount > 0)(
				options.moveTo,
				// options.delete,
				options.printBarcode,
				options.archiveItem
			),
			...conditionalArrayElements(
				selectedCellsContainingItemsCount === 0
			)(options.importItems),
		];
	}

	return (
		<div className={styles.headerCellActions}>
			{selectedAreaBounds.length > 0 && (
				<Typography
					variant="body"
					color="text-tertiary-v2"
					bold
					data-cy="header-num-selected"
				>
					{selectedCellsContainingItemsCount} selected:
				</Typography>
			)}
			{actions.map(
				({ key, renderHeader, onClick, disabled, type, danger }) => {
					return (
						<ButtonV2
							key={key}
							dataCy={`header-cell-${key.replace(/\s/g, "-")}`}
							onClick={() => onClick()}
							type={type}
							disabled={disabled}
							danger={danger}
						>
							{renderHeader()}
						</ButtonV2>
					);
				}
			)}
		</div>
	);
};

const useBoxCellMenuOptions = () => {
	const dispatch = useAppDispatch();
	const { selectedAreaBounds } = useBoxTableFields("selectedAreaBounds");
	const { setMovingItems, setIsImporting, setIsArchiving } =
		useBoxTableActions();
	const { openUpgradeModal } = useCommonModalState("upgradeModal");
	const openOrderForm = useOpenOrderFormForItem({ fromBoxView: true });
	const copySelected = useCopySelected();
	const { openPasteSelectedBoxTableCellsModal } = useCommonModalState(
		"pasteSelectedBoxTableCellsModal"
	);
	const deleteSelected = useOpenDeleteSelectedBoxTableCellsModal();
	const handleBookmarkToggleClick = useBookmarkToggleClick();
	const { box, items } = useBoxView();
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const { openCustomizeBoxModal } = useCommonModalState("customizeBoxModal");
	const { is_limit_reached: orderManagementRestricted } =
		useFeatureRestrictionHook("order_management");
	const { openBarcodeModal } = useBarcodeModal();
	const [clipboardSize, setClipboardSize] = useState(getClipboardSize());

	const { openUnArchiveContainer } = useRepository();

	const getItem = () =>
		items.find((item) => getCellId(item) === getBoxTableState().cellId);

	let selectedCellsContainingItemsCount = 0;
	items.forEach((x: Item) => {
		selectedAreaBounds.forEach((selectedCell: AreaBounds) => {
			if (areaBoundsIncludesCellId(selectedCell, getCellId(x))) {
				selectedCellsContainingItemsCount++;
			}
		});
	});

	useEffect(() => {
		const interval = setInterval(() => {
			setClipboardSize(getClipboardSize());
		}, 300);
		return () => clearInterval(interval);
	}, []);

	const printBarcode = () => {
		const selectedItems = Object.values(
			selectedAreaBounds.reduce<Record<string, Item>>(
				(accumulator, areaBounds) => {
					for (const item of items) {
						if (
							accumulator[item.id] === undefined &&
							areaBoundsIncludesCellId(
								areaBounds,
								getCellId(item)
							)
						) {
							accumulator[item.id] = item;
						}
					}
					return accumulator;
				},
				{}
			)
		);
		if (selectedItems.length === 0) return;
		openBarcodeModal(selectedItems);
	};

	const options = mapToKeyValue(
		[
			{
				key: "cut",
				onClick: () =>
					copySelected({
						cutNotCopy: true,
						focusModeOnly: false,
					}),
				renderHeader: () => <>Cut</>,
				renderMenuItem: () => (
					<BoxCellMenuItem
						name="Cut"
						shortcut="Ctrl/⌘+X"
						dataCy="cut"
					/>
				),
				type: "link",
			},
			{
				key: "copy",
				onClick: () =>
					copySelected({
						cutNotCopy: false,
						focusModeOnly: false,
					}),
				renderHeader: () => <>Copy</>,
				renderMenuItem: () => (
					<BoxCellMenuItem
						name="Copy"
						shortcut="Ctrl/⌘+C"
						dataCy="copy"
					/>
				),
				type: "link",
			},
			{
				key: "paste",
				disabled: clipboardSize === 0,
				onClick: () =>
					openPasteSelectedBoxTableCellsModal({
						focusModeOnly: false,
					}),
				renderHeader: () => <PasteHeaderOption />,
				renderMenuItem: () => (
					<BoxCellMenuItem
						name="Paste"
						shortcut="Ctrl/⌘+V"
						dataCy="paste"
					/>
				),
				type: "link",
			},
			{
				key: "delete",
				onClick: deleteSelected,
				renderHeader: () => <>Delete selected</>,
				renderMenuItem: () => (
					<BoxCellMenuItem
						name="Delete"
						shortcut="Del"
						dataCy="delete-selected"
					/>
				),
				type: "link",
				danger: true,
			},
			{
				key: "moveTo",
				onClick: () => setMovingItems(true),
				renderHeader: () => <>Move to</>,
				renderMenuItem: () => {
					if (selectedCellsContainingItemsCount > 1)
						return <>Move items</>;
					return <>Move</>;
				},
				type: "link",
			},
			{
				key: "requestOrder",
				onClick: () => {
					if (orderManagementRestricted) {
						openUpgradeModal({ type: "UNLOCK_ORDERS" });
						return;
					}

					openOrderForm();
				},
				renderHeader: () => <>Request order</>,
				renderMenuItem: () => (
					<>
						Request order
						{orderManagementRestricted && (
							<UpgradeButton
								type="tag"
								style={{ marginLeft: 24 }}
							/>
						)}
					</>
				),
			},
			{
				key: "print",
				onClick: () => {
					const item = getItem();
					if (item) {
						dispatch(prepareItemPrint(item.id));
					}
				},
				renderHeader: () => <>Print</>,
				renderMenuItem: () => (
					<BoxCellMenuItem
						name="Print"
						shortcut="Ctrl/⌘+P"
						dataCy="print"
					/>
				),
			},
			{
				key: "share",
				onClick: () => openShareLinkModal({}),
				renderHeader: () => <>Share</>,
				renderMenuItem: () => <>Share</>,
			},
			{
				key: "bookmark",
				onClick: () => {
					const item = getItem();
					if (!item) return;
					handleBookmarkToggleClick(item);
				},
				renderHeader: () => <>Bookmark</>,
				renderMenuItem: () => <>Bookmark</>,
			},
			{
				key: "printBarcode",
				onClick: printBarcode,
				renderHeader: () => "Print barcode",
				renderMenuItem: () => "Print barcode",
				type: "link",
			},
			{
				key: "importItems",
				onClick: () => setIsImporting(true),
				renderHeader: () => "Import items",
				renderMenuItem: () => <></>,
				type: "default",
			},
			{
				key: "customizeBox",
				onClick: () =>
					box ? openCustomizeBoxModal({ boxId: box.id }) : null,
				renderHeader: () => "Customize box",
				renderMenuItem: () => <></>,
				type: "default",
			},
			{
				key: "archiveItem",
				onClick: () => setIsArchiving(true),
				renderHeader: () => (
					<span
						style={{
							display: "flex",
							gap: 8,
							alignItems: "center",
						}}
					>
						<GenemodIcon name="archive" />
						Archive selected{" "}
					</span>
				),
				renderMenuItem: () => <></>,
				icon: "archive",
				type: "link",
			},
			{
				key: "restoreItems",
				onClick: () => openUnArchiveContainer(null, "ITEM"),
				renderHeader: () => "Restore items",
				renderMenuItem: () => <></>,
			},
		],
		"key"
	);

	return {
		options,
		selectedCellsContainingItemsCount,
		isMultiSelect: selectedAreaBounds.length > 1,
	} as const;
};

type BoxCellMenuItemProps = {
	name: string;
	shortcut?: string;
	dataCy?: string;
};

function BoxCellMenuItem({
	name,
	shortcut,
	dataCy,
}: BoxCellMenuItemProps): JSX.Element {
	return (
		<span
			className={styles.menuItemContainer}
			data-cy={`cell menu-${dataCy}`}
		>
			<span>{name}</span>
			{shortcut && <span className={styles.shortcut}>{shortcut}</span>}
		</span>
	);
}

const getClipboardSize = () =>
	Object.values(getClipboardManager().getClipboard()?.items).length || 0;

/**
 * Since the clipboard is stored in local storage and won't trigger a re-render
 * when it changes, we need to poll the clipboard size to update the paste option
 */
const PasteHeaderOption = () => {
	const [clipboardSize, setClipboardSize] = useState(getClipboardSize());

	useEffect(() => {
		const interval = setInterval(() => {
			setClipboardSize(getClipboardSize());
		}, 300);
		return () => clearInterval(interval);
	}, []);

	return <>Paste ({clipboardSize})</>;
};
