import React, {
	useState,
	useRef,
	Dispatch,
	SetStateAction,
	useEffect,
} from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { Menu } from "antd";
import { GenemodIcon, Typography, Notification, DropdownV2 } from "@components";
import DeleteConfirmModal from "@containers/Freezer/components/DeleteConfirmModal/DeleteConfirmModal";
import { SegmentTrackEvent, SegmentFreezerEvents } from "@Segment";
import { ClickParam } from "antd/lib/menu";
import styles from "./RackCard.module.scss";
import "./index.scss";
import { RackCategoryHover } from "./ShelfRow";
import { Category, Freezer, Rack } from "@common/types";
import { useLocation } from "@common/helpers/Hooks/UseRouterDom";
import { useExportState } from "@containers/Freezer/hooks";
import { truncArgs } from "@helpers/Formatters";

import {
	DropdownMenuDivider,
	DropdownMenuItem,
} from "@common/components/DropDownV2/dropdownV2";
import { useCommonModalState } from "@redux/CommonModals/hooks";
import { FREEZER_PATHS } from "@containers/Freezer";
import { useOrganizationRouter } from "@root/AppRouter";
import { useFreezer } from "../Contents";
import { useCommonPanelState } from "@redux/CommonPanels/hooks";
import {
	useCategoryPatchMutation,
	useCategoryDeleteMutation,
} from "@redux/inventory/Category";
import {
	useRackPatchMutation,
	useRackDeleteMutation,
} from "@redux/inventory/Rack";
import RackMovingCart from "@containers/Freezer/components/MovingCart/RackMovingCart/RackMovingCart";
import classNames from "classnames";
import { useParams } from "@helpers/URLParams";
import {
	ArchiveStep,
	useRepository,
} from "@containers/Freezer/Repository/RepositoryContext";
import { UNARCHIVE_STEPS } from "@containers/Freezer/Repository/components/Unarchive/unarchiveSteps";

type RackCardProps = {
	isEditingFreezer: boolean;
	rackOrCategory: Rack | Category;
	rackHover: RackCategoryHover | null;
	selectRackOrCategory: (
		obj: Rack | Category,
		select: boolean,
		callback?: () => void
	) => void;
	setLoading: (isLoading: boolean) => void;
	setRackHover: (isHover: RackCategoryHover | null) => void;
	style?: React.CSSProperties;
	viewOnly: boolean;
	dataCy?: string;
};

const DISABLE_DESELECT_OF_RACK_OR_CATEGORY = true;

export default function RackCard(props: RackCardProps): JSX.Element {
	const ItemTypes = {
		RACK: "rack",
		CATEGORY: "category",
	};
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const [patchRack] = useRackPatchMutation();
	const [patchCategory] = useCategoryPatchMutation();
	const { freezer } = useFreezer();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const isRack = (obj: Rack | Category) => {
		return (obj as Rack).rows !== undefined;
	};

	const is_rack = isRack(props.rackOrCategory);

	// Prevent clicks on the rack if using menu
	const handleMenuClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
		if (e.stopPropagation) {
			e.stopPropagation();
		} else {
			// cancelBubble is an old IE property
			(e as any).cancelBubble = true;
		}
	};

	const handleRackClick = () => {
		if (!props.isEditingFreezer && !isModalOpen) {
			props.selectRackOrCategory(
				props.rackOrCategory,
				DISABLE_DESELECT_OF_RACK_OR_CATEGORY
			);
			SegmentTrackEvent(SegmentFreezerEvents.RACK_VIEW, {
				id: props.rackOrCategory.id,
				num_rows: (props.rackOrCategory as Rack).rows || 0,
				num_columns: (props.rackOrCategory as Rack).columns || 0,
				is_category: false,
			});
		}
	};

	const classes = [styles.card];
	const isSelected = is_rack
		? params.has("selected_rack") &&
		  props.rackOrCategory.id ===
				parseInt(params.get("selected_rack") || "")
		: params.has("selected_category") &&
		  props.rackOrCategory.id ===
				parseInt(params.get("selected_category") || "");

	if (props.isEditingFreezer) {
		classes.push("rack__rearranging");
	}
	if (isSelected) {
		classes.push(styles.selectedCard);
	}

	// DRAG AND DROP
	const ref = useRef<HTMLDivElement>(null);
	const [{ isDragging }, drag] = useDrag({
		item: {
			type: ItemTypes.RACK || ItemTypes.CATEGORY,
			id: props.rackOrCategory.id,
			location_index: props.rackOrCategory.location.index,
			location_shelf: props.rackOrCategory.location.shelf,
			obj: props.rackOrCategory,
			toLeft: false,
		},
		begin() {
			return {
				type: ItemTypes.RACK || ItemTypes.CATEGORY,
				id: props.rackOrCategory.id,
				location_index: props.rackOrCategory.location.index,
				location_shelf: props.rackOrCategory.location.shelf,
				obj: props.rackOrCategory,
				toLeft: false,
			};
		},
		canDrag() {
			return props.isEditingFreezer;
		},
		collect(monitor) {
			return {
				isDragging: monitor.isDragging(),
			};
		},
		end(item: any, monitor) {
			// Rearrange here
			if (monitor.didDrop()) {
				// Location_col refers to the new location if racks were rearranged
				const { obj, location_shelf, location_index, toLeft } = item;

				props.setLoading(true);

				const failure = (err?: string) => {
					console.log("Failed to relocate rack", err);
					Notification.warning({
						message: "Failed to relocate rack",
					});
				};

				const data = { ...obj };

				data["location"] = {
					shelf: location_shelf,
					index: Math.max(0, location_index + (toLeft ? -1 : 1)),
					freezer: data.location.freezer,
				};

				if (isRack(obj)) {
					patchRack(data)
						.unwrap()
						.then(() => props.setLoading(false))
						.catch((err) => {
							failure(err);
						});
				} else {
					patchCategory(data)
						.unwrap()
						.then(() => props.setLoading(false))
						.catch((err) => {
							failure(err);
						});
				}
			}
			props.setRackHover(null);
		},
	});

	const [, drop] = useDrop({
		accept: ItemTypes.RACK,
		hover(item: any, monitor) {
			// If hovered rack changed or side changed
			const clientOffset = monitor.getClientOffset() as XYCoord;

			// Check if rack exists in DOM
			if (!ref.current) return;
			if (item.id === props.rackOrCategory.id) {
				if (props.rackHover) {
					props.setRackHover(null);
				}

				if (item.location_index !== item.obj.location_index) {
					item.location_index = item.obj.location_index;
					item.location_shelf = item.obj.location_shelf;
				}
				return;
			}

			const rackPos = ref.current.getBoundingClientRect();
			const rackMiddleX = rackPos.left + rackPos.width / 2;
			const toLeft = clientOffset.x < rackMiddleX;

			const dist =
				item.obj.location.index - props.rackOrCategory.location.index;
			if (
				item.obj.location.shelf ===
					props.rackOrCategory.location.shelf &&
				Math.abs(dist) <= 1
			) {
				if ((dist === -1 && toLeft) || (dist === 1 && !toLeft)) {
					props.setRackHover(null);
					item.location_index = item.obj.location.index;
					return;
				}
			}

			// Update if hovered rack or position is different
			if (
				!props.rackHover ||
				props.rackHover.obj.id !== props.rackOrCategory.id ||
				props.rackHover.toLeft !== toLeft
			) {
				props.setRackHover({
					obj: props.rackOrCategory,
					toLeft,
				});

				item.location_index = props.rackOrCategory.location.index;
				item.toLeft = toLeft;
			}
		},
	});

	if (props.rackHover) {
		let isNeighbor = false;
		let distance = -1;
		let isOver = false;
		if (props.rackHover) {
			distance =
				props.rackHover.obj.location.index -
				props.rackOrCategory.location.index;
			isNeighbor = Math.abs(distance) === 1;
			isOver = props.rackHover.obj.id === props.rackOrCategory.id;
		}

		if (isOver) {
			// Highlight left
			if (props.rackHover.toLeft) {
				classes.push("rack__hovered__left");
			} else {
				classes.push("rack__hovered__right");
			}
		}

		// Next to the hovered rack
		if (isNeighbor) {
			if (props.rackHover.toLeft && distance === 1) {
				classes.push("rack__hovered__right");
			} else if (!props.rackHover.toLeft && distance === -1) {
				classes.push("rack__hovered__left");
			}
		}
	}

	drag(drop(ref));
	return (
		<div
			ref={ref}
			className={classNames(styles.rackCard, {
				[styles.selected]: isSelected,
			})}
			onClick={handleRackClick}
			style={{ ...props.style, opacity: isDragging ? 0.4 : 1 }}
			data-cy={props.dataCy}
		>
			{!is_rack && (
				<div className={styles.categoryIcon}>
					<GenemodIcon
						name="category"
						size="large"
						fill="accent-subtle"
						stroke="accent-subtle"
					/>
				</div>
			)}
			{!props.isEditingFreezer && (
				<RackMenu
					freezer={freezer}
					setIsModalOpen={setIsModalOpen}
					{...props}
				>
					<div
						onClick={handleMenuClick}
						className={styles.catOptions}
						data-cy={`${props.dataCy}-menu`}
					>
						<GenemodIcon
							name="meatballs"
							size="large"
							hoverEffect
						/>
					</div>
				</RackMenu>
			)}

			{is_rack && <div className={styles.rowHeader}></div>}
			<div className={styles.content}>
				<Typography
					variant="label"
					resize={false}
					className={styles.category_name}
					ellipsis
					color="text-secondary"
				>
					{props.rackOrCategory.name}
				</Typography>
			</div>
		</div>
	);
}

type RackMenuProps = {
	freezer: Freezer | undefined;
	dataCy?: string;
	rackOrCategory: Rack | Category;
	viewOnly: boolean;
	children?: React.ReactNode | React.ReactNode;
	setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
	selectRackOrCategory?: (
		obj: Rack | Category,
		select: boolean,
		callback?: () => void
	) => void;
};

export const RackMenu = ({
	freezer,
	children,
	setIsModalOpen,
	...props
}: RackMenuProps) => {
	const isRack = (obj: Rack | Category) => {
		return (obj as Rack).rows !== undefined;
	};
	const [deleteRack, setDelete] = useState(false);
	const { handleExport, exportModal } = useExportState();
	const { openShareLinkModal } = useCommonModalState("shareLinkModal");
	const [showMenu, setMenu] = useState<boolean>(false);
	const is_rack = isRack(props.rackOrCategory);
	const { appendBaseUrlAndParams } = useOrganizationRouter();
	const { deleteParam } = useParams();
	const [moveModalOpen, setMoveModalOpen] = useState(false);
	const {
		genericallyNamed: { openPanel },
	} = useCommonPanelState(is_rack ? "rackSettings" : "categorySettings");
	const [deleteRackFromShelf] = useRackDeleteMutation();
	const [deleteCategoryFromShelf] = useCategoryDeleteMutation();
	const { handleArchiveOrRestoreButton, openUnArchiveContainer } =
		useRepository();

	useEffect(() => {
		if (setIsModalOpen) {
			if (!showMenu && !deleteRack && !exportModal) {
				setIsModalOpen(false);
			} else {
				setIsModalOpen(true);
			}
		}
	}, [exportModal, showMenu, deleteRack]);

	const handleDelete = () => {
		const rack = props.rackOrCategory;
		const failure = (err?: string) => {
			console.log("Failed to delete the rack", err, rack);
			Notification.warning({
				message: "Failed to delete the rack",
			});
		};

		const deleteMethod = is_rack
			? deleteRackFromShelf
			: deleteCategoryFromShelf;

		deleteMethod(rack.id)
			.unwrap()
			.then(() => {
				setDelete(false);
				deleteParam("selected_rack");
				deleteParam("selected_category");
				Notification.success({
					message: (
						<span>
							<b>{truncArgs`${rack?.name}`(68)}</b>
							{" has been deleted."}
						</span>
					),
				});
			})
			.catch(failure);
	};

	const handleMenuSelection = ({ key }: ClickParam) => {
		setMenu(false);
		if (key === "setting") {
			openPanel({ id: props.rackOrCategory.id });
		} else if (key === "export") {
			if (props.rackOrCategory) {
				handleExport(
					"racks",
					props.rackOrCategory.id,
					props.rackOrCategory.name
				);
			}
		} else if (key === "share") {
			if (freezer) {
				openShareLinkModal({
					newPath: appendBaseUrlAndParams(
						FREEZER_PATHS.CONTENTS.replace(
							":id",
							freezer.id.toString() || ""
						),
						is_rack
							? { selected_rack: props.rackOrCategory.id }
							: { selected_category: props.rackOrCategory.id }
					),
				});
			}
		} else if (key === "delete") {
			setDelete(true);
		} else if (key === "archive") {
			handleArchiveOrRestoreButton(true, {
				items: [props.rackOrCategory],
				layer: is_rack ? "RACK" : "CATEGORY",
			});
			deleteParam("selected_rack");
			deleteParam("selected_category");
		} else if (key === "restore-box") {
			openUnArchiveContainer(props.rackOrCategory, "BOX");
		} else if (key === "restore-item-group") {
			openUnArchiveContainer(props.rackOrCategory, "ITEMGROUP");
		} else if (key === "move") {
			setMoveModalOpen(true);
		}
	};
	const viewOnly = freezer?.is_archived;

	const dropdownMenu = (
		<Menu onClick={handleMenuSelection}>
			<DropdownMenuItem
				key="setting"
				label="Settings"
				data-cy={`${props.dataCy}-menu-settings`}
				icon="settings"
			/>
			{/* <DropdownMenuItem
				key="move"
				label="Move"
				data-cy={`${props.dataCy}-menu-move`}
			/>

			<DropdownMenuItem
				key="share"
				label="Share"
				data-cy={`${props.dataCy}-menu-share`}
			/> */}
			{!viewOnly ? (
				<DropdownMenuItem
					key="restore-box"
					label="Restore Box"
					data-cy={`${props.dataCy}-menu-restore-box`}
					icon="undo"
				/>
			) : null}
			{!is_rack && !viewOnly && (
				<DropdownMenuItem
					key="restore-item-group"
					label="Restore Item Group"
					data-cy={`${props.dataCy}-menu-restore-itemgroup`}
					icon="undo"
				/>
			)}
			<DropdownMenuDivider />
			{!viewOnly ? (
				<DropdownMenuItem
					key="archive"
					label="Archive"
					data-cy={`${props.dataCy}-menu-archive`}
					icon="archive"
				/>
			) : null}
			{/* {!props.viewOnly && (
				<DropdownMenuItem
					key="delete"
					label="Delete"
					color="dust-red"
					data-cy={`${props.dataCy}-menu-delete`}
				/>
			)} */}
		</Menu>
	);

	return (
		<>
			<DropdownV2
				visible={showMenu}
				onVisibleChange={(visible) => setMenu(visible)}
				overlay={dropdownMenu}
				size="compact"
			>
				{children}
			</DropdownV2>

			<DeleteConfirmModal
				title={!is_rack ? "Delete this category?" : "Delete this rack?"}
				itemName={props.rackOrCategory.name || ""}
				onConfirm={handleDelete}
				onCancel={() => setDelete(false)}
				visible={deleteRack}
				type={!is_rack ? "category" : "rack"}
			/>
			{exportModal}
			<RackMovingCart
				rackOrCategory={props.rackOrCategory}
				visible={moveModalOpen}
				onClose={() => setMoveModalOpen(false)}
			/>
		</>
	);
};
