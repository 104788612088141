import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useEffect,
} from "react";
import { useParams } from "@helpers/URLParams";
import { ResponsiveTableColumns } from "@common/components/Table/ResponsiveTable";
import {
	Box,
	Category,
	FilterOptionsSearch,
	FilterType,
	Freezer,
	ITEM_STATUS,
	Item,
	ItemGroup,
	PaginatedSearchResults,
	Rack,
	RepositoryItemsResult,
	SearchFilterOption,
	TableItem,
	isItem,
	isRack,
} from "@common/types";
import { UNARCHIVE_STEPS } from "./components/Unarchive/unarchiveSteps";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
	RepositoryRackResult,
	useGetRepositoryRacksQuery,
	useRackBulkUpdateMutation,
	useRestorableRacksQuery,
} from "@redux/inventory/Rack";
import { useDebounceEventHandler } from "@helpers/Hooks";
import { SegmentFreezerEvents, SegmentTrackEvent } from "@Segment";
import { Notification } from "@common/components";
import {
	RepositoryShelfResult,
	useGetRepositoryShelvesQuery,
	useShelfBulkUpdateMutation,
} from "@redux/inventory/Shelf";
import { useBulkUpdateFreezersMutation } from "@redux/inventory/Freezer";
import {
	RepositoryCategoryResult,
	useCategoryBulkUpdateMutation,
	useGetRepositoryCategoriesQuery,
	useRestorableCategoriesQuery,
} from "@redux/inventory/Category";
import {
	RepositoryBoxResult,
	useBoxBulkUpdateMutation,
	useGetRepositoryBoxesQuery,
	useRestorableBoxesQuery,
} from "@redux/inventory/Box";
import { useGetRepositoryFreezersQuery } from "@redux/inventory/Freezer";
import {
	RepositoryItemGroupResult,
	useGetRepositoryItemGroupsQuery,
	useItemGroupRepositoryBulkUpdateMutation,
	useRestorableItemGroupsQuery,
} from "@redux/inventory/ItemGroup";
import {
	useRepositoryItemsFilterQuery,
	useRepositoryItemsQuery,
	useRestorableItemsQuery,
} from "@redux/inventory/Item";
import { useBulkItemsEditMutation } from "@redux/inventory/Item";
import ArchiveModal from "../components/ArchiveModal/ArchiveModal";
import { ProgressStep } from "@common/components/ProgressCircles/ProgressCircles";
import { useLocation } from "react-router-dom";

export type FreezerLayers =
	| "FREEZER"
	| "SHELF"
	| "RACK"
	| "BOX"
	| "ITEM"
	| "CATEGORY"
	| "ITEMGROUP";

export type ToolbarButtons =
	| "EDIT"
	| "DUPLICATE"
	| "PRINT"
	| "BARCODE"
	| "RESTORE"
	| "DELETE"
	| "ARCHIVE"
	| "LOCATION"
	| "REARRANGE"
	| "DIMENSION"
	| "CUSTOMIZE";

export type LayerObject = {
	name: string;
	toolbar: ToolbarButtons[];
	actionButton: {
		label: string;
		action: () => void;
	};
	onRowClick: (row: any) => void;
	table: {
		[key: string]: ResponsiveTableColumns<TableItem>;
	};
	secondOption?: LayerObject & { layer: string };
	omitRender?: boolean;
	confirmDelete?: boolean;
};

export type ArchiveStep = ProgressStep;

interface RepositoryParams {
	addedAtGte: string | null;
	addedAtLte: string | null;
	createdBy: string[];
	updatedAtGte: string | null;
	updatedAtLte: string | null;
	updatedBy: string[];
	expiresOnGte: string;
	expiresOnLte: string;
	locatedIn: number[];
	statusIn: ITEM_STATUS[];
	type: number[];
	ordering: string;
	sortValuesIndex: string;
	freezerDefaultType: number[];
	itemGrouptItemType: number[];
	containerType: number[];
	boxItemType: number[];
	itemItemType: number[];
	q: string;
}

type RepositoryContextType = {
	// Layer State
	currentLayer: LayerObject | undefined;
	setCurrentLayer: (layer: LayerObject | undefined) => void;
	selectedLayer?: FreezerLayers;
	setSelectedLayer: (layer: FreezerLayers) => void;

	// Repository Tab
	currentTab: "Freezers" | "Consumables";
	setCurrentTab: (value: string) => void;

	// P A R A M S
	resetAllFilterParamsOnUrl: () => void;
	openUnArchiveContainer: (
		parent: TableItem | null,
		layer: keyof typeof UNARCHIVE_STEPS
	) => void;
	closeUnArchiveContainer: () => void;

	// A R C H I V I N G
	isArchive: boolean;
	setIsArchive: (value: boolean) => void;
	isArchiveModalVisible: boolean;
	setIsArchiveModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	unarchivingSteps: ArchiveStep[] | null;
	setUnarchivingSteps: (value: ArchiveStep[] | null) => void;
	setCurrentStep: (value: number) => void;
	currentStep: number;
	startUnarchiveProcess: () => void;
	handleArchiveOrRestoreButton: (
		isArchiving: boolean,
		options?: { items?: TableItem[]; layer?: FreezerLayers },
		afterArchive?: () => void
	) => void;

	// S E L E C T E D  I T E M S
	selectedItems: TableItem[];
	setSelectedItems: React.Dispatch<React.SetStateAction<TableItem[]>>;

	// F I L T E R S
	filters: RepositoryParams;
	isAnyFilterOrSortingActive: boolean;
	isAnyFilterActive: boolean;

	itemsFilterOptions?: FilterOptionsSearch;

	// Data
	data: {
		FREEZER: PaginatedSearchResults<Freezer> | undefined;
		ITEM: RepositoryItemsResult | undefined;
		BOX: RepositoryBoxResult | undefined;
		RACK: RepositoryRackResult | undefined;
		SHELF: RepositoryShelfResult | undefined;
		CATEGORY: RepositoryCategoryResult | undefined;
		ITEMGROUP: RepositoryItemGroupResult | undefined;
	};
	restorableResults: {
		ITEM: RepositoryItemsResult | undefined;
		BOX: RepositoryBoxResult | undefined;
		RACK: RepositoryRackResult | undefined;
		CATEGORY: RepositoryCategoryResult | undefined;
		ITEMGROUP: RepositoryItemGroupResult | undefined;
	};

	locationFilters: {
		[key in FreezerLayers]: SearchFilterOption[];
	};
	_racks: RepositoryRackResult | undefined;

	// State
	isLoading: boolean;
	isRestorableLoading: boolean;
	isFetching: boolean;

	// Search
	search: string;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	defaultSearchFilter: {
		page_size: number;
		page: number;
		search: string;
	};
	handleSearchText: (value: string) => void;
	debounceSearch: (value: string) => void;

	// Parent Container
	selectedParent: TableItem | null;
	setSelectedParent: React.Dispatch<React.SetStateAction<TableItem | null>>;

	refetch: (layer: FreezerLayers) => void;
};

// Create the context with a default undefined value
const RepositoryContext = createContext<RepositoryContextType | undefined>(
	undefined
);

// Provider component
interface RepositoryProviderProps {
	children: ReactNode;
	restorableItems?: boolean;
}

export const getOrdering = (valueIndex: number, colName: string | null) => {
	if (valueIndex === 1) {
		return colName;
	} else if (valueIndex == 2) {
		return "-" + colName;
	} else {
		return null;
	}
};

const filterByFractional =
	(fraction: number) =>
	(number: number): boolean => {
		return (
			Math.abs(parseFloat((number % 1).toFixed(1)) - fraction) <
			Number.EPSILON
		);
	};

export const RepositoryProvider: React.FC<RepositoryProviderProps> = ({
	children,
	restorableItems,
}) => {
	const params = new URLSearchParams(location.search);
	const [currentStep, setCurrentStep] = useState(0);
	const [unarchivingSteps, setUnarchivingSteps] = useState<
		ArchiveStep[] | null
	>(null);
	const [selectedItems, setSelectedItems] = useState<TableItem[]>([]);
	const [currentLayer, setCurrentLayer] = useState<LayerObject | undefined>(
		undefined
	);
	const [numItemsPerPage, setItemsPerPage] = useState<number>(25);
	const [selectedParent, setSelectedParent] = useState<TableItem | null>(
		null
	);

	// PARAMS TOOLS
	const {
		setParam,
		getParam,
		updateUrl,
		setParamOnURL,
		deleteParam,
		resetParamsOnURL,
	} = useParams();
	const resetAllFilterParamsOnUrl = () => {
		const filterParams = [
			"type",
			"itemItemType",
			"status_in",
			"located_in",
			"created_by",
			"addedAtGte",
			"addedAtLte",
			"updated_by",
			"updatedAtGte",
			"updatedAtLte",
			"expiresOnGte",
			"expiresOnLte",
			"ordering",
			"sortValuesIndex",
			"freezerDefaultType",
			"itemGrouptItemType",
			"containerType",
			"page",
			"q",
		];
		resetParamsOnURL(filterParams);
	};

	// Function to return URLSearchParmas after deleting filterParams
	const getClearedFilterParams = (
		params: URLSearchParams
	): URLSearchParams => {
		const filterParams = [
			"type",
			"status_in",
			"located_in",
			"created_by",
			"addedAtGte",
			"addedAtLte",
			"updated_by",
			"updatedAtGte",
			"updatedAtLte",
			"expiresOnGte",
			"expiresOnLte",
			"ordering",
			"sortValuesIndex",
			"freezerDefaultType",
			"itemGrouptItemType",
			"containerType",
			"page",
			"q",
		];

		const newParams = new URLSearchParams(params);
		filterParams.forEach((param) => {
			newParams.delete(param);
		});

		return newParams;
	};

	const openUnArchiveContainer = (
		parent: TableItem | null,
		layer: keyof typeof UNARCHIVE_STEPS
	) => {
		// set params
		let newParams = new URLSearchParams(window.location.search);
		newParams.set("archive", "true");
		newParams.set("layer", layer);
		newParams = getClearedFilterParams(newParams);
		updateUrl(newParams);

		// select parent
		setSelectedParent(parent);

		// set unarchiving steps
		let steps = UNARCHIVE_STEPS[layer];
		if (layer === "BOX") {
			if (isRack(parent as Rack | Category)) {
				steps.map((step) => ({
					...step,
					title: step.title.replace("Category", "Rack"),
				}));
			} else {
				steps = steps.slice(0, 1);
			}
		}
		setUnarchivingSteps(steps);
	};

	const closeUnArchiveContainer = () => {
		setSelectedParent(null);
		setUnarchivingSteps(null);
		setCurrentStep(0);
		setSelectedItems([]);
		let newParams = new URLSearchParams(window.location.search);
		newParams.delete("archive");
		newParams.delete("layer");
		newParams = getClearedFilterParams(newParams);
		updateUrl(newParams, true);
	};

	const currentTab: "Freezers" | "Consumables" =
		(getParam("tab") as "Freezers" | "Consumables") || "Freezers";
	const setCurrentTab = (value: string) => {
		setParam("tab", value);
	};

	const selectedLayer: FreezerLayers | undefined = getParam(
		"layer"
	) as FreezerLayers;
	const setSelectedLayer = (layer: FreezerLayers) => {
		const filterParams = [
			"type",
			"status_in",
			"located_in",
			"created_by",
			"addedAtGte",
			"addedAtLte",
			"updated_by",
			"updatedAtGte",
			"updatedAtLte",
			"expiresOnGte",
			"expiresOnLte",
			"ordering",
			"sortValuesIndex",
			"freezerDefaultType",
			"itemGrouptItemType",
			"containerType",
			"page",
			"q",
		];
		setParam("layer", layer);
		filterParams.forEach((param) => {
			deleteParam(param);
		});
	};

	const [bulkUpdateFreezers] = useBulkUpdateFreezersMutation();
	const [bulkUpdateShelves] = useShelfBulkUpdateMutation();
	const [bulkUpdateRacks] = useRackBulkUpdateMutation();
	const [bulkUpdateCategories] = useCategoryBulkUpdateMutation();
	const [bulkUpdateItems] = useBulkItemsEditMutation();
	const [bulkUpdateBoxes] = useBoxBulkUpdateMutation();
	const [bulkUpdateItemGroups] = useItemGroupRepositoryBulkUpdateMutation();

	// - - - - - - - - -
	// A R C H I V I N G
	// - - - - - - - - -
	const isArchive = getParam("archive") === "true" ? true : false;
	const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
	const setIsArchive = (value: boolean) => {
		setParam("archive", value ? "true" : "false");
		setParamOnURL("page", "1");
	};

	type BulkUpdateFunction<T = any> = (items: T) => {
		unwrap: () => Promise<T>;
	};
	const bulkArchiveUpdate = ({
		layerType,
		items,
		bulkUpdateFunction,
		isArchive,
		afterArchive,
	}: {
		layerType: FreezerLayers;
		items: TableItem[];
		bulkUpdateFunction: BulkUpdateFunction;
		isArchive: boolean;
		afterArchive?: () => void;
	}) => {
		const isItems = selectedLayer === "ITEM" || layerType === "ITEM";
		const itemsToArchive = items.map((item: any) => {
			return {
				...item,
				is_archived: !item.is_archived,
			};
		});
		const itemsParam = isItems ? { items: itemsToArchive } : itemsToArchive;
		bulkUpdateFunction(itemsParam)
			.unwrap()
			.then((res) => {
				afterArchive?.();
				Notification.success({
					message: `${res.length} ${layerType.toLowerCase()}${
						res.length !== 1 ? "s" : ""
					} have been ${isArchive ? "archived" : "unarchived"}.`,
				});
				setSelectedItems([]);
				if (!isArchive) {
					setUnarchivingSteps(null);
					setCurrentStep(0);
				}
			})
			.catch((error) => {
				Notification.error({
					message: `Error ${
						isArchive ? "archiving" : "unarchiving"
					} ${layerType.toLowerCase()}s.`,
				});
			})
			.finally(() => {
				setIsArchiveModalVisible(false);
				setSelectedItems([]);
			});
	};

	const handleArchiveOrRestoreButton = (
		isArchiving: boolean,
		options?: { items?: TableItem[]; layer?: FreezerLayers },
		afterArchive?: () => void
	) => {
		const bulkUpdateFunctions: {
			[K in FreezerLayers]?: BulkUpdateFunction;
		} = {
			FREEZER: bulkUpdateFreezers,
			SHELF: bulkUpdateShelves,
			RACK: bulkUpdateRacks,
			CATEGORY: bulkUpdateCategories,
			BOX: bulkUpdateBoxes,
			ITEMGROUP: bulkUpdateItemGroups,
			ITEM: bulkUpdateItems,
		};
		const itemsToModify = options?.items ? options?.items : selectedItems;
		const layerSelected = options?.layer ? options?.layer : selectedLayer;

		const bulkUpdateFunction = bulkUpdateFunctions[layerSelected];
		if (bulkUpdateFunction) {
			bulkArchiveUpdate({
				layerType: layerSelected,
				items: itemsToModify,
				bulkUpdateFunction: bulkUpdateFunction,
				isArchive: isArchiving,
				afterArchive,
			});
		} else {
			console.error(
				`No bulk update function defined for layer: ${layerSelected}`
			);
		}
	};

	const startUnarchiveProcess = () => {
		const unarchiveSteps = UNARCHIVE_STEPS[selectedLayer];

		if (unarchiveSteps) {
			setUnarchivingSteps(unarchiveSteps);
		}
	};

	// T A B L E  P A R A M S
	const defaultSearchFilter = {
		// search: searchText,
		page_size:
			(params.get("items_per_page") &&
				parseInt(params.get("items_per_page") as string)) ||
			numItemsPerPage,
		page:
			(params.get("page") && parseInt(params.get("page") as string)) || 1,
		search: params.get("q") || "",
	} as const;
	const selectedStatus = isArchive
		? [ITEM_STATUS.ARCHIVED]
		: [ITEM_STATUS.ACTIVE];
	const [search, setSearch] = useState(defaultSearchFilter.search);

	useEffect(() => {
		setSearch(defaultSearchFilter.search);
	}, [defaultSearchFilter.search]);

	const handleSearchText = (value: string) => {
		setParamOnURL("q", value);
		SegmentTrackEvent(SegmentFreezerEvents.REPOSITORY_SEARCH, {
			query: value,
		});
	};

	const debounceSearch = useDebounceEventHandler(
		(ev: string) => handleSearchText(ev),
		350
	);

	const getFiltersFromURL = () => {
		const currentParams = {
			type: params.get("type")?.split(",").map(Number) || [],
			statusIn: params.get("status_in")?.split(",").map(Number) || [],
			locatedIn: params.get("located_in")?.split(",").map(Number) || [],
			createdBy: params.get("created_by")?.split(",") || [],
			addedAtGte: params.get("addedAtGte") || null,
			addedAtLte: params.get("addedAtLte") || null,
			updatedBy: params.get("updated_by")?.split(",") || [],
			updatedAtGte: params.get("updatedAtGte") || null,
			updatedAtLte: params.get("updatedAtLte") || null,
			expiresOnGte: params.get("expiresOnGte") || "",
			expiresOnLte: params.get("expiresOnLte") || "",
			ordering: params.get("ordering") || "",
			sortValuesIndex: params.get("sortValuesIndex") || "",
			freezerDefaultType:
				params.get("freezerDefaultType")?.split(",").map(Number) || [],
			itemGrouptItemType:
				params.get("itemGrouptItemType")?.split(",").map(Number) || [],
			containerType:
				params.get("containerType")?.split(",").map(Number) || [],
			boxItemType:
				params.get("boxItemType")?.split(",").map(Number) || [],
			itemItemType:
				params.get("itemItemType")?.split(",").map(Number) || [],
			q: params.get("q") || "",
		};
		return currentParams;
	};
	const [filters, setFilters] = useState(getFiltersFromURL());

	const isItAnyFilterOrSortingActive = (filters: object) => {
		return Object.values(filters).some((value) => {
			if (Array.isArray(value)) {
				return value.length > 0;
			} else {
				return value !== null && value.trim() !== "";
			}
		});
	};
	const isAnyFilterOrSortingActive = isItAnyFilterOrSortingActive(filters);
	const isItAnyFilterActive = (filters: object) => {
		const keysToKeep = [
			"type",
			"itemItemType",
			"statusIn",
			"locatedIn",
			"createdBy",
			"updatedBy",
			"freezerDefaultType",
			"itemGrouptItemType",
			"containerType",
			"boxItemType",
		];

		const filteredParams = keysToKeep.reduce((obj: any, key: string) => {
			if (Object.prototype.hasOwnProperty.call(filters, key)) {
				obj[key] = (filters as any)[key];
			}
			return obj;
		}, {});

		return Object.values(filteredParams).some((value) => {
			if (Array.isArray(value)) {
				return value.length > 0;
			} else {
				return value !== null && value !== "";
			}
		});
	};
	const isAnyFilterActive = isItAnyFilterActive(filters);

	useEffect(() => {
		setFilters(getFiltersFromURL());
	}, [location.search]);

	// L O C A T I O N S
	const noLocationFilter: SearchFilterOption[] = [
		{ name: "No location", value: -1, count: -1 },
	];

	// Constants for commonly used values on querys
	const defaultPageSize = defaultSearchFilter.page_size;
	const defaultPage = defaultSearchFilter.page;
	const orderingValue =
		getOrdering(Number(filters.sortValuesIndex), filters.ordering) ||
		"-updated_at";
	const idParam = params.get("id") ? { id: Number(params.get("id")) } : {};

	// Utility to build common query parameters
	const buildQueryParams = (layerSpecificParams: any) => ({
		page_size: defaultPageSize,
		page: defaultPage,
		search: filters.q,
		status__in: selectedStatus,
		ordering: orderingValue,
		include_filter_options: true,
		updated_at__gte: filters.updatedAtGte,
		updated_at__lte: filters.updatedAtLte,
		updated_by__in: filters.updatedBy,
		created_at__gte: filters.addedAtGte,
		created_at__lte: filters.addedAtLte,
		created_by__in: filters.createdBy.map(String),
		...idParam,
		...layerSpecificParams,
	});
	const rackSpecificFilters = {
		location__shelf__in: filters.locatedIn.filter((l) => l !== -1),
		location__isnull: filters.locatedIn.find((l) => l === -1),
	};
	const categorySpecificFilters = {
		location__shelf__in: filters.locatedIn.filter((l) => l !== -1),
		location__isnull: filters.locatedIn.find((l) => l === -1),
	};
	// B O X E S
	const rackCheckboxes = filters.locatedIn
		.filter(filterByFractional(FilterType.RACK / 10))
		.map((l) => Math.trunc(l));
	const categoryCheckboxes = filters.locatedIn
		.filter(filterByFractional(FilterType.CATEGORY / 10))
		.map((l) => Math.trunc(l));
	const boxSpecificFilters = {
		location__category: null,
		location__category__in: categoryCheckboxes,
		location__rack_location__rack: null,
		location__rack_location__rack__in: rackCheckboxes,
		item_type__in: filters.boxItemType,
	};
	const shelfSpecificFilters = {
		freezer__in: filters.locatedIn.filter((l) => l !== -1),
		location__isnull: filters.locatedIn.find((l) => l === -1),
	};
	const freezerSpecificParams = {
		freezer_type__in: filters.type,
		default_item_type__in: filters.freezerDefaultType,
	};
	const itemGroupSpecificParams = {
		location__category__in: filters.locatedIn.filter((l) => l !== -1),
		location__isnull: filters.locatedIn.find((l) => l === -1),
		item_type__in: filters.itemGrouptItemType,
		container_type__in: filters.containerType,
	};
	const itemSpecificParams = {
		added_at__gte: filters.addedAtGte,
		added_at__lte: filters.addedAtLte,
		expiration_date__gte: filters.expiresOnGte,
		expiration_date__lte: filters.expiresOnLte,
		location__box_location__box__in: filters.locatedIn.filter(
			(l) => l !== -1
		),
		location__isnull: filters.locatedIn.find((l) => l === -1),
		item_type__in: filters.itemItemType,
	};

	const {
		data: _racks,
		isLoading: isLoadingRacks,
		refetch: refetchRacks,
		isFetching: isFetchingRacks,
	} = useGetRepositoryRacksQuery(
		selectedLayer === "RACK" && !restorableItems
			? buildQueryParams(rackSpecificFilters)
			: skipToken
	);
	const {
		data: _restorableRacks,
		isLoading: isLoadingRestorableRacks,
		refetch: refetchRestorableRacks,
		isFetching: isFetchingRestorableRacks,
	} = useRestorableRacksQuery(
		selectedLayer === "RACK" && isArchive && restorableItems
			? buildQueryParams(rackSpecificFilters)
			: skipToken
	);
	const {
		data: _categories,
		isLoading: isLoadingCategories,
		refetch: refetchCategories,
		isFetching: isFetchingCategories,
	} = useGetRepositoryCategoriesQuery(
		selectedLayer === "CATEGORY" && !restorableItems
			? buildQueryParams(categorySpecificFilters)
			: skipToken
	);
	const {
		data: _restorableCategories,
		isLoading: isLoadingRestorableCategories,
		refetch: refetchRestorableCategories,
		isFetching: isFetchingRestorableCategories,
	} = useRestorableCategoriesQuery(
		selectedLayer === "CATEGORY" && isArchive && restorableItems
			? buildQueryParams(categorySpecificFilters)
			: skipToken
	);
	const {
		data: _boxes,
		isLoading: isLoadingBoxes,
		refetch: refetchBoxes,
		isFetching: isFetchingBoxes,
	} = useGetRepositoryBoxesQuery(
		selectedLayer === "BOX" && !restorableItems
			? buildQueryParams(boxSpecificFilters)
			: skipToken
	);
	const {
		data: _restorableBoxes,
		isLoading: isLoadingRestorableBoxes,
		refetch: refetchRestorableBoxes,
		isFetching: isFetchingRestorableBoxes,
	} = useRestorableBoxesQuery(
		selectedLayer === "BOX" && isArchive && restorableItems
			? buildQueryParams(boxSpecificFilters)
			: skipToken
	);
	const {
		data: _shelves,
		isLoading: isLoadingShelves,
		refetch: refetchShelves,
		isFetching: isFetchingShelves,
	} = useGetRepositoryShelvesQuery(
		selectedLayer !== "SHELF"
			? skipToken
			: buildQueryParams(shelfSpecificFilters)
	);
	const {
		data: _freezers,
		isLoading: isLoadingFreezers,
		refetch: refetchFreezers,
		isFetching: isFetchingFreezers,
	} = useGetRepositoryFreezersQuery(
		selectedLayer !== "FREEZER"
			? skipToken
			: buildQueryParams(freezerSpecificParams)
	);
	const {
		data: _item_groups,
		isLoading: isLoadingItemGroups,
		refetch: refetchItemGroups,
		isFetching: isFetchingItemGroups,
	} = useGetRepositoryItemGroupsQuery(
		selectedLayer !== "ITEMGROUP"
			? skipToken
			: buildQueryParams(itemGroupSpecificParams)
	);
	const {
		data: _restorableItemGroups,
		isLoading: isLoadingRestorableItemGroups,
		refetch: refetchRestorableItemGroups,
		isFetching: isFetchingRestorableItemGroups,
	} = useRestorableItemGroupsQuery(
		selectedLayer === "ITEMGROUP" && isArchive && restorableItems
			? buildQueryParams(itemGroupSpecificParams)
			: skipToken
	);
	const {
		data: _items,
		isLoading: isLoadingItems,
		refetch: refetchItems,
		isFetching: isFetchingItems,
	} = useRepositoryItemsQuery(
		selectedLayer === "ITEM" && !restorableItems
			? buildQueryParams(itemSpecificParams)
			: skipToken
	);
	const {
		data: _itemsFilterOptions,
		isLoading: isLoadingItemsFilterOptions,
		isFetching: isFetchingItemsFilterOptions,
	} = useRepositoryItemsFilterQuery(
		selectedLayer === "ITEM" && !restorableItems
			? {
					...buildQueryParams(itemSpecificParams),
					include_archived: isArchive,
			  }
			: buildQueryParams(itemSpecificParams)
	);
	const {
		data: _restorableItems,
		isLoading: isLoadingRestorableItems,
		refetch: refetchRestorableItems,
		isFetching: isFetchingRestorableItems,
	} = useRestorableItemsQuery(
		selectedLayer === "ITEM" && isArchive && restorableItems
			? buildQueryParams(itemSpecificParams)
			: skipToken
	);
	const rackLocationFilters = () => {
		if (_racks?.filter_options.location__shelf) {
			return noLocationFilter.concat(
				_racks?.filter_options.location__shelf
			);
		}
		return [];
	};
	const categoryLocationFilters = () => {
		if (_categories?.filter_options.location__shelf) {
			return noLocationFilter.concat(
				_categories?.filter_options.location__shelf
			);
		}
		return [];
	};
	const boxLocationsFilters = () => {
		if (!_boxes) return [];
		let locations_filter: SearchFilterOption[] = [];
		const noLocationFilter: SearchFilterOption[] = [
			{ name: "No location", value: -1, count: -1 },
		];
		if (_boxes?.filter_options.location__category) {
			const categoryFilter =
				_boxes?.filter_options.location__category.map((category) => ({
					...category,
					type: FilterType.CATEGORY,
				}));
			locations_filter = noLocationFilter.concat(categoryFilter);
		}
		if (_boxes?.filter_options.location__rack_location__rack) {
			const rackFilter =
				_boxes?.filter_options.location__rack_location__rack.map(
					(rack) => ({ ...rack, type: FilterType.RACK })
				);
			locations_filter = locations_filter.concat(rackFilter);
		}
		return locations_filter;
	};
	const shelfLocationsFilter = () => {
		let locations_filter: SearchFilterOption[] = [];
		const noLocationFilter: SearchFilterOption[] = [
			{ name: "No location", value: -1, count: -1 },
		];
		if (_shelves?.filter_options.freezer) {
			locations_filter = noLocationFilter.concat(
				_shelves?.filter_options.freezer
			);
		}
		return locations_filter;
	};
	const itemGroupLocationsFilter = () => {
		let locations_filter: SearchFilterOption[] = [];
		const noLocationFilter: SearchFilterOption[] = [
			{ name: "No location", value: -1, count: -1 },
		];
		if (_item_groups?.filter_options.location__category) {
			locations_filter = noLocationFilter.concat(
				_item_groups?.filter_options.location__category
			);
		}
		return locations_filter;
	};
	const itemLocationFilters = () => {
		let locations_filter: SearchFilterOption[] = [];
		const noLocationFilter: SearchFilterOption[] = [
			{ name: "No location", value: -1, count: -1 },
		];
		if (_itemsFilterOptions?.location__box_location__box) {
			locations_filter = noLocationFilter.concat(
				_itemsFilterOptions?.location__box_location__box
			);
		}
		return locations_filter;
	};

	const data: {
		FREEZER: PaginatedSearchResults<Freezer> | undefined;
		ITEM: RepositoryItemsResult | undefined;
		BOX: RepositoryBoxResult | undefined;
		RACK: RepositoryRackResult | undefined;
		SHELF: RepositoryShelfResult | undefined;
		CATEGORY: RepositoryCategoryResult | undefined;
		ITEMGROUP: RepositoryItemGroupResult | undefined;
	} = {
		FREEZER: _freezers,
		ITEM: _items,
		BOX: _boxes,
		RACK: _racks,
		SHELF: _shelves,
		CATEGORY: _categories,
		ITEMGROUP: _item_groups,
	};

	const restorableResults: {
		ITEM: RepositoryItemsResult | undefined;
		BOX: RepositoryBoxResult | undefined;
		RACK: RepositoryRackResult | undefined;
		CATEGORY: RepositoryCategoryResult | undefined;
		ITEMGROUP: RepositoryItemGroupResult | undefined;
	} = {
		ITEM: _restorableItems,
		BOX: _restorableBoxes,
		RACK: _restorableRacks,
		CATEGORY: _restorableCategories,
		ITEMGROUP: _restorableItemGroups,
		// BOX: _restorableItemGroups,
		// RACK: _restorableItemGroups,
		// CATEGORY: _restorableItemGroups,
		// ITEMGROUP: _restorableItemGroups,
	};

	const refetch = (layer: FreezerLayers) => {
		switch (layer) {
			case "FREEZER":
				refetchFreezers();
				break;
			case "SHELF":
				refetchShelves();
				break;
			case "RACK":
				refetchRacks();
				break;
			case "CATEGORY":
				refetchCategories();
				break;
			case "ITEMGROUP":
				refetchItemGroups();
				break;
			case "ITEM":
				refetchItems();
				break;
		}
	};

	const isFetching =
		isFetchingRacks ||
		isFetchingShelves ||
		isFetchingBoxes ||
		isFetchingCategories ||
		isFetchingItemGroups ||
		isFetchingItems ||
		isFetchingFreezers ||
		isFetchingItemsFilterOptions;

	const isLoading =
		isLoadingRacks ||
		isLoadingShelves ||
		isLoadingBoxes ||
		isLoadingCategories ||
		isLoadingItemGroups ||
		isLoadingItems ||
		isLoadingFreezers ||
		isLoadingItemsFilterOptions;
	const isRestorableLoading =
		isLoadingRestorableItems ||
		isLoadingRestorableBoxes ||
		isLoadingRestorableRacks ||
		isLoadingRestorableItemGroups ||
		isLoadingRestorableCategories;
	const value = {
		currentLayer,
		setCurrentLayer,
		currentTab,
		setCurrentTab,

		selectedLayer,
		setSelectedLayer,

		// Params
		resetAllFilterParamsOnUrl,
		openUnArchiveContainer,
		closeUnArchiveContainer,

		// Archive Functions and State
		isArchive,
		setIsArchive,
		isArchiveModalVisible,
		setIsArchiveModalVisible,
		setCurrentStep,
		currentStep,
		unarchivingSteps,
		setUnarchivingSteps,
		startUnarchiveProcess,
		handleArchiveOrRestoreButton,

		selectedItems,
		setSelectedItems,
		filters,
		isAnyFilterOrSortingActive,
		isAnyFilterActive,

		// Repository Data
		data,
		restorableResults,
		refetch,

		_racks,
		locationFilters: {
			FREEZER: [],
			SHELF: shelfLocationsFilter(),
			RACK: rackLocationFilters(),
			CATEGORY: categoryLocationFilters(),
			BOX: boxLocationsFilters(),
			ITEMGROUP: itemGroupLocationsFilter(),
			ITEM: itemLocationFilters(),
		},

		// Filter Options
		itemsFilterOptions: _itemsFilterOptions,

		// States
		isLoading,
		isRestorableLoading,
		isFetching,

		// Search
		search,
		setSearch,
		defaultSearchFilter,
		handleSearchText,
		debounceSearch,

		// Parent Container
		selectedParent,
		setSelectedParent,
	};

	return (
		<RepositoryContext.Provider value={value}>
			{children}
			<ArchiveModal
				visible={isArchiveModalVisible}
				onCancel={() => setIsArchiveModalVisible(false)}
				onArchive={() => handleArchiveOrRestoreButton(true)}
				type={selectedLayer?.toLowerCase() as any}
				numberOfItems={selectedItems.length}
			/>
		</RepositoryContext.Provider>
	);
};

// Custom hook to use the context
export const useRepository = () => {
	const context = useContext(RepositoryContext);
	if (context === undefined) {
		throw new Error(
			"useRepository must be used within a RepositoryProvider"
		);
	}
	return context;
};
