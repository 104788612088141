import API from "@API";
import {
	SidebarSettings,
	SidebarSettingsDto,
	sidebarSettingsFromDto,
	sidebarSettingsToDto,
} from "@common/classes/SidebarSettings";
import { genemodBaseQuery } from "@redux/helpers/RtkQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { User } from "@common/types/User";
import { AtLeastId } from "@helpers/TypeHelpers";
import { AppState } from "@redux/store";
import { UserPreference } from "@common/types/UserPreference";

export type Signature = {
	id: number;
	image: string;
	user: User;
}

const dataURLToBlob = (dataURL: string) => {
	const [header, base64] = dataURL.split(',');
	const mimeType = header?.match(/:(.*?);/)?.[1];
	const byteCharacters = atob(base64);
	const byteArrays = [];

	for (let i = 0; i < byteCharacters.length; i += 512) {
			const slice = byteCharacters.slice(i, i + 512);
			const byteNumbers = new Array(slice.length);

			for (let j = 0; j < slice.length; j++) {
					byteNumbers[j] = slice.charCodeAt(j);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, { type: mimeType });
}

export const userApi = createApi({
	reducerPath: "userApi",
	baseQuery: genemodBaseQuery({ baseUrl: "" }),
	tagTypes: ["SidebarSettings", "User", "Preferences", "Signature"],
	endpoints: (builder) => {
		const invite = {
			acceptInvite: builder.mutation<
				{ email: string },
				{
					orgId: number | null;
					token: string;
					isBeta: boolean;
					/**
					 * Only use these for on prem/drf auth
					 */
					drfOptions?: {
						first_name: string;
						last_name: string;
						password: string;
					};
				}
			>({
				query: ({ orgId, token, isBeta }) => ({
					url: `/org/v2/accept-invite/`,
					method: "POST",
					body: { token, org_id: orgId, is_beta: isBeta },
				}),
				extraOptions: { maxRetries: 2 },
			}),
			verifyInvite: builder.query<
				{ email: string; has_existing_account: boolean },
				{ token: string; isBeta: boolean }
			>({
				query: ({ token, isBeta }) =>
					`${API.org2.checkInvite(token)}/` +
					(isBeta ? "?is_beta=true" : ""),
				extraOptions: { maxRetries: 2 },
			}),
		} as const;

		const sidebarSettings = {
			sidebarSettings: builder.query<SidebarSettings, void>({
				query: () => API.user.sidebarSettings(),
				extraOptions: { maxRetries: 2 },
				transformResponse: (resp: SidebarSettingsDto) =>
					sidebarSettingsFromDto(resp),
				providesTags: ["SidebarSettings"],
			}),
			sidebarSettingsPut: builder.mutation<
				SidebarSettings,
				SidebarSettings
			>({
				query: (body) => ({
					url: API.user.sidebarSettings(),
					method: "PUT",
					body: sidebarSettingsToDto(body),
				}),
				extraOptions: { maxRetries: 2 },
				invalidatesTags: ["SidebarSettings"],
				transformResponse: (resp: SidebarSettingsDto) =>
					sidebarSettingsFromDto(resp),
				// Optimistic update
				async onQueryStarted(settings, { dispatch, queryFulfilled }) {
					const patchResult = dispatch(
						userApi.util.updateQueryData(
							"sidebarSettings",
							undefined,
							(draft) => {
								Object.assign(draft, settings);
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				},
			}),
		};

		const user = {
			currentUser: builder.query<User, void>({
				query: () => `/rest-auth/user/`,
				providesTags: ["User"],
			}),
			patchCurrentUser: builder.mutation<User, AtLeastId<User>>({
				query: (body) => ({
					url: `/rest-auth/user/`,
					method: "PATCH",
					body,
				}),
				invalidatesTags: ["User"],
				extraOptions: { maxRetries: 0 },
			}),

			preferences: builder.query<UserPreference, void>({
				query: () => `/user/preferences/`,
				extraOptions: { maxRetries: 0 },
				providesTags: ["Preferences"],
			}),

			getUserSignatures: builder.query<Signature[], void>({
				query: () => `/user/user-signature/`,
				extraOptions: { maxRetries: 0 },
				providesTags: ["Signature"],
			}),

			createUserSignature: builder.mutation<Signature, Pick<Signature, "image">>({
				query: ({ image }) => {
					const body = new FormData();
					body.append("image", dataURLToBlob(image));
					return {
						url: `/user/user-signature/`,
						method: "POST",
						body,
					}
				},
				extraOptions: { maxRetries: 0 },
				invalidatesTags: ["Signature"],
			}),

			updatePreferences: builder.mutation<
				UserPreference,
				Partial<UserPreference>
			>({
				query: (body) => ({
					url: `/user/preferences/`,
					method: "PUT",
					body,
				}),
				invalidatesTags: ["Preferences"],
				// optimistic update
				async onQueryStarted(settings, { dispatch, queryFulfilled }) {
					const patchResult = dispatch(
						userApi.util.updateQueryData(
							"preferences",
							undefined,
							(draft) => {
								Object.assign(draft, settings);
							}
						)
					);
					try {
						await queryFulfilled;
					} catch {
						patchResult.undo();
					}
				},
			}),
			verifyAccountStatus: builder.query<void, void>({
				query: () => API.org.verifyAccountStatus.getRoute(),
			}),
		};

		const drfAuth = {
			register: builder.mutation<
				void,
				{
					email: string;
					password1: string;
					password2: string;
					first_name: string;
					last_name: string;
				}
			>({
				query: (body) => ({
					url: `${API.auth.registration.getRoute()}/`,
					method: "POST",
					body,
				}),
			}),
			login: builder.mutation<
				{ key: string },
				{ email: string; password: string }
			>({
				query: (body) => ({
					url: `${API.auth.login.getRoute()}/`,
					method: "POST",
					body,
				}),
			}),
			logout: builder.mutation<void, void>({
				query: () => ({
					url: `${API.auth.logout.getRoute()}/`,
					method: "POST",
				}),
			}),
			updatePassword: builder.mutation<
				void,
				{ new_password1: string; new_password2: string }
			>({
				query: (body) => ({
					url: `${API.auth.password.change.getRoute()}/`,
					method: "POST",
					body,
				}),
			}),
			sendForgotEmailPassword: builder.mutation<void, { email: string }>({
				query: (body) => ({
					url: `${API.auth.password.reset.getRoute()}/`,
					method: "POST",
					body,
				}),
			}),
			resetForgottenPassword: builder.mutation<
				void,
				{
					new_password1: string;
					new_password2: string;
					uid: string;
					token: string;
				}
			>({
				query: (body) => ({
					url: `${API.auth.password.reset.confirm.getRoute()}/`,
					method: "POST",
					body,
				}),
			}),
			patchNotifications: builder.mutation<void, boolean>({
				query: () => ({
					url: `${API.auth.user.getRoute()}/`,
					method: "PATCH",
				}),
			}),
			verifyEmail: builder.mutation<void, { key: string }>({
				query: ({ ...body }) => ({
					url: `${API.auth.registration.verify.getRoute()}/`,
					method: "POST",
					body,
				}),
			}),
			resendVerificationEmail: builder.mutation<void, void>({
				query: () => ({
					url: `${API.auth.registration.resendVerification()}/`,
					method: "POST",
				}),
			}),
		};

		return {
			...invite,
			...sidebarSettings,
			...user,
			...drfAuth,
		};
	},
});

export const {
	useAcceptInviteMutation: useAcceptInvite,
	useVerifyInviteQuery,
	useSidebarSettingsQuery,
	useSidebarSettingsPutMutation,
	useCurrentUserQuery,
	usePatchCurrentUserMutation,
	useVerifyEmailMutation,
	useResendVerificationEmailMutation,
	usePreferencesQuery,
	useLazyPreferencesQuery,
	useUpdatePreferencesMutation,
	// drf auth
	useRegisterMutation,
	useLoginMutation,
	useLogoutMutation,
	useUpdatePasswordMutation,
	useSendForgotEmailPasswordMutation,
	useResetForgottenPasswordMutation,
	usePatchNotificationsMutation,
	useVerifyAccountStatusQuery,
	useGetUserSignaturesQuery,
	useCreateUserSignatureMutation
} = userApi;

export const getUser = (state: AppState) =>
	(state.userApi.queries["currentUser(undefined)"] as any).data as
	| User
	| undefined;
